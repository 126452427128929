import React from "react";
import { api } from "src/data/api/api";
import { selectContactOptions } from "src/data/selectors/resourceCache";
import { useAppSelector } from "src/data/store";

export function useContactOptions(query?: string) {
  api.endpoints.getContacts.useQuery(
    query != null
      ? {
          search: {
            and: [{ query }],
          },
          page: 1,
        }
      : undefined,
  );

  const contactOptions = useAppSelector(selectContactOptions);

  return React.useMemo(
    () =>
      contactOptions
        .filter(
          (option) =>
            query == null ||
            option.label.toLowerCase().includes(query.toLowerCase()),
        )
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [contactOptions, query],
  );
}
