import { Button, Stack } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import { BidReceiverField } from "src/components/Fields/BidReceiverField";
import { DEFAULT_BID_RECEIVER_VALUE, NewBid } from "../types/bid";

interface Props {
  readonly control: Control<NewBid["bidReceivers"]>;
  readonly shouldAllowCustomerCreate?: boolean;
  readonly shouldAllowCustomerDelete?: boolean;
  readonly shouldAllowCustomerEdit?: boolean;
}

export const BidReceiversFormContent = React.memo<Props>(
  function _BidReceiversFormContent({
    control,
    shouldAllowCustomerCreate = false,
    shouldAllowCustomerDelete = false,
    shouldAllowCustomerEdit = false,
  }) {
    const { fields, append, remove } = useFieldArray({
      control,
      name: "bidReceivers",
    });

    const handleAppend = React.useCallback(() => {
      append(DEFAULT_BID_RECEIVER_VALUE);
    }, [append]);

    return (
      <>
        <Stack gap="md">
          {fields.map((field, index) => {
            return (
              <BidReceiverField
                key={field.id}
                control={control}
                index={index}
                onRemove={remove}
                shouldAllowCustomerEdit={shouldAllowCustomerEdit}
                shouldAllowDelete={shouldAllowCustomerDelete}
              />
            );
          })}
        </Stack>
        {shouldAllowCustomerCreate && (
          <div>
            <Button
              leftSection={<IconPlus size={16} />}
              onClick={handleAppend}
              variant="default"
            >
              Add customer
            </Button>
          </div>
        )}
      </>
    );
  },
);
