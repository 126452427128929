import { Button, Group, Modal, Stack, Stepper, Title } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { BidFormContent } from "src/components/Forms/NewBid/BidFormContent";
import { BidReceiversFormContent } from "src/components/Forms/NewBid/BidReceiversFormContent";
import { EstimationFormContent } from "src/components/Forms/NewBid/EstimationFormContent";
import { DEFAULT_NEW_BID_VALUE, NewBid } from "src/components/Forms/types/bid";
import { PersistenceType } from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  EstimationAttributes,
  ResourceType,
  SALES_LEAD_ROLE,
} from "src/data/api/types/bulkTransaction";
import { EMPTY_STRING } from "src/utils/empty";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

const MAX_STEP_COUNT = 3;

interface Props {
  readonly onClose: () => void;
}

export const NewBidDialog = React.memo<Props>(function _NewBidDialog({
  onClose,
}) {
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const bidAttributesForm = useForm<NewBid["attributes"]>({
    defaultValues: DEFAULT_NEW_BID_VALUE.attributes,
  });
  const bidReceiversForm = useForm<NewBid["bidReceivers"]>({
    defaultValues: DEFAULT_NEW_BID_VALUE.bidReceivers,
  });
  const bidReceiversValue = bidReceiversForm.watch("bidReceivers");
  const estimationForm = useForm<NewBid["estimation"]>({
    defaultValues: DEFAULT_NEW_BID_VALUE.estimation,
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNextStep = React.useCallback(() => {
    setActiveStep((current) =>
      current < MAX_STEP_COUNT - 1 ? current + 1 : current,
    );
  }, []);

  const handleBidCreate = React.useCallback(async () => {
    try {
      const attributes = bidAttributesForm.getValues();
      const bidReceivers = bidReceiversForm.getValues();
      const estimationFormValues = estimationForm.getValues();

      if (attributes.jobSiteAddress == null) {
        throw new Error();
      }

      const bidUuid = crypto.randomUUID();
      const jobSiteId = crypto.randomUUID();
      const associations: Array<Association> = [];

      for (const {
        customer,
        contacts,
        dueDate,
        walkDate,
      } of bidReceivers.bidReceivers) {
        const customerId =
          customer != null
            ? customer.type === PersistenceType.EXISTING
              ? customer.data.uuid
              : customer.uuid
            : EMPTY_STRING;

        for (const contact of contacts) {
          const contactId =
            contact.type === PersistenceType.EXISTING
              ? contact.data.uuid
              : contact.uuid;

          associations.push({
            type: AssociationType.ContactRole,
            contact_id: contactId,
            resource_type: ResourceType.Bid,
            resource_id: bidUuid,
            metadata: {
              customer_id: customerId,
            },
          });
        }

        associations.push({
          type: AssociationType.CustomerBid,
          customer_id: customerId,
          bid_id: bidUuid,
          metadata: {
            due_at: dueDate?.toISOString(),
            job_walk_at: walkDate?.toISOString(),
          },
        });
        associations.push({
          type: AssociationType.CustomerJobSite,
          customer_id: customerId,
          job_site_id: jobSiteId,
        });
      }

      const estimations_attributes: Array<EstimationAttributes> = [];
      for (const estimation of estimationFormValues.estimations) {
        const estimationId = crypto.randomUUID();
        estimations_attributes.push({
          uuid: estimationId,
          status: estimation.status,
          job_types: estimation.jobTypes,
        });
        associations.push({
          type: AssociationType.Role,
          user_id: estimation.salesLead,
          resource_type: ResourceType.Estimation,
          resource_id: estimationId,
          metadata: {
            name: SALES_LEAD_ROLE,
          },
        });
      }

      await trigger({
        bulk_transaction: {
          associations,
          job_sites_attributes: [
            {
              uuid: jobSiteId,
              company_id: 1 as const,
              address_attributes: {
                full_address: attributes.jobSiteAddress.text,
                google_id: attributes.jobSiteAddress.id,
              },
              bids_attributes: [
                {
                  address_details: attributes.jobSiteAddressDetails,
                  uuid: bidUuid,
                  bid_stage: attributes.bidStage,
                  branch_id: estimationFormValues.branch,
                  company_id: 1 as const,
                  construction_type: attributes.constructionType,
                  difficulty: attributes.difficulty,
                  name: attributes.name,
                  priority: attributes.priority,
                  relationship: attributes.relationship,
                  estimations_attributes,
                },
              ],
            },
          ],
        },
      }).unwrap();
      onClose();
    } catch (error) {
      showBulkTransactionNotifications(error);
    }
  }, [bidAttributesForm, bidReceiversForm, estimationForm, onClose, trigger]);

  const handlePrevStep = React.useCallback(
    () => setActiveStep((current) => (current > 0 ? current - 1 : current)),
    [],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size={950}
      title="Create new bid"
    >
      <Group align="flex-start" wrap="nowrap">
        <Stepper
          active={activeStep}
          allowNextStepsSelect={false}
          onStepClick={setActiveStep}
          orientation="vertical"
          size="sm"
          w={200}
          wrap={false}
        >
          <Stepper.Step label="Bid information" />
          <Stepper.Step label="Customers" />
          <Stepper.Step label="Estimation" />
        </Stepper>

        <Stack w="100%">
          <Title order={5}>
            {activeStep === 0
              ? "Tell us more about the bid"
              : activeStep === 1
                ? "Which customers are receiving this bid?"
                : "Which salespeople will be estimating this bid?"}
          </Title>

          {activeStep === 0 ? (
            <BidFormContent control={bidAttributesForm.control} />
          ) : activeStep === 1 ? (
            <BidReceiversFormContent
              control={bidReceiversForm.control}
              shouldAllowCustomerCreate={true}
              shouldAllowCustomerDelete={bidReceiversValue.length > 1}
              shouldAllowCustomerEdit={true}
            />
          ) : (
            <EstimationFormContent control={estimationForm.control} />
          )}
        </Stack>
      </Group>

      <FormFooter
        leftSection={
          <div>
            {activeStep > 0 && (
              <Button onClick={handlePrevStep} variant="outline">
                Back
              </Button>
            )}
          </div>
        }
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            {activeStep === 0 ? (
              <Button onClick={bidAttributesForm.handleSubmit(handleNextStep)}>
                Next
              </Button>
            ) : activeStep === 1 ? (
              <Button onClick={bidReceiversForm.handleSubmit(handleNextStep)}>
                Next
              </Button>
            ) : (
              <Button
                loading={mutationState.isLoading}
                onClick={estimationForm.handleSubmit(handleBidCreate)}
              >
                Create bid
              </Button>
            )}
          </Group>
        }
      />
    </Modal>
  );
});
