import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { NoteFrame } from "src/components/Frames/NoteFrame";
import { Note } from "src/data/api/types/shared/note";
import { WithClassname } from "src/types/withClassName";
import styles from "../Frames/NoteHoverOptions.module.scss";
import { NoteDialog } from "./NoteDialog";

interface Props extends WithClassname {
  readonly isAddNoteLoading: boolean;
  readonly isUpdateNoteLoading: boolean;
  readonly notes?: ReadonlyArray<Note>;
  readonly onNoteAdd: (content: string) => Promise<void>;
  readonly onNoteDelete: (id: number) => Promise<void>;
  readonly onNoteUpdate: (id: number, content: string) => Promise<void>;
}

export const NotesModule = React.memo<Props>(function _NotesModule({
  className,
  isAddNoteLoading,
  isUpdateNoteLoading,
  notes,
  onNoteAdd,
  onNoteDelete,
  onNoteUpdate,
}) {
  const [isOpen, { open, close }] = useDisclosure(false);

  return (
    <ContentSection
      className={className}
      rightSection={
        <Button
          leftSection={<IconPlus size={14} />}
          onClick={open}
          variant="subtle"
        >
          Add
        </Button>
      }
      title="Notes"
    >
      {notes?.map((note) => {
        return (
          <NoteFrame
            key={note.id}
            className={styles.note}
            isLoading={isUpdateNoteLoading}
            note={note}
            onNoteDelete={onNoteDelete}
            onNoteUpdate={onNoteUpdate}
          />
        );
      })}

      {isOpen && (
        <NoteDialog
          isLoading={isAddNoteLoading}
          onClose={close}
          onNoteAdd={onNoteAdd}
        />
      )}
    </ContentSection>
  );
});
