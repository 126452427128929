import { Stack } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { CustomerSelectField } from "../Fields/CustomerSelectField";
import { RoleStatusField } from "../Fields/RoleStatusField";
import { TitleRoleSelectField } from "../Fields/TitleRoleSelectField";
import { REQUIRED_RULES } from "./constants";
import { NewContact } from "./types/contact";

interface Props {
  readonly control: Control<NewContact["data"]>;
  readonly shouldAllowCustomerEdit?: boolean;
}

export const CustomerRoleFormContent = React.memo<Props>(
  function _CustomerRoleFormContent({
    control,
    shouldAllowCustomerEdit = false,
  }) {
    const customer = useController({
      control,
      name: "workplace",
      rules: REQUIRED_RULES,
    });

    const role = useController({
      control,
      name: "role",
    });

    const departedAt = useController({
      control,
      name: "departedAt",
    });

    return (
      <Stack>
        <CustomerSelectField
          disabled={!shouldAllowCustomerEdit}
          error={customer.fieldState.error?.message}
          label="Customer"
          onChange={customer.field.onChange}
          required={true}
          value={customer.field.value}
        />
        <TitleRoleSelectField
          error={role.fieldState.error?.message}
          label="Role"
          onChange={role.field.onChange}
          value={role.field.value}
        />
        <RoleStatusField
          label="Current company?"
          onChange={departedAt.field.onChange}
          value={departedAt.field.value}
        />
      </Stack>
    );
  },
);
