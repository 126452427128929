import { Checkbox, Group, Paper } from "@mantine/core";
import React from "react";

interface Props {
  readonly columnId: string;
  readonly displayName: string;
  readonly isChecked: boolean;
  readonly onColumnToggle: (columnId: string) => void;
}

export const ColumnRow = React.memo(function _ColumnRow({
  columnId,
  displayName,
  isChecked,
  onColumnToggle,
}: Props) {
  const handleColumnToggle = React.useCallback(() => {
    onColumnToggle(columnId);
  }, [columnId, onColumnToggle]);

  return (
    <Paper p={12} withBorder={true}>
      <Group>
        <Checkbox checked={isChecked} onChange={handleColumnToggle} />
        {displayName}
      </Group>
    </Paper>
  );
});
