import { Group, Stack } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { PageHeader } from "src/components/Frames/PageHeader";
import { JOB_SITES_CONFIG } from "src/constants/navBarConfigs";
import { EMPTY_STRING } from "src/utils/empty";
import { SearchInput } from "../Frames/SearchInput";
import { NewJobSiteButton } from "./Header/NewJobSiteButton";
import styles from "./JobSiteView.module.scss";
import { JobSiteTable } from "./Table/JobSiteTable";

export const JobSiteView = React.memo(function _JobSiteView() {
  const [query, setQuery] = useInputState(EMPTY_STRING);

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <SearchInput onQueryChange={setQuery} query={query} />
            <NewJobSiteButton />
          </Group>
        }
        title={
          <Group gap={4}>
            {JOB_SITES_CONFIG.icon}
            {JOB_SITES_CONFIG.name}
          </Group>
        }
      />
      <JobSiteTable query={query} />
    </Stack>
  );
});
