import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/data/store";
import { ContactCache } from "src/hooks/resourceCache/types";
import { LabelValue } from "src/types/util/labelValue";

export const selectResourceCache = (state: RootState) => state.resourceCache;
export const selectContactByUuid = (state: RootState) =>
  state.resourceCache.contactByUuid;
export const selectCustomerByUuid = (state: RootState) =>
  state.resourceCache.customerByUuid;
export const selectUserByUuid = (state: RootState) =>
  state.resourceCache.userByUuid;

export const selectContactOptions = createSelector(
  selectContactByUuid,
  (contactCache) => {
    const returns: Array<ContactCache> = [];
    for (const [uuid, contact] of Object.entries(contactCache)) {
      if (contact == null) {
        continue;
      }

      returns.push({
        label: `${contact.firstName} ${contact.lastName}`,
        value: uuid,
        customerUuid: contact.customerUuid,
      });
    }
    return returns;
  },
);

export const selectCustomerOptions = createSelector(
  selectCustomerByUuid,
  (customerCache) => {
    const returns: Array<LabelValue> = [];
    for (const [uuid, customer] of Object.entries(customerCache)) {
      if (customer == null) {
        continue;
      }

      returns.push({
        label: customer.name,
        value: uuid,
      });
    }
    return returns;
  },
);

export const selectUserOptions = createSelector(
  selectUserByUuid,
  (userCache) => {
    const returns: Array<LabelValue> = [];
    for (const [uuid, user] of Object.entries(userCache)) {
      if (user == null) {
        continue;
      }

      returns.push({
        label: `${user.firstName} ${user.lastName}`,
        value: uuid,
      });
    }
    return returns;
  },
);
