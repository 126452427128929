import { Button, Input, Select, Stack } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { Control, useController, useFieldArray } from "react-hook-form";
import { api } from "src/data/api/api";
import { AddressField } from "../Fields/AddressField";
import { JobSiteContactField } from "../Fields/JobSiteContactField";
import { UserField } from "../Fields/UserField";
import { REQUIRED_RULES } from "./constants";
import { DEFAULT_NEW_SITE_CONTACT_VALUE, NewJobSite } from "./types/jobSite";

interface Props {
  readonly control: Control<NewJobSite["data"]>;
  readonly shouldRenderContacts?: boolean;
}

export const NewJobSiteFormContent = React.memo<Props>(
  function _NewJobSiteFormContent({ control, shouldRenderContacts = false }) {
    const { currentData } = api.endpoints.getJobSiteOptions.useQuery();

    const address = useController({
      control,
      name: "address",
      rules: REQUIRED_RULES,
    });

    const buildingClass = useController({ control, name: "buildingClass" });
    const accountManager = useController({ control, name: "accountManager" });

    const { fields, append, remove } = useFieldArray({
      control,
      name: "siteContacts",
    });

    const handleContactAdd = React.useCallback(() => {
      append(DEFAULT_NEW_SITE_CONTACT_VALUE);
    }, [append]);

    return (
      <Stack>
        <AddressField
          error={address.fieldState.error?.message}
          label="Job site address"
          onChange={address.field.onChange}
          required={true}
          value={address.field.value}
        />
        <Select
          clearable={true}
          data={currentData?.buildingClass}
          disabled={currentData == null}
          error={buildingClass.fieldState.error?.message}
          label="Building class"
          onChange={buildingClass.field.onChange}
          value={buildingClass.field.value}
        />
        <UserField
          error={accountManager.fieldState.error?.message}
          label="Account manager"
          onChange={accountManager.field.onChange}
          value={accountManager.field.value}
        />
        {shouldRenderContacts && (
          <>
            <Input.Wrapper label="Is there any dedicated Site Contact?">
              <Stack gap="md">
                {fields.map((field, index) => {
                  return (
                    <JobSiteContactField
                      key={field.id}
                      control={control}
                      index={index}
                      onRemove={remove}
                    />
                  );
                })}
              </Stack>
            </Input.Wrapper>
            <div>
              <Button
                leftSection={<IconPlus size={16} />}
                onClick={handleContactAdd}
                variant="default"
              >
                Add contact
              </Button>
            </div>
          </>
        )}
      </Stack>
    );
  },
);
