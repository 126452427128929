import { Address } from "src/types/address";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import { ExistingContact, NewContact } from "./contact";
import { PersistenceType } from "./persistenceType";

export interface SiteContact {
  readonly contact: NewContact | ExistingContact | null;
  readonly influence: string;
  readonly departedAt: string | null;
}

export const DEFAULT_NEW_SITE_CONTACT_VALUE: SiteContact = {
  contact: null,
  departedAt: null,
  influence: EMPTY_STRING,
};

export interface NewJobSite {
  readonly type: PersistenceType.NEW;
  readonly data: {
    readonly address: Address | null;
    readonly buildingClass: string;
    readonly accountManager?: string;
    readonly siteContacts: ReadonlyArray<SiteContact>;
    /* TODO: if we want siteCustomer, it should be added here */
  };
}

export const DEFAULT_NEW_JOB_SITE_VALUE: NewJobSite["data"] = {
  address: null,
  buildingClass: EMPTY_STRING,
  accountManager: undefined,
  siteContacts: EMPTY_ARRAY,
  /* TODO: if we want a siteCustomer, it should be added here */
};

export interface ExistingJobSite {
  readonly type: PersistenceType.EXISTING;
  readonly data: {
    readonly uuid: string;
  };
}
