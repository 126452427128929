import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { BidReceiversFormContent } from "src/components/Forms/NewBid/BidReceiversFormContent";
import { NewBid } from "src/components/Forms/types/bid";
import {
  createExisting,
  PersistenceType,
} from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  Deletion,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { EMPTY_ARRAY } from "src/utils/empty";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormValue = NewBid["bidReceivers"];

interface Props {
  readonly bid: LoadedBid;
  readonly customerId: string;
  readonly onClose: () => void;
}

export const EditCustomerDialog = React.memo<Props>(
  function _EditCustomerDialog({ bid, customerId, onClose }) {
    const customer = React.useMemo(
      () => bid.attributes.customers.find((entry) => entry.uuid === customerId),
      [bid.attributes.customers, customerId],
    );

    const contacts = React.useMemo(
      () =>
        bid.attributes.contacts.filter(
          (entry) => entry.customer_id === customerId,
        ),
      [bid.attributes.contacts, customerId],
    );

    const {
      control,
      formState: { defaultValues },
      handleSubmit,
    } = useForm<FormValue>({
      defaultValues: {
        bidReceivers: [
          {
            customer: createExisting({ uuid: customer?.uuid }),
            contacts:
              contacts.map((contact) =>
                createExisting({ uuid: contact.uuid }),
              ) ?? EMPTY_ARRAY,
            dueDate:
              customer?.due_at != null ? new Date(customer?.due_at) : null,
            walkDate:
              customer?.job_walk_at != null
                ? new Date(customer?.job_walk_at)
                : null,
          },
        ],
      },
    });

    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleCustomerUpdate = React.useCallback(
      async (submittedValue: FormValue) => {
        try {
          const [submittedBidReceiver] = submittedValue.bidReceivers;
          const [defaultBidReceiver] =
            defaultValues?.bidReceivers ?? EMPTY_ARRAY;

          if (
            submittedBidReceiver == null ||
            defaultBidReceiver == null ||
            customer == null
          ) {
            throw new Error();
          }

          const associations: Array<Association> = [
            {
              type: AssociationType.CustomerBid,
              id: customer.association_id,
              customer_id: customer.uuid,
              bid_id: bid.attributes.uuid,
              metadata: {
                due_at:
                  submittedBidReceiver.dueDate != null
                    ? submittedBidReceiver.dueDate.toISOString()
                    : null,
                job_walk_at:
                  submittedBidReceiver.walkDate != null
                    ? submittedBidReceiver.walkDate.toISOString()
                    : null,
              },
            },
          ];

          for (const submittedContact of submittedBidReceiver.contacts) {
            if (submittedContact.type === PersistenceType.EXISTING) {
              const isNewEntry =
                defaultBidReceiver.contacts?.find(
                  (existingContact) =>
                    existingContact?.type === PersistenceType.EXISTING &&
                    existingContact.data?.uuid === submittedContact.data.uuid,
                ) == null;

              if (isNewEntry) {
                associations.push({
                  type: AssociationType.ContactRole,
                  contact_id: submittedContact.data.uuid,
                  resource_type: ResourceType.Bid,
                  resource_id: bid.attributes.uuid,
                  metadata: {
                    customer_id: customer.uuid,
                  },
                });
              }
            } else {
              associations.push({
                type: AssociationType.ContactRole,
                contact_id: submittedContact.uuid,
                resource_type: ResourceType.Bid,
                resource_id: bid.attributes.uuid,
                metadata: {
                  customer_id: customer.uuid,
                },
              });
            }
          }

          const deletions: Array<Deletion> = [];
          for (const existingContact of defaultBidReceiver.contacts ??
            EMPTY_ARRAY) {
            const existingContactUuid =
              existingContact?.type === PersistenceType.EXISTING
                ? existingContact.data?.uuid
                : undefined;

            const matchingSubmittedContact = submittedBidReceiver.contacts.find(
              (submittedContact) =>
                submittedContact.type === PersistenceType.EXISTING &&
                submittedContact.data.uuid === existingContactUuid,
            );

            if (matchingSubmittedContact != null) {
              // The contact was not deleted, don't do anything
              continue;
            }

            const resourceId = contacts.find(
              (contact) => contact.uuid === existingContactUuid,
            )?.association_id;

            if (resourceId == null) {
              continue;
            }

            deletions.push({
              resource_type: AssociationType.ContactRole,
              resource_id: resourceId,
            });
          }

          await trigger({
            bulk_transaction: {
              associations,
              deletions,
            },
          }).unwrap();
          onClose();
        } catch (error) {
          showBulkTransactionNotifications(error);
        }
      },
      [
        bid.attributes.uuid,
        contacts,
        customer,
        defaultValues?.bidReceivers,
        onClose,
        trigger,
      ],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Update customer"
      >
        <BidReceiversFormContent control={control} />

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleCustomerUpdate)}
              >
                Update customer
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
