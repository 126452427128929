import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { AddEstimationDialog } from "./AddEstimationDialog";

interface Props {
  readonly bid: LoadedBid;
}

export const AddEstimationButton = React.memo(function _AddEstimationButton({
  bid,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        leftSection={<IconPlus size={14} />}
        onClick={open}
        variant="subtle"
      >
        Add
      </Button>

      {opened && <AddEstimationDialog bid={bid} onClose={close} />}
    </>
  );
});
