import { Group, Stack, Title } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./PageHeader.module.scss";

interface Props extends WithClassname {
  readonly rightSection?: React.ReactNode;
  readonly subtitle?: React.ReactNode;
  readonly title: React.ReactNode;
}

export const PageHeader = React.memo<Props>(function _PageHeader({
  className,
  rightSection,
  subtitle,
  title,
}) {
  return (
    <Group className={classNames(className, styles.root)}>
      <Stack className={styles.titleContainer}>
        {subtitle}
        <Title order={6} size={24}>
          {title}
        </Title>
      </Stack>

      {rightSection}
    </Group>
  );
});
