import { Group, InputWrapper, InputWrapperProps, Radio } from "@mantine/core";
import React from "react";

interface Props extends Omit<InputWrapperProps, "onChange"> {
  readonly onChange: (value: string | null) => void;
  readonly value: string | null;
}

export const RoleStatusField = React.memo(function _RoleStatusField({
  onChange,
  value,
  ...inputWrapperProps
}: Props) {
  const handleTrue = React.useCallback(() => {
    onChange(null);
  }, [onChange]);

  const handleFalse = React.useCallback(() => {
    onChange(new Date().toISOString());
  }, [onChange]);

  return (
    <InputWrapper {...inputWrapperProps}>
      <Group>
        <Radio checked={value == null} label="Yes" onChange={handleTrue} />
        <Radio checked={value != null} label="No" onChange={handleFalse} />
      </Group>
    </InputWrapper>
  );
});
