import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { WithClassname } from "src/types/withClassName";
import { AddEstimationButton } from "./AddEstimationButton";
import { Branch } from "./Branch";
import { EstimationsTable } from "./EstimationsTable";

interface Props extends WithClassname {
  readonly bid: LoadedBid;
}

export const EstimationsSection = React.memo<Props>(
  function _EstimationsSection({ bid, className }) {
    return (
      <ContentSection
        className={className}
        rightSection={<AddEstimationButton bid={bid} />}
        title="Estimations"
      >
        <Branch branchId={bid.attributes.branch_id} />
        <EstimationsTable bid={bid} />
      </ContentSection>
    );
  },
);
