import { type FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";

export function getAuthToken(
  _baseQueryReturnValue: unknown,
  meta: FetchBaseQueryMeta,
): string | undefined {
  const authHeader = meta?.response?.headers.get("Authorization") ?? undefined;

  if (authHeader == null) {
    return;
  }

  return authHeader.split("Bearer ")[1];
}

export async function setAuthToken(
  queryFulfilled: Promise<{ data: string | undefined }>,
) {
  const { data: token } = await queryFulfilled;

  if (token != null) {
    localStorage.setItem("token", token);
  }
}

export function removeAuthToken() {
  localStorage.removeItem("token");
}
