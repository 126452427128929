import { IconTrash } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./DeleteButton.module.scss";

interface Props extends WithClassname {
  readonly onDelete: () => void;
  readonly size?: number;
}

export const DeleteButton = React.memo(function _DeleteButton({
  className,
  onDelete,
  size,
}: Props) {
  return (
    <IconTrash
      className={classNames(className, styles.icon)}
      onClick={onDelete}
      size={size}
    />
  );
});
