import { Select, SelectProps } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React from "react";
import { useUserOptions } from "src/hooks/resourceCache/useUserOptions";
import { EMPTY_STRING } from "src/utils/empty";
import { DEFAULT_SELECT_PLACEHOLDER } from "./constants";

interface Props extends SelectProps {}

export const UserField = React.memo<Props>(function _UserField(props) {
  const [searchValue, setSearchValue] = React.useState(EMPTY_STRING);
  const userOptions = useUserOptions(searchValue);

  return (
    <Select
      clearable={true}
      data={userOptions}
      leftSection={<IconSearch size={16} />}
      onSearchChange={setSearchValue}
      placeholder={DEFAULT_SELECT_PLACEHOLDER}
      searchValue={searchValue}
      searchable={true}
      {...props}
    />
  );
});
