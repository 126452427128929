import classNames from "classnames";
import React from "react";
import { GOOGLE_API_KEY } from "src/constants/googleApiKey";
import { WithClassname } from "src/types/withClassName";
import styles from "./EmbeddedMap.module.scss";

interface Props extends WithClassname {
  readonly googleId?: string;
}

export const EmbeddedMap = React.memo(function _EmbeddedMap({
  className,
  googleId,
}: Props) {
  if (googleId == null) {
    return undefined;
  }

  return (
    <iframe
      className={classNames(className, styles.map)}
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=place_id:${googleId}`}
    />
  );
});
