import React from "react";

type Args = (value: string) => void;

type Returns = React.ChangeEventHandler<HTMLInputElement>;

export function useOnValueChange(onValueChange: Args): Returns {
  return React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );
}
