import { Group, Pill, Table } from "@mantine/core";
import React from "react";
import styles from "src/components/BidProfileView/Overview/Estimations/EstimationsTable.module.scss";
import { UserChip } from "src/components/Frames/EntityChip/UserChip";
import { RowHoverOptions } from "src/components/Frames/RowHoverOptions";
import rowHoverOptionsStyles from "src/components/Frames/RowHoverOptions.module.scss";
import { api } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { EditEstimationDialog } from "./EditEstimationDialog";
import { EstimationButton } from "./EstimationButton";

interface Props {
  readonly bid: LoadedBid;
  readonly shouldHideActions?: boolean;
}

export const EstimationsTable = React.memo<Props>(function _EstimationsTable({
  bid,
  shouldHideActions = false,
}) {
  const [trigger] = api.endpoints.bulkTransaction.useMutation();
  const bidOptions = api.endpoints.getBidOptions.useQuery();
  const [editId, setEditId] = React.useState<string>();

  const handleDialogClose = React.useCallback(() => {
    setEditId(undefined);
  }, []);

  const handleEstimationDelete = React.useCallback(
    (id: string) => {
      const estimation = bid.attributes.estimations.find(
        (entry) => entry.attributes.uuid === id,
      );

      if (estimation?.attributes.id == null) {
        throw new Error();
      }

      trigger({
        bulk_transaction: {
          deletions: [
            {
              resource_type: ResourceType.Estimation,
              resource_id: estimation.attributes.id,
            },
          ],
        },
      });
    },
    [bid.attributes.estimations, trigger],
  );

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Salesperson</Table.Th>
          <Table.Th className={styles.noWrap}>Job type</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th className={styles.noWrap}>Total sell amount</Table.Th>
          {!shouldHideActions && <Table.Th />}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {bid.attributes.estimations.map((estimation) => {
          const user = estimation.users[0];
          return (
            <Table.Tr
              key={estimation.attributes.uuid}
              className={rowHoverOptionsStyles.row}
            >
              <Table.Td>
                {user != null && <UserChip id={user.id.toString()} />}
              </Table.Td>
              <Table.Td>
                <Group gap={5} wrap="wrap">
                  {estimation.attributes.job_types?.map((jobType) => {
                    const displayName = bidOptions.currentData?.jobTypes.find(
                      (entry) => entry.value === jobType,
                    )?.label;

                    return displayName != null ? (
                      <Pill key={jobType}>{displayName}</Pill>
                    ) : undefined;
                  })}
                </Group>
              </Table.Td>
              <Table.Td className={styles.noWrap}>
                {import.meta.env.DEV ? (
                  <EstimationButton estimation={estimation} />
                ) : (
                  bidOptions.currentData?.status.find(
                    ({ value }) => value === estimation.attributes.status,
                  )?.label
                )}
              </Table.Td>
              <Table.Td>
                {estimation.attributes.total != null
                  ? `$${estimation.attributes.total.toLocaleString()}`
                  : undefined}
              </Table.Td>
              {!shouldHideActions && (
                <Table.Td>
                  <RowHoverOptions
                    id={estimation.attributes.uuid}
                    onDelete={handleEstimationDelete}
                    onEdit={setEditId}
                  />
                </Table.Td>
              )}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
      {editId != null && (
        <EditEstimationDialog
          bid={bid}
          estimationId={editId}
          onClose={handleDialogClose}
        />
      )}
    </Table>
  );
});
