import { Sort } from "src/data/api/types/shared/sort";
import { ValuesUnion } from "src/types/valuesUnion";

export const CustomerColumnId = {
  ACCOUNT_MANAGER: "ACCOUNT_MANAGER",
  INDUSTRY: "INDUSTRY",
  NAME: "NAME",
  CREATED_AT: "CREATED_AT",
  CREATED_BY: "CREATED_BY",
  UPDATED_AT: "UPDATED_AT",
  UPDATED_BY: "UPDATED_BY",
  VISTA_CUSTOMER_ID: "VISTA_CUSTOMER_ID",
} as const;
export type CustomerColumnId = ValuesUnion<typeof CustomerColumnId>;

export function getCustomerFilterKeyFromColumnId(key: CustomerColumnId) {
  switch (key) {
    case CustomerColumnId.ACCOUNT_MANAGER:
      return "users";
    case CustomerColumnId.INDUSTRY:
      return "industry_id";
    case CustomerColumnId.CREATED_BY:
      return "created_by";
    case CustomerColumnId.UPDATED_BY:
      return "updated_by";
  }
}

export function getCustomerSortFromColumnId(
  key: CustomerColumnId | undefined,
  sort: Sort | undefined,
) {
  if (key == null || sort == null) {
    return;
  }

  switch (key) {
    case CustomerColumnId.ACCOUNT_MANAGER:
      return { users: { first_name: sort } };
    case CustomerColumnId.INDUSTRY:
      return { industry_id: sort };
    case CustomerColumnId.CREATED_AT:
      return { created_at: sort };
    case CustomerColumnId.CREATED_BY:
      return { created_by: { first_name: sort } };
    case CustomerColumnId.NAME:
      return { name: sort };
    case CustomerColumnId.UPDATED_AT:
      return { updated_at: sort };
    case CustomerColumnId.UPDATED_BY:
      return { updated_by: { first_name: sort } };
    case CustomerColumnId.VISTA_CUSTOMER_ID:
      return { vista_customer_id: sort };
  }
}

export const CUSTOMER_LABEL_BY_ID = {
  [CustomerColumnId.ACCOUNT_MANAGER]: "Account manager",
  [CustomerColumnId.INDUSTRY]: "Industry",
  [CustomerColumnId.NAME]: "Name",
  [CustomerColumnId.CREATED_AT]: "Created at",
  [CustomerColumnId.CREATED_BY]: "Created by",
  [CustomerColumnId.UPDATED_AT]: "Updated at",
  [CustomerColumnId.UPDATED_BY]: "Updated by",
  [CustomerColumnId.VISTA_CUSTOMER_ID]: "Vista customer ID",
};
