import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { WithClassname } from "src/types/withClassName";
import { formatDateTime } from "src/utils/formatDateTime";
import styles from "./ContactInfo.module.scss";
import { EditContactButton } from "./EditContactButton";

interface Props extends WithClassname {
  readonly contact: LoadedContact;
}

export const ContactInfo = React.memo<Props>(function _ContactInfo({
  className,
  contact,
}) {
  return (
    <ContentSection
      className={className}
      rightSection={<EditContactButton contact={contact} />}
      title="Information"
    >
      <Table highlightOnHover={true} withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Email address</Table.Td>
            <Table.Td className={styles.columnValue}>
              {contact.attributes.email}
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td className={styles.columnName}>Phone number</Table.Td>
            <Table.Td className={styles.columnValue}>
              {contact.attributes.phone}
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td className={styles.columnName}>Created</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(contact.meta.created_at)}
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td className={styles.columnName}>Last updated</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(contact.meta.updated_at)}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </ContentSection>
  );
});
