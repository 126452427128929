import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconShare } from "@tabler/icons-react";
import React from "react";
import styles from "./ShareButton.module.scss";

export const ShareButton = React.memo(function _ShareButton() {
  const clipboard = useClipboard();

  const handleLinkCopy = React.useCallback(() => {
    clipboard.copy(window.location.href);
    clipboard.error == null
      ? notifications.show({
          title: "Copied successfully",
          message: "You can now share the copied URL",
          withCloseButton: false,
        })
      : notifications.show({
          title: "Copy failed",
          message: "We were unable to copy the URL. Please try again",
          withCloseButton: false,
        });
  }, [clipboard]);

  return <IconShare className={styles.icon} onClick={handleLinkCopy} />;
});
