import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from "@hello-pangea/dnd";
import { Button, Group, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconColumns } from "@tabler/icons-react";
import React from "react";
import { BidColumnId, DEFAULT_BID_COLUMNS } from "../../constants";
import { BID_LABEL_BY_ID } from "../utils";
import { ColumnRow } from "./ColumnRow";
import styles from "./EditColumnsButton.module.scss";

const TITLE = "Edit columns";

interface Column {
  readonly id: string;
  readonly isVisible: boolean;
}

interface Props {
  readonly columnOrder: ReadonlyArray<string>;
  readonly isDisabled?: boolean;
  readonly onColumnOrderChange: (columnOrder: ReadonlyArray<string>) => void;
}

export const EditColumnsButton = React.memo<Props>(function _EditColumnsButton({
  columnOrder,
  isDisabled = false,
  onColumnOrderChange,
}) {
  const [isEditColumnsDialogOpen, { open, close }] = useDisclosure(false);
  const [columnOrderState, setColumnOrderState] = React.useState<
    ReadonlyArray<Column>
  >(() => getColumnOrderStateFromColumnOrder(columnOrder));

  const handleColumnVisibilityToggle = React.useCallback((columnId: string) => {
    setColumnOrderState((prev) =>
      prev.map((prevColumn) =>
        prevColumn.id === columnId
          ? { id: prevColumn.id, isVisible: !prevColumn.isVisible }
          : prevColumn,
      ),
    );
  }, []);

  const handleCancel = React.useCallback(() => {
    close();
    setColumnOrderState(getColumnOrderStateFromColumnOrder(columnOrder));
  }, [close, columnOrder]);

  const handleSave = React.useCallback(() => {
    onColumnOrderChange(
      columnOrderState
        .filter((column) => column.isVisible)
        .map((column) => column.id),
    );
    close();
  }, [close, columnOrderState, onColumnOrderChange]);

  const handleDragEnd = React.useCallback((result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setColumnOrderState((prev) =>
      reorder(prev, result.source.index, result.destination?.index),
    );
  }, []);

  return (
    <React.Fragment>
      <Button
        classNames={{ label: styles.button }}
        disabled={isDisabled}
        onClick={open}
        variant="outline"
      >
        <IconColumns size={16} /> {TITLE}
      </Button>

      <Modal
        centered={true}
        onClose={handleCancel}
        opened={isEditColumnsDialogOpen}
        title={TITLE}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="edit-columns"
            // Basically modal is a portal and draggable glitches w/o this in a portal.
            // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
            renderClone={(provided, _snapshot, rubric) => {
              const isChecked =
                columnOrderState.find(
                  (column) => column.id === rubric.draggableId,
                )?.isVisible ?? false;

              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <ColumnRow
                    key={rubric.draggableId}
                    columnId={rubric.draggableId}
                    displayName={
                      BID_LABEL_BY_ID[rubric.draggableId as BidColumnId]
                    }
                    isChecked={isChecked}
                    onColumnToggle={handleColumnVisibilityToggle}
                  />
                </div>
              );
            }}
          >
            {(droppable) => (
              <div {...droppable.droppableProps} ref={droppable.innerRef}>
                {columnOrderState.map((column, index) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}
                  >
                    {(draggable, draggableSnapshot) => (
                      <div
                        ref={draggable.innerRef}
                        {...draggable.draggableProps}
                        {...draggable.dragHandleProps}
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggable.draggableProps.style,
                        )}
                      >
                        <ColumnRow
                          key={column.id}
                          columnId={column.id}
                          displayName={
                            BID_LABEL_BY_ID[column.id as BidColumnId]
                          }
                          isChecked={column.isVisible}
                          onColumnToggle={handleColumnVisibilityToggle}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Group gap={10} justify="end">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Modal>
    </React.Fragment>
  );
});

function getColumnOrderStateFromColumnOrder(
  columnOrder: ReadonlyArray<string>,
): ReadonlyArray<Column> {
  const visibleColumnSet = new Set(columnOrder);

  return [
    ...columnOrder.map((column) => ({
      id: column,
      isVisible: true,
    })),
    ...DEFAULT_BID_COLUMNS.filter(
      (column) => !visibleColumnSet.has(column),
    ).map((column) => ({
      id: column,
      isVisible: false,
    })),
  ];
}

function reorder(
  list: ReadonlyArray<Column>,
  startIndex: number,
  endIndex?: number,
) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  if (removed != null && endIndex != null) {
    result.splice(endIndex, 0, removed);
  }

  return result;
}

function getItemStyle(
  _isDragging: boolean,
  draggableStyle?: React.CSSProperties,
): React.CSSProperties {
  return {
    userSelect: "none",
    marginBottom: 5,
    // styles we need to apply on draggables
    ...draggableStyle,
  };
}
