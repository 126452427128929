import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { WithClassname } from "src/types/withClassName";
import { AddCustomerButton } from "./AddCustomerButton";
import { CustomersTable } from "./CustomersTable";

interface Props extends WithClassname {
  readonly bid: LoadedBid;
}

export const CustomersSection = React.memo<Props>(function _CustomersSection({
  bid,
  className,
}) {
  return (
    <ContentSection
      className={className}
      rightSection={<AddCustomerButton bid={bid} />}
      title="Customers"
    >
      <CustomersTable bid={bid} />
    </ContentSection>
  );
});
