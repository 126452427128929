import { Group, Stack } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { PageHeader } from "src/components/Frames/PageHeader";
import { CUSTOMERS_CONFIG } from "src/constants/navBarConfigs";
import { EMPTY_STRING } from "src/utils/empty";
import { SearchInput } from "../Frames/SearchInput";
import styles from "./CustomerView.module.scss";
import { NewCustomerButton } from "./Header/NewCustomerButton";
import { CustomerTable } from "./Table/CustomerTable";

export const CustomerView = React.memo(function _CustomerView() {
  const [query, setQuery] = useInputState(EMPTY_STRING);

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <SearchInput onQueryChange={setQuery} query={query} />
            <NewCustomerButton />
          </Group>
        }
        title={
          <Group gap={4}>
            {CUSTOMERS_CONFIG.icon}
            {CUSTOMERS_CONFIG.name}
          </Group>
        }
      />
      <CustomerTable query={query} />
    </Stack>
  );
});
