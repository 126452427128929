import { Avatar, Box, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import classNames from "classnames";
import React from "react";
import { api } from "src/data/api/api";
import { Note } from "src/data/api/types/shared/note";
import { selectCurrentUserId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { WithClassname } from "src/types/withClassName";
import { formatDateTime } from "src/utils/formatDateTime";
import { NoteDialog } from "./NoteDialog";
import styles from "./NoteFrame.module.scss";
import { NoteHoverOptions } from "./NoteHoverOptions";

interface Props extends WithClassname {
  readonly isLoading: boolean;
  readonly note: Note;
  readonly onNoteDelete: (id: number) => Promise<void>;
  readonly onNoteUpdate: (id: number, content: string) => Promise<void>;
}

export const NoteFrame = React.memo<Props>(function _NoteFrame({
  className,
  isLoading,
  note,
  onNoteDelete,
  onNoteUpdate,
}) {
  const [opened, { open, close }] = useDisclosure(false);
  const currentUserId = useAppSelector(selectCurrentUserId);

  const authorUserId = note.user_id;

  const { currentData: authorUser } = api.endpoints.getUser.useQuery(
    authorUserId ?? skipToken,
  );

  const authorUserName =
    authorUser != null
      ? `${authorUser?.user.data.attributes.first_name} ${authorUser?.user.data.attributes.last_name}`
      : undefined;

  const handleEdit = React.useCallback(() => {
    open();
  }, [open]);

  const handleDelete = React.useCallback(() => {
    if (note.id != null) {
      onNoteDelete(note.id);
    }
  }, [note.id, onNoteDelete]);

  return (
    <Box className={classNames(styles.container, className)}>
      <Group gap="xs" justify="space-between">
        <Group gap="xs">
          <Avatar radius="xl" size="sm" />
          {authorUser != null && (
            <>
              <span className={styles.title}>{authorUserName}</span>
              <span className={styles.date}>
                {formatDateTime(note.created_at)}
              </span>
            </>
          )}
        </Group>

        {currentUserId === authorUserId.toString() && (
          <NoteHoverOptions onDelete={handleDelete} onEdit={handleEdit} />
        )}
      </Group>

      {note.content}

      {opened && (
        <NoteDialog
          isLoading={isLoading}
          note={note}
          onClose={close}
          onNoteUpdate={onNoteUpdate}
        />
      )}
    </Box>
  );
});
