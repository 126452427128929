import {
  ColDef,
  GridOptions,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomerChip } from "src/components/Frames/EntityChip/CustomerChip";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { UserCell } from "src/components/Frames/Table/UserCell";
import { FilterHeader } from "src/components/Table/Filter/FilterHeader";
import { PaginationFooter } from "src/components/Table/PaginationFooter";
import { NO_OP_COMPARATOR } from "src/constants/noOpComparator";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { FilterArgs, FilterEntry } from "src/data/api/types/shared/filterSort";
import { ResourceCacheType } from "src/data/types/resourceCacheType";
import { useTableSort } from "src/hooks/table/useTableSort";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { Mutable } from "src/types/mutable";
import { asMutableArray } from "src/utils/asMutableArray";
import { formatDateTime } from "src/utils/formatDateTime";
import { isNonEmptyString } from "src/utils/isNonEmptyString";
import styles from "./ContactTable.module.scss";
import {
  CONTACT_LABEL_BY_ID,
  ContactColumnId,
  getContactFilterKeyFromColumnId,
  getContactSortFromColumnId,
} from "./utils";

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
  autoSizeStrategy: {
    type: "fitGridWidth",
  },
};

interface Props {
  readonly query: string;
}

export const ContactTable = React.memo(function _ContactTable({
  query,
}: Props) {
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const { onSortChanged, sortState } = useTableSort<ContactColumnId>();
  const [filterSetById, setFilterSetById] = React.useState<
    Partial<Record<ContactColumnId, ReadonlySet<string>>>
  >({
    [ContactColumnId.COMPANY]: new Set(),
  });

  const filterQuery = React.useMemo((): FilterArgs | undefined => {
    const and: Mutable<NonNullable<FilterArgs["search"]>["and"]> = [];

    for (const [key, filterSet] of Object.entries(filterSetById)) {
      if (filterSet != null && filterSet.size > 0) {
        const or: Array<FilterEntry> = [];

        for (const filterValue of filterSet) {
          const filterKey = getContactFilterKeyFromColumnId(
            key as ContactColumnId,
          );

          if (filterKey != null) {
            or.push({
              [filterKey]: filterValue,
            });
          }
        }

        and.push({ or });
      }
    }

    if (isNonEmptyString(query)) {
      and.push({ query });
    }

    const search = and.length > 0 ? { and } : undefined;

    return {
      search,
      sort: getContactSortFromColumnId(sortState?.columnId, sortState?.sort),
      page: currentPageIndex,
    };
  }, [
    currentPageIndex,
    filterSetById,
    query,
    sortState?.columnId,
    sortState?.sort,
  ]);

  const agGridRef = React.useRef<AgGridReact<LoadedContact>>(null);

  const contacts = api.endpoints.getContacts.useQuery(filterQuery);

  const columnDefs: ReadonlyArray<ColDef<LoadedContact>> = React.useMemo(
    () => [
      {
        colId: ContactColumnId.NAME,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.NAME],
        valueGetter: (v) =>
          `${v.data?.attributes.first_name} ${v.data?.attributes.last_name}`,
      },
      {
        colId: ContactColumnId.EMAIL,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.EMAIL],
        valueGetter: (v) => v.data?.attributes.email,
      },
      {
        colId: ContactColumnId.PHONE_NUMBER,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.PHONE_NUMBER],
        valueGetter: (v) => v.data?.attributes.phone,
      },
      {
        colId: ContactColumnId.COMPANY,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.COMPANY],
        cellRenderer: (v: ValueGetterParams<LoadedContact>) => {
          const currentCompanyId = v.data?.attributes.customer_roles.find(
            (entry) => entry.roles[0]?.departed_at == null,
          )?.id;

          return (
            currentCompanyId != null && (
              <CustomerChip id={currentCompanyId.toString()} />
            )
          );
        },
      },
      {
        colId: ContactColumnId.CREATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.CREATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.created_at),
      },
      {
        colId: ContactColumnId.CREATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.CREATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedContact>) => {
          return <UserCell userId={v.data?.meta.created_by?.id.toString()} />;
        },
      },
      {
        colId: ContactColumnId.UPDATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.UPDATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.updated_at),
      },
      {
        colId: ContactColumnId.UPDATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: CONTACT_LABEL_BY_ID[ContactColumnId.UPDATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedContact>) => {
          return <UserCell userId={v.data?.meta.updated_by?.id.toString()} />;
        },
      },
    ],
    [],
  );

  const optionsOrResourceType = React.useMemo(() => {
    return {
      [ContactColumnId.COMPANY]: ResourceCacheType.Customer,
      [ContactColumnId.CREATED_BY]: ResourceCacheType.User,
      [ContactColumnId.UPDATED_BY]: ResourceCacheType.User,
    };
  }, []);

  const handleRowClick = React.useCallback(
    (row: RowSelectedEvent<LoadedContact>) => {
      if (row.data?.id == null) {
        return;
      }

      navigate(Paths.CURRENT + Paths.SLASH + row.data.id);
    },
    [navigate],
  );

  if (contacts.data?.collection.data == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Table} />;
  }

  return (
    <div className={classNames("ag-theme-quartz", styles.tableWrapper)}>
      <FilterHeader
        filterSetById={filterSetById}
        labelById={CONTACT_LABEL_BY_ID}
        onFilterSetByIdChange={setFilterSetById}
        optionsOrResourceTypeById={optionsOrResourceType}
      />

      <AgGridReact<LoadedContact>
        ref={agGridRef}
        columnDefs={asMutableArray(columnDefs)}
        gridOptions={GRID_OPTIONS}
        onRowClicked={handleRowClick}
        onSortChanged={onSortChanged}
        rowData={asMutableArray(contacts.data.collection.data)}
      />

      {contacts.data != null && (
        <PaginationFooter
          currentPageIndex={currentPageIndex}
          onPageChange={setCurrentPageIndex}
          totalPageCount={contacts.data.total_pages}
        />
      )}
    </div>
  );
});
