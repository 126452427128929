import { EMPTY_STRING } from "./empty";

export function formatCurrency(value: number | undefined) {
  if (value == null) {
    return EMPTY_STRING;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.round(value));
}
