import { Chip } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { CHIP_STYLES } from "./constants";

interface Props {
  readonly id: string;
}

export const ContactChip = React.memo(function _ContactChip({ id }: Props) {
  const navigate = useNavigate();

  const { data } = api.endpoints.getContact.useQuery(id);

  const handleClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.CONTACTS + Paths.SLASH + id);
  }, [id, navigate]);

  return (
    <Chip
      checked={true}
      color="gray"
      icon={<IconUser size={14} />}
      onClick={handleClick}
      radius="sm"
      styles={CHIP_STYLES}
      variant="outline"
    >
      {data?.contact.data.attributes.first_name}{" "}
      {data?.contact.data.attributes.last_name}
    </Chip>
  );
});
