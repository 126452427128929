import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { api } from "src/data/api/api";
import { DEFAULT_SELECT_PLACEHOLDER, LOADING_PLACEHOLDER } from "./constants";

interface Props extends SelectProps {}

export const IndustrySelectField = React.memo<Props>(
  function _IndustrySelectField(props) {
    const { currentData } = api.endpoints.getCustomerOptions.useQuery();

    return (
      <Select
        clearable={true}
        data={currentData?.industries}
        disabled={currentData == null}
        placeholder={
          currentData == null ? LOADING_PLACEHOLDER : DEFAULT_SELECT_PLACEHOLDER
        }
        {...props}
      />
    );
  },
);
