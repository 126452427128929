import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { NewJobSiteFormContent } from "src/components/Forms/NewJobSiteFormContent";
import { NewJobSite } from "src/components/Forms/types/jobSite";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  ACCOUNT_MANAGER_ROLE,
  Association,
  AssociationType,
  Deletion,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";

type FormValue = NewJobSite["data"];

interface Props {
  readonly jobSite: LoadedJobSite;
  readonly onClose: () => void;
}

export const EditJobSiteDialog = React.memo<Props>(function _EditJobSiteDialog({
  jobSite,
  onClose,
}) {
  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      address: {
        id: jobSite.attributes.address?.google_id,
        text: jobSite.attributes.address?.full_address,
      },
      buildingClass: jobSite.attributes.build_class,
      accountManager: jobSite.attributes.users?.[0]?.uuid,
    },
  });
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleJobSiteUpdate = React.useCallback(
    async (submittedValue: FormValue) => {
      try {
        if (submittedValue.address == null) {
          throw new Error();
        }

        const roleId = jobSite.attributes.users?.[0]?.roles.find(
          (entry) =>
            entry.resource_type === ResourceType.JobSite &&
            entry.resource_id === jobSite.attributes.id &&
            entry.name === ACCOUNT_MANAGER_ROLE,
        )?.id;

        const associations: Array<Association> = [];
        const deletions: Array<Deletion> = [];
        if (submittedValue.accountManager != null) {
          associations.push({
            id: roleId,
            metadata: {
              name: ACCOUNT_MANAGER_ROLE,
            },
            resource_id: jobSite.attributes.uuid,
            resource_type: ResourceType.JobSite,
            type: AssociationType.Role,
            user_id: submittedValue.accountManager,
          });
        } else if (roleId != null) {
          deletions.push({
            resource_id: roleId,
            resource_type: AssociationType.Role,
          });
        }

        await trigger({
          bulk_transaction: {
            associations,
            job_sites_attributes: [
              {
                id: jobSite.attributes.id,
                company_id: 1 as const,
                build_class: submittedValue.buildingClass,
                address_attributes: {
                  id: jobSite.attributes.address?.id,
                  full_address: submittedValue.address?.text,
                  google_id: submittedValue.address?.id,
                },
              },
            ],
            deletions,
          },
        });

        onClose();
      } catch (error) {
        // error notification
      }
    },
    [jobSite.attributes, onClose, trigger],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Edit existing job site"
    >
      <NewJobSiteFormContent control={control} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleJobSiteUpdate)}
            >
              Edit job site
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
