import { Anchor, Group, Stack, Tabs } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "src/components/Frames/PageHeader";
import { ShareButton } from "src/components/Frames/ShareButton";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { DeleteButton } from "../Frames/DeleteButton";
import { LoadingScreen } from "../Frames/LoadingScreen";
import styles from "./BidProfileView.module.scss";
import { Overview } from "./Overview/Overview";

const TABS = ["Overview", "Estimation", "Documents"] as const;

export const BidProfileView = React.memo(function _BidProfileView() {
  const navigate = useNavigate();
  const { bidId } = useParams<MatchParam<"BID_ID">>();

  const bid = api.endpoints.getBid.useQuery(bidId ?? skipToken);
  const [deleteBid] = api.endpoints.deleteBid.useMutation();

  const handleBidsClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.BIDS);
  }, [navigate]);

  const handleBidDelete = React.useCallback(async () => {
    if (bidId == null) {
      return;
    }

    await deleteBid(bidId);
    handleBidsClick();
  }, [bidId, deleteBid, handleBidsClick]);

  if (bid.currentData == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Profile} />;
  }

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <ShareButton />
            <DeleteButton onDelete={handleBidDelete} />
          </Group>
        }
        subtitle={
          <span className={styles.subtitle}>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleBidsClick}
            >
              Bids
            </Anchor>
            <span className={styles.slash}>/</span>
            <span className={styles.path}>
              {bid.currentData.bid.data.attributes.name}
            </span>
          </span>
        }
        title={bid.currentData.bid.data.attributes.name}
      />

      <Group className={styles.group}>
        <Tabs className={styles.tabs} defaultValue={TABS[0]}>
          <Tabs.List p="0 20px">
            {TABS.map((tab) => (
              <Tabs.Tab key={tab} value={tab}>
                {tab}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel className={styles.panel} value={TABS[0]}>
            <Overview bid={bid.currentData.bid.data} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS[1]}>{TABS[1]}</Tabs.Panel>

          <Tabs.Panel value={TABS[2]}>{TABS[2]}</Tabs.Panel>
        </Tabs>
      </Group>
    </Stack>
  );
});
