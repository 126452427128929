import { ById } from "src/types/byId";
import { getLabelValueFromObject, LabelValue } from "src/types/util/labelValue";

export interface GetJobSiteOptionsReturns {
  readonly collection: ById<ById<string>>;
}

export interface TransformedGetJobSiteOptionsReturns {
  readonly buildingClass: ReadonlyArray<LabelValue>;
}

export function getTransformedJobSiteOptions({
  collection,
}: GetJobSiteOptionsReturns) {
  return {
    buildingClass: getLabelValueFromObject(collection.building_class),
  };
}
