import {
  Button,
  CheckIcon,
  CloseButton,
  Combobox,
  Group,
  Input,
  InputBase,
  InputWrapperProps,
  Modal,
  Text,
  useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import React from "react";
import { useForm } from "react-hook-form";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { api } from "src/data/api/api";
import {
  ACCOUNT_MANAGER_ROLE,
  Association,
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { useCustomerOptions } from "src/hooks/resourceCache/useCustomerOptions";
import { EMPTY_STRING } from "src/utils/empty";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";
import { NewCustomerFormContent } from "../Forms/NewCustomerFormContent";
import {
  DEFAULT_NEW_CUSTOMER_VALUE,
  ExistingCustomer,
  NewCustomer,
} from "../Forms/types/customer";
import {
  createExisting,
  createNew,
  PersistenceType,
} from "../Forms/types/persistenceType";
import { FormFooter } from "../Frames/FormFooter";
import { SEARCH_PLACEHOLDER } from "./constants";

type FieldValue = NewCustomer | ExistingCustomer | null;

interface Props extends Omit<InputWrapperProps, "onChange"> {
  readonly disabled?: boolean;
  readonly onChange: (value: FieldValue) => void;
  readonly value: FieldValue;
}

const NEW_VALUE = "$NEW_VALUE";

export const CustomerSelectField = React.memo(function _CustomerSelectField({
  disabled = false,
  onChange,
  value,
  ...inputWrapperProps
}: Props) {
  const allOptions = useCustomerOptions();
  const [search, setSearch] = React.useState(
    () =>
      allOptions.find(
        (option) =>
          value?.type === PersistenceType.EXISTING &&
          value.data.uuid === option.value,
      )?.label ?? EMPTY_STRING,
  );
  const searchedOptions = useCustomerOptions(search);

  const handleResetSearch = React.useCallback(() => {
    if (value?.type === PersistenceType.NEW) {
      setSearch(value.data.name);
    } else if (value?.type === PersistenceType.EXISTING) {
      setSearch(
        allOptions.find((option) => option.value === value.data.uuid)?.label ??
          EMPTY_STRING,
      );
    } else {
      setSearch(EMPTY_STRING);
    }
  }, [allOptions, value]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleOptionSubmit = React.useCallback(
    (submittedValue: string) => {
      if (submittedValue === NEW_VALUE) {
        combobox.closeDropdown();
        // Do not submit changes until the dialog is either submitted or canceled.
        return;
      } else {
        onChange(createExisting({ uuid: submittedValue }));
        setSearch(
          searchedOptions.find((option) => option.value === submittedValue)
            ?.label ?? EMPTY_STRING,
        );
        combobox.closeDropdown();
      }
    },
    [combobox, searchedOptions, onChange],
  );

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      combobox.openDropdown();
      combobox.updateSelectedOptionIndex();
      setSearch(event.currentTarget.value);
    },
    [combobox],
  );

  const handleSearchBlur = React.useCallback(() => {
    handleResetSearch();
    combobox.closeDropdown();
  }, [combobox, handleResetSearch]);

  const handleClear = React.useCallback(() => {
    setSearch(EMPTY_STRING);
    onChange(null);
  }, [onChange]);

  const [isNewCustomerDialogOpen, { open, close }] = useDisclosure();

  const handleDropdownOpen = React.useCallback(() => {
    combobox.openDropdown();
  }, [combobox]);

  /*
    New Customer Dialog
  */
  const { control, handleSubmit } = useForm<NewCustomer["data"]>({
    defaultValues: DEFAULT_NEW_CUSTOMER_VALUE,
  });

  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleNewCustomerCreate = React.useCallback(
    async (submittedValue: NewCustomer["data"]) => {
      try {
        const customerId = crypto.randomUUID();
        const associations: Array<Association> = [];

        if (submittedValue.accountManager != null) {
          associations.push({
            type: AssociationType.Role,
            user_id: submittedValue.accountManager,
            resource_type: ResourceType.Customer,
            resource_id: customerId,
            metadata: {
              name: ACCOUNT_MANAGER_ROLE,
            },
          });
        }

        await trigger({
          bulk_transaction: {
            associations,
            customers_attributes: [
              {
                uuid: customerId,
                company_id: 1 as const,
                name: submittedValue.name,
                industry_id: submittedValue.industry,
                vista_customer_id: submittedValue.vistaCustomerId,
              },
            ],
          },
        }).unwrap();
        onChange(createNew(submittedValue, customerId));
        setSearch(submittedValue.name);
        close();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [close, onChange, trigger],
  );

  const handleDialogCancel = React.useCallback(() => {
    handleResetSearch();
    close();
  }, [close, handleResetSearch]);

  return (
    <>
      <Input.Wrapper {...inputWrapperProps}>
        <Combobox onOptionSubmit={handleOptionSubmit} store={combobox}>
          <Combobox.Target>
            <InputBase
              disabled={disabled}
              leftSection={<IconSearch size={16} />}
              onBlur={handleSearchBlur}
              onChange={handleSearchChange}
              onClick={handleDropdownOpen}
              onFocus={handleDropdownOpen}
              placeholder={SEARCH_PLACEHOLDER}
              rightSection={
                search !== EMPTY_STRING && !disabled ? (
                  <CloseButton
                    aria-label="Clear value"
                    onClick={handleClear}
                    // prevent other fields from losing focus if user interacts with CloseButton while on another field
                    onMouseDown={PREVENT_DEFAULT}
                    size="sm"
                  />
                ) : (
                  <Combobox.Chevron />
                )
              }
              rightSectionPointerEvents={
                search !== EMPTY_STRING ? "all" : "none"
              }
              value={search}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options>
              <Combobox.Header>
                <Text fz="xs">Select an option or create one</Text>
              </Combobox.Header>

              {searchedOptions.map((item) => (
                <Combobox.Option key={item.value} value={item.value}>
                  <Group gap="xs">
                    {value?.type === PersistenceType.EXISTING &&
                      item.value === value?.data.uuid && (
                        <CheckIcon size={12} />
                      )}
                    <span>{item.label}</span>
                  </Group>
                </Combobox.Option>
              ))}

              {searchedOptions.length === 0 && search.trim().length > 0 && (
                <Combobox.Option onClick={open} value={NEW_VALUE}>
                  Create new customer
                </Combobox.Option>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Input.Wrapper>

      {isNewCustomerDialogOpen && (
        <Modal
          centered={true}
          onClose={handleDialogCancel}
          opened={true}
          size="md"
          title="Create new customer"
        >
          <NewCustomerFormContent control={control} />

          <FormFooter
            rightSection={
              <Group gap={10}>
                <Button
                  loading={mutationState.isLoading}
                  onClick={handleDialogCancel}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button onClick={handleSubmit(handleNewCustomerCreate)}>
                  Create customer
                </Button>
              </Group>
            }
          />
        </Modal>
      )}
    </>
  );
});
