import { ValuesUnion } from "src/types/valuesUnion";

export const BidColumnId = {
  BID_NUMBER: "BID_NUMBER",
  BID_STAGE: "BID_STAGE",
  BRANCH_ID: "BRANCH_ID",
  CONSTRUCTION_TYPE: "CONSTRUCTION_TYPE",
  CONTACTS: "CONTACTS",
  CREATED_AT: "CREATED_AT",
  CREATED_BY: "CREATED_BY",
  CUSTOMERS: "CUSTOMERS",
  DUE_DATE: "DUE_DATE",
  JOB_SITE_ADDRESS: "JOB_SITE_ADDRESS",
  JOB_SITE_ADDRESS_DETAILS: "JOB_SITE_ADDRESS_DETAILS",
  JOB_TYPES: "JOB_TYPES",
  JOB_WALK_DATE: "JOB_WALK_DATE",
  NAME: "NAME",
  PRIORITY: "PRIORITY",
  RELATIONSHIP: "RELATIONSHIP",
  SALES_LEAD: "SALES_LEAD",
  STATUS: "STATUS",
  TOTAL_SELL_AMOUNT: "TOTAL_SELL_AMOUNT",
  UPDATED_AT: "UPDATED_AT",
  UPDATED_BY: "UPDATED_BY",
} as const;
export type BidColumnId = ValuesUnion<typeof BidColumnId>;
export type FilterByColumn = Partial<Record<BidColumnId, ReadonlySet<string>>>;

export const BID_VIEW_TYPE = "BID_VIEW";
export const MY_BIDS_VIEW = "my-bids-view";
export const ALL_BIDS_VIEW = "all-bids-view";

export const DEFAULT_BID_COLUMNS: ReadonlyArray<BidColumnId> = [
  BidColumnId.BID_NUMBER,
  BidColumnId.SALES_LEAD,
  BidColumnId.NAME,
  BidColumnId.JOB_SITE_ADDRESS,
  BidColumnId.JOB_SITE_ADDRESS_DETAILS,
  BidColumnId.CUSTOMERS,
  BidColumnId.CONTACTS,
  BidColumnId.DUE_DATE,
  BidColumnId.STATUS,
  BidColumnId.TOTAL_SELL_AMOUNT,
  BidColumnId.JOB_WALK_DATE,
  BidColumnId.BID_STAGE,
  BidColumnId.JOB_TYPES,
  BidColumnId.PRIORITY,
  BidColumnId.CREATED_AT,
  BidColumnId.CREATED_BY,
  BidColumnId.UPDATED_AT,
  BidColumnId.UPDATED_BY,
];
