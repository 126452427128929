import { Paper, UnstyledButton } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React from "react";
import { Control, useController } from "react-hook-form";
import { REQUIRED_RULES } from "../Forms/constants";
import { NewJobSite } from "../Forms/types/jobSite";
import { ContactSelectField } from "./ContactSelectField";
import { InfluenceRoleSelectField } from "./InfluenceRoleSelectField";
import styles from "./JobSiteContactField.module.scss";

interface Props {
  readonly index: number;
  readonly control: Control<NewJobSite["data"]>;
  readonly onRemove: (index: number) => void;
}

export const JobSiteContactField = React.memo(function _JobSiteContactField({
  index,
  control,
  onRemove,
}: Props) {
  const contact = useController({
    control,
    name: `siteContacts.${index}.contact`,
    rules: REQUIRED_RULES,
  });

  const influence = useController({
    control,
    name: `siteContacts.${index}.influence`,
  });

  const handleSelfRemove = React.useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  return (
    <Paper className={styles.container} p="sm" withBorder={true}>
      <div>
        <UnstyledButton className={styles.button} onClick={handleSelfRemove}>
          <IconTrash size={16} />
        </UnstyledButton>
      </div>
      <ContactSelectField
        error={contact.fieldState.error?.message}
        label="Site contact"
        onChange={contact.field.onChange}
        required={true}
        value={contact.field.value}
      />
      <InfluenceRoleSelectField
        error={influence.fieldState.error?.message}
        label="Influence"
        onChange={influence.field.onChange}
        value={influence.field.value}
      />
    </Paper>
  );
});
