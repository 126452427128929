import { Button, Input, Stack } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { Control, useController, useFieldArray } from "react-hook-form";
import { BranchField } from "src/components/Fields/BranchField";
import { EstimationField } from "src/components/Fields/EstimationField";
import { REQUIRED_RULES } from "../constants";
import { DEFAULT_ESTIMATION_VALUE, NewBid } from "../types/bid";

interface Props {
  readonly control: Control<NewBid["estimation"]>;
}

export const EstimationFormContent = React.memo<Props>(
  function _EstimationFormContent({ control }) {
    const branch = useController({
      control,
      name: "branch",
      rules: REQUIRED_RULES,
    });

    const { fields, append, remove } = useFieldArray({
      control,
      name: "estimations",
    });

    const handleAppend = React.useCallback(() => {
      append(DEFAULT_ESTIMATION_VALUE);
    }, [append]);

    return (
      <>
        <BranchField
          error={branch.fieldState.error?.message}
          onChange={branch.field.onChange}
          value={branch.field.value}
        />

        <Input.Wrapper label="Owner" required={true}>
          <Stack gap="md">
            {fields.map((field, index) => {
              return (
                <EstimationField
                  key={field.id}
                  control={control}
                  index={index}
                  onRemove={fields.length > 1 ? remove : undefined}
                />
              );
            })}
          </Stack>
        </Input.Wrapper>
        <div>
          <Button
            leftSection={<IconPlus size={16} />}
            onClick={handleAppend}
            variant="default"
          >
            Add salesperson
          </Button>
        </div>
      </>
    );
  },
);
