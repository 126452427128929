import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ById } from "src/types/byId";
import { EMPTY_OBJECT } from "src/utils/empty";
import { LoadedContact } from "../api/types/shared/contact";
import { LoadedCustomer } from "../api/types/shared/customer";
import { User } from "../api/types/shared/user";

export interface ResourceCacheState {
  readonly contactByUuid: ById<{
    readonly firstName: string;
    readonly lastName: string;
    readonly customerUuid?: string;
  }>;
  readonly customerByUuid: ById<{
    readonly name: string;
  }>;
  readonly userByUuid: ById<{
    readonly firstName: string;
    readonly lastName: string;
  }>;
}

const INITIAL_STATE: ResourceCacheState = {
  contactByUuid: EMPTY_OBJECT,
  customerByUuid: EMPTY_OBJECT,
  userByUuid: EMPTY_OBJECT,
};

export const resourceCacheSlice = createSlice({
  name: "resourceCache",
  initialState: INITIAL_STATE,
  reducers: {
    addContacts: (
      state,
      action: PayloadAction<ReadonlyArray<LoadedContact>>,
    ) => {
      const { payload } = action;

      for (const contact of payload) {
        state.contactByUuid[contact.attributes.uuid] = {
          firstName: contact.attributes.first_name,
          lastName: contact.attributes.last_name,
          customerUuid: contact.attributes.customer_roles.find((customerRole) =>
            customerRole.roles.some((role) => role.departed_at == null),
          )?.uuid,
        };
      }
    },
    deleteContact: (state, action: PayloadAction<string>) => {
      delete state.contactByUuid[action.payload];
    },
    addCustomers: (
      state,
      action: PayloadAction<ReadonlyArray<LoadedCustomer>>,
    ) => {
      const { payload } = action;

      for (const customer of payload) {
        state.customerByUuid[customer.attributes.uuid] = {
          name: customer.attributes.name,
        };
      }
    },
    deleteCustomer: (state, action: PayloadAction<string>) => {
      delete state.customerByUuid[action.payload];
    },
    addUsers: (state, action: PayloadAction<ReadonlyArray<User>>) => {
      const { payload } = action;

      for (const user of payload) {
        state.userByUuid[user.attributes.uuid] = {
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
        };
      }
    },
    deleteUser: (state, action: PayloadAction<string>) => {
      delete state.userByUuid[action.payload];
    },
  },
});
