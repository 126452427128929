import React from "react";
import { LoadedDashboard } from "src/data/api/types/getDashboard";
import { formatCurrency } from "src/utils/formatCurrency";
import { Units } from "./units";
import { formatBidCount } from "./util/formatBidCount";
import { MetricType, ScopeType } from "./util/utils";

interface TotalsData {
  readonly label: string;
  readonly units: string;
  readonly value: string;
}

interface SalesData {
  readonly labelText: string;
  readonly labelValue: string;
  readonly value: number;
}

interface TotalsDataOptions {
  readonly [ScopeType.Company]: {
    readonly [MetricType.Sales]: TotalsData;
    readonly [MetricType.Volume]: TotalsData;
  };
  readonly [ScopeType.Individual]: {
    readonly [MetricType.Sales]: TotalsData;
    readonly [MetricType.Volume]: TotalsData;
  };
}

interface SalesDataOptions {
  readonly [ScopeType.Company]: {
    readonly [MetricType.Sales]: ReadonlyArray<SalesData>;
    readonly [MetricType.Volume]: ReadonlyArray<SalesData>;
  };
  readonly [ScopeType.Individual]: {
    readonly [MetricType.Sales]: ReadonlyArray<SalesData>;
    readonly [MetricType.Volume]: ReadonlyArray<SalesData>;
  };
}

interface Args {
  readonly data: LoadedDashboard;
  readonly metric: MetricType;
  readonly scope: ScopeType;
}

interface Returns {
  readonly totalsData: TotalsData;
  readonly salesData: ReadonlyArray<SalesData>;
}

export function useChartData({ data, scope, metric }: Args): Returns {
  const totalsData = React.useMemo(() => {
    const totalsDataOptions: TotalsDataOptions = {
      [ScopeType.Company]: {
        [MetricType.Sales]: {
          label: "Total bids submitted",
          units: Units.SELL_AMOUNT,
          value: formatCurrency(data.company_data.dollar_units.total),
        },

        [MetricType.Volume]: {
          label: "Total bids submitted",
          units: Units.COUNT,
          value: formatBidCount(data.company_data.bid_units.total),
        },
      },
      [ScopeType.Individual]: {
        [MetricType.Sales]: {
          label: "Total bids submitted",
          units: Units.SELL_AMOUNT,
          value: formatCurrency(data.user_data.dollar_units.total),
        },

        [MetricType.Volume]: {
          label: "Total bids submitted",
          units: Units.COUNT,
          value: formatBidCount(data.user_data.bid_units.total),
        },
      },
    };

    return totalsDataOptions[scope][metric];
  }, [data, metric, scope]);

  const salesData = React.useMemo(() => {
    const salesDataOptions: SalesDataOptions = {
      [ScopeType.Company]: {
        [MetricType.Sales]: [
          {
            labelText: `Total bids pending ${Units.SELL_AMOUNT}`,
            labelValue: formatCurrency(
              data.company_data.dollar_units.submitted,
            ),
            value: data.company_data.dollar_units.submitted,
          },
          {
            labelText: `Total bids won ${Units.SELL_AMOUNT}`,
            labelValue: formatCurrency(data.company_data.dollar_units.won),
            value: data.company_data.dollar_units.won,
          },
          {
            labelText: `Total bids lost ${Units.SELL_AMOUNT}`,
            labelValue: formatCurrency(data.company_data.dollar_units.lost),
            value: data.company_data.dollar_units.lost,
          },
        ],
        [MetricType.Volume]: [
          {
            labelText: `Total bids pending ${Units.COUNT}`,
            labelValue: formatBidCount(data.company_data.bid_units.submitted),
            value: data.company_data.bid_units.submitted,
          },
          {
            labelText: `Total bids won ${Units.COUNT}`,
            labelValue: formatBidCount(data.company_data.bid_units.won),
            value: data.company_data.bid_units.won,
          },
          {
            labelText: `Total bids lost ${Units.COUNT}`,
            labelValue: formatBidCount(data.company_data.bid_units.lost),
            value: data.company_data.bid_units.lost,
          },
        ],
      },
      [ScopeType.Individual]: {
        [MetricType.Sales]: [
          {
            labelText: `Total bids pending ${Units.SELL_AMOUNT}`,
            labelValue: formatCurrency(data.user_data.dollar_units.submitted),
            value: data.user_data.dollar_units.submitted,
          },
          {
            labelText: `Total bids won ${Units.SELL_AMOUNT}`,
            labelValue: formatCurrency(data.user_data.dollar_units.won),
            value: data.user_data.dollar_units.won,
          },
          {
            labelText: `Total bids lost ${Units.SELL_AMOUNT}`,
            labelValue: formatCurrency(data.user_data.dollar_units.lost),
            value: data.user_data.dollar_units.lost,
          },
        ],
        [MetricType.Volume]: [
          {
            labelText: `Total bids pending ${Units.COUNT}`,
            labelValue: formatBidCount(data.user_data.bid_units.submitted),
            value: data.user_data.bid_units.submitted,
          },
          {
            labelText: `Total bids won ${Units.COUNT}`,
            labelValue: formatBidCount(data.user_data.bid_units.won),
            value: data.user_data.bid_units.won,
          },
          {
            labelText: `Total bids lost ${Units.COUNT}`,
            labelValue: formatBidCount(data.user_data.bid_units.lost),
            value: data.user_data.bid_units.lost,
          },
        ],
      },
    };

    return salesDataOptions[scope][metric];
  }, [data, metric, scope]);

  return { totalsData, salesData };
}
