import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { AddContactDialog } from "./AddContactDialog";

interface Props {
  readonly customer: LoadedCustomer;
}

export const AddContactButton = React.memo(function _AddContactButton({
  customer,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        leftSection={<IconPlus size={14} />}
        onClick={open}
        variant="subtle"
      >
        Add
      </Button>

      {opened && <AddContactDialog customer={customer} onClose={close} />}
    </>
  );
});
