import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { ContactChip } from "src/components/Frames/EntityChip/ContactChip";
import { RowHoverOptions } from "src/components/Frames/RowHoverOptions";
import styles from "src/components/Frames/RowHoverOptions.module.scss";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { WithClassname } from "src/types/withClassName";
import { AddContactButton } from "./AddContactButton";
import { EditContactDialog } from "./EditContactDialog";

interface Props extends WithClassname {
  readonly customer: LoadedCustomer;
}

export const Contacts = React.memo<Props>(function _Contacts({
  className,
  customer,
}) {
  const contactRoles = api.endpoints.getContactRoleOptions.useQuery();
  const [trigger] = api.endpoints.bulkTransaction.useMutation();
  const [editId, setEditId] = React.useState<number>();

  const contactToEdit = React.useMemo(() => {
    return customer.attributes.contacts.find(
      (contact) => contact.id === editId,
    );
  }, [customer.attributes.contacts, editId]);

  const handleDialogClose = React.useCallback(() => {
    setEditId(undefined);
  }, []);

  const handleEstimationDelete = React.useCallback(
    (id: number) => {
      const contactRow = customer.attributes.contacts.find(
        (entry) => entry.id === id,
      );
      const roleToDelete = contactRow?.roles.find(
        (entry) =>
          entry.resource_type === ResourceType.Customer &&
          entry.resource_id === customer.attributes.id,
      );

      if (roleToDelete == null) {
        throw new Error();
      }

      trigger({
        bulk_transaction: {
          deletions: [
            {
              resource_type: AssociationType.ContactRole,
              resource_id: roleToDelete.id,
            },
          ],
        },
      });
    },
    [customer.attributes.contacts, customer.attributes.id, trigger],
  );

  return (
    <ContentSection
      className={className}
      rightSection={<AddContactButton customer={customer} />}
      title="Contacts"
    >
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Role</Table.Th>
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {customer.attributes.contacts.map((contact) => {
            const role = contact.roles.find(
              (entry) =>
                entry.resource_type === ResourceType.Customer &&
                entry.resource_id === customer.attributes.id,
            );

            return (
              <Table.Tr key={contact.id} className={styles.row}>
                <Table.Td>
                  <ContactChip id={contact.id.toString()} />
                </Table.Td>
                <Table.Td>
                  {
                    contactRoles.currentData?.title.find(
                      ({ value }) => value === role?.name,
                    )?.label
                  }
                </Table.Td>
                <Table.Td>
                  {role?.departed_at == null ? "Current" : "Past"}
                </Table.Td>
                <Table.Td>
                  <RowHoverOptions
                    id={contact.id}
                    onDelete={handleEstimationDelete}
                    onEdit={setEditId}
                  />
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>

      {contactToEdit != null && (
        <EditContactDialog
          contact={contactToEdit}
          customer={customer}
          onClose={handleDialogClose}
        />
      )}
    </ContentSection>
  );
});
