import { Group, Stack } from "@mantine/core";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./TwoColumnOverview.module.scss";

interface Props extends WithClassname {
  readonly leftSection: React.ReactNode;
  readonly rightSection: React.ReactNode;
  readonly leftWidthPercentage?: number;
  readonly rightWidthPercentage?: number;
}

export const TwoColumnOverview = React.memo<Props>(function _TwoColumnOverview({
  leftSection,
  rightSection,
  leftWidthPercentage = 50,
  rightWidthPercentage = 50,
}) {
  return (
    <Group className={styles.container} gap="md">
      <Stack h="100%" w={`${leftWidthPercentage}%`}>
        {leftSection}
      </Stack>
      <Stack h="100%" w={`${rightWidthPercentage}%`}>
        {rightSection}
      </Stack>
    </Group>
  );
});
