import { Button } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { Estimation } from "src/data/api/types/shared/bid";

interface Props {
  readonly estimation: Estimation;
}

export const EstimationButton = React.memo(function _EstimationButton({
  estimation,
}: Props) {
  const navigate = useNavigate();

  const handleEstimationStart = React.useCallback(() => {
    navigate(Paths.ESTIMATIONS + Paths.SLASH + estimation.id);
  }, [estimation.id, navigate]);

  return <Button onClick={handleEstimationStart}>Start estimation</Button>;
});
