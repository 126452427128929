import { Group, Select, TextInput } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { AddressField } from "src/components/Fields/AddressField";
import {
  DEFAULT_SELECT_PLACEHOLDER,
  LOADING_PLACEHOLDER,
} from "src/components/Fields/constants";
import { api } from "src/data/api/api";
import { useOnValueChange } from "src/hooks/useOnValueChange";
import { REQUIRED_RULES } from "../constants";
import { NewBid } from "../types/bid";

interface Props {
  readonly control: Control<NewBid["attributes"]>;
}

export const BidFormContent = React.memo<Props>(function _BidFormContent({
  control,
}) {
  const { currentData, isLoading } = api.endpoints.getBidOptions.useQuery();

  const projectName = useController({
    control,
    name: "name",
    rules: REQUIRED_RULES,
  });
  const handleProjectNameChange = useOnValueChange(projectName.field.onChange);

  const jobSiteAddress = useController({
    control,
    name: "jobSiteAddress",
    rules: REQUIRED_RULES,
  });

  const jobSiteAddressDetails = useController({
    control,
    name: "jobSiteAddressDetails",
  });
  const handleJobSiteAddressDetailsChange = useOnValueChange(
    jobSiteAddressDetails.field.onChange,
  );

  const constructionType = useController({
    control,
    name: "constructionType",
    rules: REQUIRED_RULES,
  });

  const bidStage = useController({
    control,
    name: "bidStage",
    rules: REQUIRED_RULES,
  });

  const relationship = useController({
    control,
    name: "relationship",
    rules: REQUIRED_RULES,
  });

  const difficulty = useController({
    control,
    name: "difficulty",
  });

  const priority = useController({
    control,
    name: "priority",
  });

  const selectPlaceholder = isLoading
    ? LOADING_PLACEHOLDER
    : DEFAULT_SELECT_PLACEHOLDER;

  return (
    <>
      <TextInput
        error={projectName.fieldState.error?.message}
        label="Project name"
        onChange={handleProjectNameChange}
        placeholder="Project name..."
        required={true}
        value={projectName.field.value}
      />
      <AddressField
        error={jobSiteAddress.fieldState.error?.message}
        label="Job site address"
        onChange={jobSiteAddress.field.onChange}
        required={true}
        value={jobSiteAddress.field.value}
      />
      <TextInput
        error={jobSiteAddressDetails.fieldState.error?.message}
        label="Suite/floor"
        onChange={handleJobSiteAddressDetailsChange}
        placeholder="Enter suite, floor or building number"
        value={jobSiteAddressDetails.field.value}
      />
      <Select
        clearable={true}
        data={currentData?.constructionType}
        disabled={isLoading}
        error={constructionType.fieldState.error?.message}
        label="Construction type"
        onChange={constructionType.field.onChange}
        placeholder={selectPlaceholder}
        required={true}
        value={constructionType.field.value}
      />
      <Group grow={true}>
        <Select
          clearable={true}
          data={currentData?.bidStage}
          disabled={isLoading}
          error={bidStage.fieldState.error?.message}
          label="Bid stage"
          onChange={bidStage.field.onChange}
          placeholder={selectPlaceholder}
          required={true}
          value={bidStage.field.value}
        />
        <Select
          clearable={true}
          data={currentData?.relationship}
          disabled={isLoading}
          error={relationship.fieldState.error?.message}
          label="Contract type"
          onChange={relationship.field.onChange}
          placeholder={selectPlaceholder}
          required={true}
          value={relationship.field.value}
        />
      </Group>
      <Group grow={true}>
        <Select
          clearable={true}
          data={currentData?.difficulty}
          disabled={isLoading}
          error={difficulty.fieldState.error?.message}
          label="Expected difficulty"
          onChange={difficulty.field.onChange}
          placeholder={selectPlaceholder}
          value={difficulty.field.value}
        />
        <Select
          clearable={true}
          data={currentData?.priority}
          disabled={isLoading}
          error={priority.fieldState.error?.message}
          label="Priority"
          onChange={priority.field.onChange}
          placeholder={selectPlaceholder}
          value={priority.field.value}
        />
      </Group>
    </>
  );
});
