import React from "react";
import { NotesModule } from "src/components/Frames/NotesModule";
import { TwoColumnOverview } from "src/components/Frames/TwoColumnOverview";
import { api } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { BidReceivers } from "./BidReceivers";
import { JobSiteInfo } from "./JobSiteInfo/JobSiteInfo";
import { SiteContacts } from "./SiteContacts/SiteContacts";

interface Props {
  readonly jobSite: LoadedJobSite;
}

export const Overview = React.memo<Props>(function _Overview({ jobSite }) {
  const [addNote, { isLoading: isAddNoteLoading }] =
    api.endpoints.addNote.useMutation();
  const [updateNote, { isLoading: isUpdateNoteLoading }] =
    api.endpoints.updateNote.useMutation();
  const [deleteNote] = api.endpoints.deleteNote.useMutation();

  const handleNoteAdd = React.useCallback(
    async (content: string) => {
      if (jobSite.attributes.id != null) {
        await addNote({
          note: {
            content: content,
            resource_id: jobSite.attributes.id.toString(),
            resource_type: ResourceType.JobSite,
          },
        });
      }
    },
    [addNote, jobSite.attributes.id],
  );

  const handleNoteDelete = React.useCallback(
    async (id: number) => {
      await deleteNote(id);
    },
    [deleteNote],
  );

  const handleNoteUpdate = React.useCallback(
    async (id: number, content: string) => {
      await updateNote({
        id: id,
        note: {
          content: content,
        },
      });
    },
    [updateNote],
  );

  return (
    <TwoColumnOverview
      leftSection={
        <React.Fragment>
          <JobSiteInfo jobSite={jobSite} />
        </React.Fragment>
      }
      rightSection={
        <React.Fragment>
          <SiteContacts jobSite={jobSite} />
          <BidReceivers jobSite={jobSite} />
          <NotesModule
            isAddNoteLoading={isAddNoteLoading}
            isUpdateNoteLoading={isUpdateNoteLoading}
            notes={jobSite.attributes.notes}
            onNoteAdd={handleNoteAdd}
            onNoteDelete={handleNoteDelete}
            onNoteUpdate={handleNoteUpdate}
          />
        </React.Fragment>
      }
    />
  );
});
