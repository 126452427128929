import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil } from "@tabler/icons-react";
import React from "react";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { EditCustomerDialog } from "./EditCustomerDialog";

interface Props {
  readonly customer: LoadedCustomer;
}

export const EditCustomerButton = React.memo(function _EditCustomerButton({
  customer,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        leftSection={<IconPencil size={14} />}
        onClick={open}
        variant="subtle"
      >
        Edit
      </Button>

      {opened && <EditCustomerDialog customer={customer} onClose={close} />}
    </>
  );
});
