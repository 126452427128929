import { Anchor, Group, Stack, Tabs } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "src/components/Frames/PageHeader";
import { ShareButton } from "src/components/Frames/ShareButton";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { BidTable } from "../BidView/Table/BidTable";
import { DeleteButton } from "../Frames/DeleteButton";
import { LoadingScreen } from "../Frames/LoadingScreen";
import styles from "./JobSiteProfileView.module.scss";
import { Overview } from "./Overview/Overview";

const TABS = ["Overview", "Bids", "Documents"] as const;

export const JobSiteProfileView = React.memo(function _JobSiteProfileView() {
  const navigate = useNavigate();
  const { jobSiteId } = useParams<MatchParam<"JOB_SITE_ID">>();

  const jobSite = api.endpoints.getJobSite.useQuery(jobSiteId ?? skipToken);
  const [deleteJobSite] = api.endpoints.deleteJobSite.useMutation();

  const handleJobSiteClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.JOB_SITES);
  }, [navigate]);

  const handleBidDelete = React.useCallback(async () => {
    if (jobSiteId == null) {
      return;
    }

    await deleteJobSite(jobSiteId);
    handleJobSiteClick();
  }, [deleteJobSite, handleJobSiteClick, jobSiteId]);

  if (jobSite.currentData == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Profile} />;
  }

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <ShareButton />
            <DeleteButton onDelete={handleBidDelete} />
          </Group>
        }
        subtitle={
          <span className={styles.subtitle}>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleJobSiteClick}
            >
              Job Sites
            </Anchor>
            <span className={styles.slash}>/</span>
            <span className={styles.path}>
              {
                jobSite.currentData.job_site.data.attributes.address
                  ?.full_address
              }
            </span>
          </span>
        }
        title={
          jobSite.currentData.job_site.data.attributes.address?.full_address
        }
      />

      <Group className={styles.group}>
        <Tabs className={styles.tabs} defaultValue={TABS[0]}>
          <Tabs.List p="0 20px">
            {TABS.map((tab) => (
              <Tabs.Tab key={tab} value={tab}>
                {tab}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel className={styles.panel} value={TABS[0]}>
            <Overview jobSite={jobSite.currentData.job_site.data} />
          </Tabs.Panel>

          <Tabs.Panel className={styles.panel} value={TABS[1]}>
            <BidTable
              jobSiteIdFilter={
                jobSite.currentData.job_site.data.attributes.uuid
              }
            />
          </Tabs.Panel>

          <Tabs.Panel value={TABS[2]}>{TABS[2]}</Tabs.Panel>
        </Tabs>
      </Group>
    </Stack>
  );
});
