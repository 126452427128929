import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { ContactChip } from "src/components/Frames/EntityChip/ContactChip";
import { RowHoverOptions } from "src/components/Frames/RowHoverOptions";
import styles from "src/components/Frames/RowHoverOptions.module.scss";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { WithClassname } from "src/types/withClassName";
import { AddSiteContactButton } from "./AddSiteContactButton";
import { EditSiteContactDialog } from "./EditSiteContactDialog";

interface Props extends WithClassname {
  readonly jobSite: LoadedJobSite;
}

export const SiteContacts = React.memo<Props>(function _SiteContacts({
  className,
  jobSite,
}) {
  const [trigger] = api.endpoints.bulkTransaction.useMutation();
  const roles = api.endpoints.getContactRoleOptions.useQuery();
  const [editId, setEditId] = React.useState<number>();

  const handleDialogClose = React.useCallback(() => {
    setEditId(undefined);
  }, []);

  const handleSiteContactDelete = React.useCallback(
    (id: number) => {
      const contactRow = jobSite.attributes.contacts?.find(
        (contact) => contact.id === id,
      );
      const roleToDelete = contactRow?.roles.find(
        (role) =>
          role.contact_id === id &&
          role.resource_type === ResourceType.JobSite &&
          role.resource_id === jobSite.attributes.id,
      );

      if (roleToDelete == null) {
        throw new Error();
      }

      trigger({
        bulk_transaction: {
          deletions: [
            {
              resource_type: AssociationType.ContactRole,
              resource_id: roleToDelete.id,
            },
          ],
        },
      });
    },
    [jobSite.attributes.contacts, jobSite.attributes.id, trigger],
  );

  const contactToEdit = React.useMemo(() => {
    return jobSite.attributes.contacts?.find(
      (contact) => contact.id === editId,
    );
  }, [editId, jobSite.attributes.contacts]);

  return (
    <ContentSection
      className={className}
      rightSection={<AddSiteContactButton jobSite={jobSite} />}
      title="Site contacts"
    >
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Influence</Table.Th>
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {jobSite.attributes.contacts?.map((contact) => {
            return (
              <Table.Tr key={contact.id} className={styles.row}>
                <Table.Td>
                  <ContactChip id={contact.id.toString()} />
                </Table.Td>
                <Table.Td>
                  {
                    roles.currentData?.influence.find(
                      ({ value }) => value === contact.roles[0]?.name,
                    )?.label
                  }
                </Table.Td>
                <Table.Td>
                  {contact.roles[0]?.departed_at == null ? "Current" : "Past"}
                </Table.Td>
                <Table.Td>
                  <RowHoverOptions
                    id={contact.id}
                    onDelete={handleSiteContactDelete}
                    onEdit={setEditId}
                  />
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>

      {contactToEdit != null && (
        <EditSiteContactDialog
          contact={contactToEdit}
          jobSite={jobSite}
          onClose={handleDialogClose}
        />
      )}
    </ContentSection>
  );
});
