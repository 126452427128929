import { Group, Paper, Stack, UnstyledButton } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconTrash } from "@tabler/icons-react";
import React from "react";
import { Control, useController } from "react-hook-form";
import { EMPTY_ARRAY } from "src/utils/empty";
import { REQUIRED_RULES } from "../Forms/constants";
import { NewBid } from "../Forms/types/bid";
import { ExistingCustomer, NewCustomer } from "../Forms/types/customer";
import { PersistenceType } from "../Forms/types/persistenceType";
import styles from "./BidReceiverField.module.scss";
import { ContactMultiSelectField } from "./ContactMultiSelectField";
import { CustomerSelectField } from "./CustomerSelectField";

const DATE_PLACEHOLDER = "Pick a date...";
const DATE_INPUT_TIME_FORMAT = "MM/DD/YYYY HH:mm";

interface Props {
  readonly control: Control<NewBid["bidReceivers"]>;
  readonly index: number;
  readonly onRemove: (index: number) => void;
  readonly shouldAllowCustomerEdit?: boolean;
  readonly shouldAllowDelete?: boolean;
}

export const BidReceiverField = React.memo<Props>(function _BidReceiverField({
  control,
  index,
  onRemove,
  shouldAllowDelete = false,
  shouldAllowCustomerEdit = false,
}) {
  const customer = useController({
    control,
    name: `bidReceivers.${index}.customer`,
    rules: REQUIRED_RULES,
  });

  const contacts = useController({
    control,
    name: `bidReceivers.${index}.contacts`,
    rules: REQUIRED_RULES,
  });

  const dueDate = useController({
    control,
    name: `bidReceivers.${index}.dueDate`,
  });

  const walkDate = useController({
    control,
    name: `bidReceivers.${index}.walkDate`,
  });

  const handleCustomerChange = React.useCallback(
    (newValue: NewCustomer | ExistingCustomer | null) => {
      customer.field.onChange(newValue);
      contacts.field.onChange(EMPTY_ARRAY);
    },
    [contacts.field, customer.field],
  );

  const handleSelfRemove = React.useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  return (
    <Paper
      className={styles.container}
      component={Stack}
      gap={0}
      p="md"
      shadow="xs"
      withBorder={true}
    >
      {shouldAllowDelete && (
        <div>
          <UnstyledButton className={styles.button} onClick={handleSelfRemove}>
            <IconTrash size={16} />
          </UnstyledButton>
        </div>
      )}
      <CustomerSelectField
        disabled={!shouldAllowCustomerEdit}
        label="Which company are you issuing bid to?"
        onChange={handleCustomerChange}
        required={true}
        value={customer.field.value}
      />
      <ContactMultiSelectField
        disabled={customer.field.value?.type == null}
        filteredCompanyId={
          customer.field.value?.type === PersistenceType.EXISTING
            ? customer.field.value.data.uuid
            : undefined
        }
        label="Who is your bid contact?"
        onChange={contacts.field.onChange}
        required={true}
        value={contacts.field.value}
      />
      <Group grow={true}>
        <DateInput
          clearable={true}
          label="Due date"
          onChange={dueDate.field.onChange}
          placeholder={DATE_PLACEHOLDER}
          value={dueDate.field.value}
          valueFormat={DATE_INPUT_TIME_FORMAT}
        />
        <DateInput
          clearable={true}
          label="Job walk date"
          onChange={walkDate.field.onChange}
          placeholder={DATE_PLACEHOLDER}
          value={walkDate.field.value}
          valueFormat={DATE_INPUT_TIME_FORMAT}
        />
      </Group>
    </Paper>
  );
});
