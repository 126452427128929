import {
  Button,
  CheckIcon,
  Combobox,
  Group,
  Modal,
  Pill,
  PillsInput,
  SelectProps,
  useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { useForm } from "react-hook-form";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { selectCustomerByUuid } from "src/data/selectors/resourceCache";
import { useAppSelector } from "src/data/store";
import { useContactOptions } from "src/hooks/resourceCache/useContactOptions";
import { EMPTY_STRING } from "src/utils/empty";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";
import { NewContactFormContent } from "../Forms/NewContactFormContent";
import {
  DEFAULT_NEW_CONTACT_VALUE,
  ExistingContact,
  NewContact,
} from "../Forms/types/contact";
import {
  createExisting,
  createNew,
  PersistenceType,
} from "../Forms/types/persistenceType";
import { FormFooter } from "../Frames/FormFooter";
import { SEARCH_PLACEHOLDER } from "./constants";

const NEW_VALUE = "$NEW_VALUE";

type FieldValue = ReadonlyArray<NewContact | ExistingContact>;

interface Props extends Omit<SelectProps, "value" | "onChange"> {
  readonly filteredCompanyId?: string;
  readonly onChange: (value: FieldValue) => void;
  readonly value: FieldValue;
}

export function ContactMultiSelectField({
  disabled,
  filteredCompanyId,
  label,
  onChange,
  required,
  value,
}: Props) {
  const [search, setSearch] = React.useState(EMPTY_STRING);
  const contactOptions = useContactOptions(search);
  const customerByUuid = useAppSelector(selectCustomerByUuid);

  const existingContactOptions = React.useMemo(() => {
    return contactOptions
      .map((contactOption) => {
        return {
          label: contactOption.label,
          value: contactOption.value,
          company: contactOption.customerUuid,
          isEmployee:
            filteredCompanyId != null &&
            contactOption.customerUuid !== filteredCompanyId,
        };
      })
      .filter((item) =>
        item.label.toLowerCase().includes(search.trim().toLowerCase()),
      )
      .sort((a, b) => {
        if (a.isEmployee === b.isEmployee) {
          return 0;
        } else if (a.isEmployee) {
          return 1;
        } else {
          return -1;
        }
      });
  }, [contactOptions, filteredCompanyId, search]);

  /*
    Combo Box
  */
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const shouldShowCreateOption = React.useMemo(
    () =>
      search.trim().length > 0 &&
      !existingContactOptions.some((item) => item.label === search),
    [existingContactOptions, search],
  );

  const handleValueSelect = React.useCallback(
    (submittedValue: string) => {
      setSearch(EMPTY_STRING);

      if (submittedValue === NEW_VALUE) {
        return;
      }

      const exists = value.findIndex(
        (existing) =>
          existing.type === PersistenceType.EXISTING &&
          existing.data.uuid === submittedValue,
      );

      if (exists !== -1) {
        onChange(value.filter((_, index) => index !== exists));
      } else {
        onChange([
          ...value,
          createExisting({
            uuid: submittedValue,
          }),
        ]);
      }
    },
    [onChange, value],
  );

  const handleValueRemove = (indexToRemove: number) =>
    onChange(value.filter((_, index) => index !== indexToRemove));

  /*
    New Contact Dialog
  */
  const [isNewContactDialogOpen, { open, close }] = useDisclosure();

  const { control, handleSubmit, reset } = useForm<NewContact["data"]>({
    defaultValues: DEFAULT_NEW_CONTACT_VALUE,
  });

  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleDialogClose = React.useCallback(() => {
    reset();
    close();
  }, [close, reset]);

  const handleNewContactCreate = React.useCallback(
    async (submittedValue: NewContact["data"]) => {
      const contactUuid = crypto.randomUUID();
      if (submittedValue.workplace == null) {
        return;
      }

      const customerId =
        submittedValue.workplace.type === PersistenceType.EXISTING
          ? submittedValue.workplace.data.uuid
          : submittedValue.workplace.uuid;

      try {
        await trigger({
          bulk_transaction: {
            associations: [
              {
                type: AssociationType.ContactRole,
                contact_id: contactUuid,
                resource_id: customerId,
                resource_type: ResourceType.Customer,
                metadata: {
                  name: submittedValue.role ?? undefined,
                },
              },
            ],
            contacts_attributes: [
              {
                uuid: contactUuid,
                company_id: 1,
                email: submittedValue.email,
                first_name: submittedValue.firstName,
                last_name: submittedValue.lastName,
                phone: submittedValue.phoneNumber,
              },
            ],
          },
        }).unwrap();
        onChange([...value, createNew(submittedValue, contactUuid)]);
        handleDialogClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [trigger, onChange, value, handleDialogClose],
  );

  return (
    <>
      <Combobox onOptionSubmit={handleValueSelect} store={combobox}>
        <Combobox.DropdownTarget>
          <PillsInput
            disabled={disabled}
            label={label}
            onClick={() => combobox.openDropdown()}
            required={required}
          >
            <Pill.Group>
              {value.map((entry, index) => (
                <Pill
                  key={
                    entry.type === PersistenceType.EXISTING
                      ? entry.data.uuid
                      : entry.data.email
                  }
                  onRemove={() => handleValueRemove(index)}
                  withRemoveButton
                >
                  {entry.type === PersistenceType.EXISTING
                    ? existingContactOptions.find(
                        (existingOption) =>
                          existingOption.value === entry.data.uuid,
                      )?.label
                    : `${entry.data.firstName} ${entry.data.lastName}`}
                </Pill>
              ))}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onBlur={() => combobox.closeDropdown()}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onFocus={() => combobox.openDropdown()}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(value.length - 1);
                    }
                  }}
                  placeholder={SEARCH_PLACEHOLDER}
                  value={search}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>
            {existingContactOptions.map((item) => (
              <Combobox.Option
                key={item.value}
                active={value.some(
                  (existing) =>
                    existing.type === PersistenceType.EXISTING &&
                    existing.data.uuid === item.value,
                )}
                value={item.value}
              >
                <Group gap="sm">
                  {value.some(
                    (existing) =>
                      existing.type === PersistenceType.EXISTING &&
                      existing.data.uuid === item.value,
                  ) ? (
                    <CheckIcon size={12} />
                  ) : undefined}

                  {/* TODO: this may lead to company name being blank, implement something like customer chip */}
                  <span>
                    {item.label} -{" "}
                    {customerByUuid[item.company ?? EMPTY_STRING]?.name}
                  </span>
                </Group>
              </Combobox.Option>
            ))}

            {shouldShowCreateOption && (
              <Combobox.Option onClick={open} value={NEW_VALUE}>
                Create new contact
              </Combobox.Option>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>

      {isNewContactDialogOpen && (
        <Modal
          centered={true}
          onClose={handleDialogClose}
          opened={true}
          size="md"
          title="Create new contact"
        >
          <NewContactFormContent
            control={control}
            shouldRenderRole={true}
            shouldRenderWorkplace={true}
          />

          <FormFooter
            rightSection={
              <Group gap={10}>
                <Button
                  loading={mutationState.isLoading}
                  onClick={handleDialogClose}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button onClick={handleSubmit(handleNewContactCreate)}>
                  Create contact
                </Button>
              </Group>
            }
          />
        </Modal>
      )}
    </>
  );
}
