import { TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React from "react";

interface Props {
  readonly onQueryChange: (event: React.ChangeEvent) => void;
  readonly query: string;
}

export const SearchInput = React.memo<Props>(function _SearchInput({
  onQueryChange,
  query,
}) {
  return (
    <TextInput
      leftSection={<IconSearch size={16} />}
      onChange={onQueryChange}
      placeholder="Search"
      value={query}
      w={300}
    />
  );
});
