import React from "react";
import { NotesModule } from "src/components/Frames/NotesModule";
import { TwoColumnOverview } from "src/components/Frames/TwoColumnOverview";
import { api } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { BidInfo } from "./BidInfo/BidInfo";
import { CustomersSection } from "./Customers/CustomersSection";
import { EstimationsSection } from "./Estimations/EstimationsSection";

interface Props {
  readonly bid: LoadedBid;
}

export const Overview = React.memo<Props>(function _Overview({ bid }) {
  const [addNote, { isLoading: isAddNoteLoading }] =
    api.endpoints.addNote.useMutation();
  const [updateNote, { isLoading: isUpdateNoteLoading }] =
    api.endpoints.updateNote.useMutation();
  const [deleteNote] = api.endpoints.deleteNote.useMutation();

  const handleNoteAdd = React.useCallback(
    async (content: string) => {
      if (bid.attributes.id != null) {
        await addNote({
          note: {
            content: content,
            resource_id: bid.attributes.id.toString(),
            resource_type: ResourceType.Bid,
          },
        });
      }
    },
    [addNote, bid.attributes.id],
  );

  const handleNoteDelete = React.useCallback(
    async (id: number) => {
      await deleteNote(id);
    },
    [deleteNote],
  );

  const handleNoteUpdate = React.useCallback(
    async (id: number, content: string) => {
      await updateNote({
        id: id,
        note: {
          content: content,
        },
      });
    },
    [updateNote],
  );

  return (
    <TwoColumnOverview
      leftSection={<BidInfo bid={bid} />}
      rightSection={
        <React.Fragment>
          <CustomersSection bid={bid} />
          <EstimationsSection bid={bid} />
          <NotesModule
            isAddNoteLoading={isAddNoteLoading}
            isUpdateNoteLoading={isUpdateNoteLoading}
            notes={bid.attributes.notes}
            onNoteAdd={handleNoteAdd}
            onNoteDelete={handleNoteDelete}
            onNoteUpdate={handleNoteUpdate}
          />
        </React.Fragment>
      }
    />
  );
});
