import { Group } from "@mantine/core";
import React from "react";
import styles from "./FormFooter.module.scss";

interface Props {
  readonly leftSection?: React.ReactElement;
  readonly rightSection?: React.ReactElement;
}

export const FormFooter = React.memo<Props>(function _FormFooter({
  leftSection = <div />,
  rightSection = <div />,
}) {
  return (
    <Group className={styles.footer} justify="space-between">
      {leftSection}
      {rightSection}
    </Group>
  );
});
