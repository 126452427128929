import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { BidFormContent } from "src/components/Forms/NewBid/BidFormContent";
import { NewBid } from "src/components/Forms/types/bid";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import { LoadedBid } from "src/data/api/types/shared/bid";

type FormValue = NewBid["attributes"];

interface Props {
  readonly bid: LoadedBid;
  readonly onClose: () => void;
}

export const EditBidDialog = React.memo<Props>(function _EditBidDialog({
  bid,
  onClose,
}) {
  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      name: bid.attributes.name,
      jobSiteAddress: {
        id: bid.attributes.address?.google_id,
        text: bid.attributes.address?.full_address,
      },
      jobSiteAddressDetails: bid.attributes.address_details,
      constructionType: bid.attributes.construction_type,
      bidStage: bid.attributes.bid_stage,
      relationship: bid.attributes.relationship,
      difficulty: bid.attributes.difficulty,
      priority: bid.attributes.priority,
    },
  });
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleBidUpdate = React.useCallback(
    async (submittedValue: FormValue) => {
      try {
        await trigger({
          bulk_transaction: {
            job_sites_attributes: [
              {
                id: bid.attributes.job_site.id,
                address_attributes: {
                  id: bid.attributes.address?.id,
                  full_address: submittedValue.jobSiteAddress?.text,
                  google_id: submittedValue.jobSiteAddress?.id,
                },
                bids_attributes: [
                  {
                    address_details: submittedValue.jobSiteAddressDetails,
                    bid_stage: submittedValue.bidStage,
                    company_id: 1 as const,
                    construction_type: submittedValue.constructionType,
                    difficulty: submittedValue.difficulty,
                    id: bid.attributes.id,
                    name: submittedValue.name,
                    priority: submittedValue.priority,
                    relationship: submittedValue.relationship,
                  },
                ],
              },
            ],
          },
        });

        onClose();
      } catch (error) {
        // error notification
      }
    },
    [
      bid.attributes.address?.id,
      bid.attributes.id,
      bid.attributes.job_site.id,
      onClose,
      trigger,
    ],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Edit existing bid"
    >
      <BidFormContent control={control} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleBidUpdate)}
            >
              Edit bid
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
