import { ById } from "src/types/byId";
import { LabelValue } from "src/types/util/labelValue";
import { EMPTY_OBJECT } from "src/utils/empty";

interface OriginalReturns {
  readonly collection: ById<{
    readonly category: string;
    readonly display_name: string;
  }>;
}

export interface GetContactRoleOptionsReturns {
  readonly influence: ReadonlyArray<LabelValue>;
  readonly title: ReadonlyArray<LabelValue>;
}

export function getTransformedContactRoleOptions({
  collection,
}: OriginalReturns): GetContactRoleOptionsReturns {
  const influence: Array<LabelValue> = [];
  const title: Array<LabelValue> = [];

  for (const [key, { display_name, category } = EMPTY_OBJECT] of Object.entries(
    collection,
  )) {
    const entry: LabelValue = { value: key, label: display_name };
    if (category === "title") {
      title.push(entry);
    } else if (category === "influence") {
      influence.push(entry);
    } else {
      console.warn(`Unknown category in contact role options - ${category}`);
    }
  }

  return {
    influence,
    title,
  };
}
