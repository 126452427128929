import { ById } from "src/types/byId";
import { getLabelValueFromObject, LabelValue } from "src/types/util/labelValue";

export interface OriginalReturns {
  readonly collection: ById<string>;
}

export interface GetCustomerOptionsReturns {
  readonly industries: ReadonlyArray<LabelValue>;
}

export function getTransformedCustomerOptions({
  collection,
}: OriginalReturns): GetCustomerOptionsReturns {
  return {
    industries: getLabelValueFromObject(collection),
  };
}
