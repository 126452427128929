import { BulkTransactionError } from "src/data/api/types/bulkTransaction";
import { showFailNotification } from "./showFailNotification";
import { showValidationNotification } from "./showValidationNotification";

export function showBulkTransactionNotifications(error: unknown): void {
  if ((error as BulkTransactionError).data != null) {
    (error as BulkTransactionError).data?.messages?.map((message) => {
      showValidationNotification({
        message: message[0] ?? "There was an error, please try again.",
      });
    });
  } else {
    showFailNotification();
  }
}
