import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { EmbeddedMap } from "src/components/Frames/EmbeddedMap";
import { JobSiteChip } from "src/components/Frames/EntityChip/JobSiteChip";
import { api } from "src/data/api/api";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { WithClassname } from "src/types/withClassName";
import { formatDateTime } from "src/utils/formatDateTime";
import styles from "./BidInfo.module.scss";
import { EditBidButton } from "./EditBidButton";

interface Props extends WithClassname {
  readonly bid: LoadedBid;
}

export const BidInfo = React.memo<Props>(function _BidInfo({ bid, className }) {
  const bidOptions = api.endpoints.getBidOptions.useQuery();

  return (
    <ContentSection
      className={className}
      rightSection={<EditBidButton bid={bid} />}
      title="Bid Info"
    >
      <EmbeddedMap
        className={styles.map}
        googleId={bid.attributes.address?.google_id}
      />

      <Table highlightOnHover={true} withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Bid number</Table.Td>
            <Table.Td className={styles.columnValue}>{bid.id}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Project name</Table.Td>
            <Table.Td className={styles.columnValue}>
              {bid.attributes.name}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Job site address</Table.Td>
            <Table.Td className={styles.columnValue}>
              <JobSiteChip id={bid.attributes.job_site.id.toString()} />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Suite/floor</Table.Td>
            <Table.Td className={styles.columnValue}>
              {bid.attributes.address_details}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Construction type</Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                bidOptions.currentData?.constructionType.find(
                  ({ value }) => value === bid.attributes.construction_type,
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Contract type</Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                bidOptions.currentData?.relationship.find(
                  ({ value }) => value === bid.attributes.relationship,
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Bid stage</Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                bidOptions.currentData?.bidStage.find(
                  ({ value }) => value === bid.attributes.bid_stage,
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>
              Expected Difficulty
            </Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                bidOptions.currentData?.difficulty.find(
                  ({ value }) => value === bid.attributes.difficulty,
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Priority</Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                bidOptions.currentData?.priority.find(
                  ({ value }) => value === bid.attributes.priority,
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Created</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(bid.meta.created_at)}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Last updated</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(bid.meta.updated_at)}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </ContentSection>
  );
});
