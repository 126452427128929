import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { NewCustomerFormContent } from "src/components/Forms/NewCustomerFormContent";
import { NewCustomer } from "src/components/Forms/types/customer";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  ACCOUNT_MANAGER_ROLE,
  Association,
  AssociationType,
  Deletion,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormValues = NewCustomer["data"];

interface Props {
  readonly customer: LoadedCustomer;
  readonly onClose: () => void;
}

export const EditCustomerDialog = React.memo<Props>(
  function _EditCustomerDialog({ customer, onClose }) {
    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();
    const { control, handleSubmit } = useForm<FormValues>({
      defaultValues: {
        name: customer.attributes.name,
        industry: customer.attributes.industry_id.toString(),
        accountManager: customer.attributes.users[0]?.uuid,
        vistaCustomerId: customer.attributes.vista_customer_id,
      },
    });

    const handleCustomerUpdate = React.useCallback(
      async (submittedValue: FormValues) => {
        try {
          const roleId = customer.attributes.users?.[0]?.roles.find(
            (entry) =>
              entry.resource_type === ResourceType.Customer &&
              entry.resource_id === customer.attributes.id &&
              entry.name === ACCOUNT_MANAGER_ROLE,
          )?.id;

          const associations: Array<Association> = [];
          const deletions: Array<Deletion> = [];
          if (submittedValue.accountManager != null) {
            associations.push({
              id: roleId,
              metadata: {
                name: ACCOUNT_MANAGER_ROLE,
              },
              resource_id: customer.attributes.uuid,
              resource_type: ResourceType.Customer,
              type: AssociationType.Role,
              user_id: submittedValue.accountManager,
            });
          } else if (roleId != null) {
            deletions.push({
              resource_id: roleId,
              resource_type: AssociationType.Role,
            });
          }

          await trigger({
            bulk_transaction: {
              associations,
              customers_attributes: [
                {
                  id: customer.attributes.id,
                  company_id: 1 as const,
                  industry_id: submittedValue.industry,
                  name: submittedValue.name,
                  vista_customer_id: submittedValue.vistaCustomerId,
                },
              ],
              deletions,
            },
          }).unwrap();
          onClose();
        } catch (error) {
          showBulkTransactionNotifications(error);
        }
      },
      [
        customer.attributes.id,
        customer.attributes.users,
        customer.attributes.uuid,
        onClose,
        trigger,
      ],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Edit existing customer"
      >
        <NewCustomerFormContent control={control} />

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleCustomerUpdate)}
              >
                Edit customer
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
