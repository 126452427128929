import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil } from "@tabler/icons-react";
import React from "react";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { EditJobSiteDialog } from "./EditJobSiteDialog";

interface Props {
  readonly jobSite: LoadedJobSite;
}

export const EditJobSiteButton = React.memo(function _EditJobSiteButton({
  jobSite,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        leftSection={<IconPencil size={14} />}
        onClick={open}
        variant="subtle"
      >
        Edit
      </Button>

      {opened && <EditJobSiteDialog jobSite={jobSite} onClose={close} />}
    </>
  );
});
