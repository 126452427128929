import { Pagination } from "@mantine/core";
import React from "react";
import styles from "./PaginationFooter.module.scss";

interface Props {
  readonly currentPageIndex: number;
  readonly onPageChange: (index: number) => void;
  readonly totalPageCount: number;
}

export const PaginationFooter = React.memo<Props>(function _PaginationFooter({
  currentPageIndex,
  onPageChange,
  totalPageCount,
}) {
  React.useEffect(() => {
    onPageChange(1);
    // On total page count change, reset active page to 1
  }, [onPageChange, totalPageCount]);

  return (
    <div className={styles.root}>
      <Pagination
        onChange={onPageChange}
        radius="xl"
        size="sm"
        total={totalPageCount}
        value={currentPageIndex}
        withEdges={true}
      />
    </div>
  );
});
