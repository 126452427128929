import { Tabs } from "@mantine/core";
import React from "react";
import { api } from "src/data/api/api";
import { DeleteButton } from "../Frames/DeleteButton";
import { ALL_BIDS_VIEW, MY_BIDS_VIEW } from "./constants";
import styles from "./ViewTab.module.scss";

interface Props {
  readonly viewId: string;
  readonly name: string;
}

export const ViewTab = React.memo<Props>(function _ViewTab({ name, viewId }) {
  const [deleteListView] = api.endpoints.deleteListView.useMutation();

  const handleTabDelete = React.useCallback(() => {
    deleteListView(viewId);
  }, [deleteListView, viewId]);

  const shouldRenderDeleteButton =
    viewId !== MY_BIDS_VIEW && viewId !== ALL_BIDS_VIEW;

  return (
    <Tabs.Tab
      key={viewId}
      classNames={{ tabLabel: styles.container }}
      value={viewId}
    >
      {name}
      {shouldRenderDeleteButton && (
        <DeleteButton
          className={styles.deleteButton}
          onDelete={handleTabDelete}
          size={14}
        />
      )}
    </Tabs.Tab>
  );
});
