import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { JobSiteChip } from "src/components/Frames/EntityChip/JobSiteChip";
import { api } from "src/data/api/api";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { WithClassname } from "src/types/withClassName";

interface Props extends WithClassname {
  readonly contact: LoadedContact;
}

export const DedicatedJobSites = React.memo<Props>(function _DedicatedJobSites({
  className,
  contact,
}) {
  const contactRoleOptions = api.endpoints.getContactRoleOptions.useQuery();

  return (
    <ContentSection className={className} title="Dedicated job sites">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Job site</Table.Th>
            <Table.Th>Influence</Table.Th>
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {contact.attributes.job_site_roles.map(
            ({ company_id, roles, id }) => {
              return (
                <Table.Tr key={company_id}>
                  <Table.Td>
                    <JobSiteChip id={id.toString()} />
                  </Table.Td>
                  <Table.Td>
                    {
                      contactRoleOptions.currentData?.influence.find(
                        ({ value }) => value === roles[0]?.name,
                      )?.label
                    }
                  </Table.Td>
                  <Table.Td>
                    {roles[0]?.departed_at != null ? "Past" : "Current"}
                  </Table.Td>
                </Table.Tr>
              );
            },
          )}
        </Table.Tbody>
      </Table>
    </ContentSection>
  );
});
