import { Outlet, type RouteObject } from "react-router-dom";
import { BidProfileView } from "src/components/BidProfileView/BidProfileView";
import { BidView } from "src/components/BidView/BidView";
import { ContactProfileView } from "src/components/ContactProfileView/ContactProfileView";
import { ContactView } from "src/components/ContactView/ContactView";
import { CustomerProfileView } from "src/components/CustomerProfileView/CustomerProfileView";
import { CustomerView } from "src/components/CustomerView/CustomerView";
import { DashboardView } from "src/components/DashboardView/DashboardView";
import { EstimationView } from "src/components/EstimationView/EstimationView";
import { JobSiteProfileView } from "src/components/JobSiteProfileView/JobSiteProfileView";
import { JobSiteView } from "src/components/JobSiteView/JobSiteView";
import { Layout } from "src/components/Layout";
import { Login } from "src/components/Login";
import { ProfileView } from "src/components/ProfileView/ProfileView";
import { ProtectedRoute } from "src/components/ProtectedRoute";
import { Matches } from "./matches";
import { Paths } from "./paths";

export const ROUTES: RouteObject[] = [
  {
    index: true,
    element: <Login />,
  },
  {
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        element: <Outlet />,
        path: Paths.BIDS,
        children: [
          {
            element: <BidView />,
            index: true,
          },
          {
            element: <Outlet />,
            path: Matches.BID_ID,
            children: [
              {
                element: <BidProfileView />,
                index: true,
              },
              {
                path: Paths.ESTIMATIONS,
                children: [
                  {
                    path: Matches.ESTIMATION_ID,
                    element: <EstimationView />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <Outlet />,
        path: Paths.CUSTOMERS,
        children: [
          {
            element: <CustomerView />,
            index: true,
          },
          {
            element: <CustomerProfileView />,
            path: Matches.CUSTOMER_ID,
          },
        ],
      },
      {
        element: <Outlet />,
        path: Paths.CONTACTS,
        children: [
          {
            element: <ContactView />,
            index: true,
          },
          {
            element: <ContactProfileView />,
            path: Matches.CONTACT_ID,
          },
        ],
      },
      {
        element: <Outlet />,
        path: Paths.JOB_SITES,
        children: [
          {
            element: <JobSiteView />,
            index: true,
          },
          {
            element: <JobSiteProfileView />,
            path: Matches.JOB_SITE_ID,
          },
        ],
      },
      {
        element: <DashboardView />,
        path: Paths.DASHBOARD,
      },
      {
        element: <ProfileView />,
        path: Paths.PROFILE,
      },
    ],
  },
];
