export const Matches = {
  BID_ID: ":bidId",
  CONTACT_ID: ":contactId",
  CUSTOMER_ID: ":customerId",
  ESTIMATION_ID: ":estimationId",
  JOB_SITE_ID: ":jobSiteId",
} as const;

export type MatchParam<T extends keyof typeof Matches> =
  (typeof Matches)[T] extends `:${infer U}` ? U : never;
