import { Stack, TextInput } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { useOnValueChange } from "src/hooks/useOnValueChange";
import { EMPTY_STRING } from "src/utils/empty";
import { CustomerSelectField } from "../Fields/CustomerSelectField";
import { TitleRoleSelectField } from "../Fields/TitleRoleSelectField";
import { REQUIRED_RULES } from "./constants";
import { NewContact } from "./types/contact";

interface Props {
  readonly control: Control<NewContact["data"]>;
  readonly shouldRenderRole?: boolean;
  readonly shouldRenderWorkplace?: boolean;
}

export const NewContactFormContent = React.memo<Props>(
  function _NewContactFormContent({
    control,
    shouldRenderRole = false,
    shouldRenderWorkplace = false,
  }) {
    const email = useController({
      control,
      name: "email",
      rules: {
        required: REQUIRED_RULES.required,
        validate: (value) =>
          /^\S+@\S+\.\S+$/.test(value) || "Invalid email format",
      },
    });
    const handleEmailChange = useOnValueChange(email.field.onChange);

    const firstName = useController({
      control,
      name: "firstName",
      rules: REQUIRED_RULES,
    });
    const handleFirstNameChange = useOnValueChange(firstName.field.onChange);

    const lastName = useController({
      control,
      name: "lastName",
      rules: REQUIRED_RULES,
    });
    const handleLastNameChange = useOnValueChange(lastName.field.onChange);

    const phoneNumber = useController({
      control,
      name: "phoneNumber",
      rules: {
        validate: (value) =>
          value == null || value === EMPTY_STRING || /^\d{10}$/.test(value)
            ? true
            : "Invalid phone number, please use 10 digits",
      },
    });
    const handlePhoneNumberChange = useOnValueChange(
      phoneNumber.field.onChange,
    );

    const customer = useController({
      control,
      name: "workplace",
      rules: REQUIRED_RULES,
      disabled: !shouldRenderWorkplace,
    });

    const role = useController({
      control,
      name: "role",
      disabled: !shouldRenderRole,
    });

    return (
      <Stack>
        <TextInput
          error={email.fieldState.error?.message}
          label="Email address"
          onChange={handleEmailChange}
          required={true}
          value={email.field.value}
        />
        <TextInput
          error={firstName.fieldState.error?.message}
          label="First name"
          onChange={handleFirstNameChange}
          required={true}
          value={firstName.field.value}
        />
        <TextInput
          error={lastName.fieldState.error?.message}
          label="Last name"
          onChange={handleLastNameChange}
          required={true}
          value={lastName.field.value}
        />
        <TextInput
          error={phoneNumber.fieldState.error?.message}
          label="Phone number"
          onChange={handlePhoneNumberChange}
          value={phoneNumber.field.value}
        />
        {shouldRenderWorkplace && (
          <CustomerSelectField
            error={customer.fieldState.error?.message}
            label="Where does this contact work?"
            onChange={customer.field.onChange}
            required={true}
            value={customer.field.value}
          />
        )}
        {shouldRenderRole && (
          <TitleRoleSelectField
            error={role.fieldState.error?.message}
            label="Role"
            onChange={role.field.onChange}
            value={role.field.value}
          />
        )}
      </Stack>
    );
  },
);
