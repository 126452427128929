import { Sort } from "src/data/api/types/shared/sort";
import { ValuesUnion } from "src/types/valuesUnion";

export const ContactColumnId = {
  COMPANY: "COMPANY",
  CREATED_AT: "CREATED_AT",
  CREATED_BY: "CREATED_BY",
  EMAIL: "EMAIL",
  NAME: "NAME",
  PHONE_NUMBER: "PHONE_NUMBER",
  UPDATED_AT: "UPDATED_AT",
  UPDATED_BY: "UPDATED_BY",
} as const;
export type ContactColumnId = ValuesUnion<typeof ContactColumnId>;

export function getContactFilterKeyFromColumnId(key: ContactColumnId) {
  switch (key) {
    case ContactColumnId.COMPANY:
      return "customers";
    case ContactColumnId.CREATED_BY:
      return "created_by";
    case ContactColumnId.UPDATED_BY:
      return "updated_by";
  }
}

export function getContactSortFromColumnId(
  key: ContactColumnId | undefined,
  sort: Sort | undefined,
) {
  if (key == null || sort == null) {
    return;
  }

  switch (key) {
    case ContactColumnId.COMPANY:
      return { customers: { name: sort } };
    case ContactColumnId.CREATED_AT:
      return { created_at: sort };
    case ContactColumnId.CREATED_BY:
      return { created_by: { first_name: sort } };
    case ContactColumnId.EMAIL:
      return { email: sort };
    case ContactColumnId.NAME:
      return { first_name: sort };
    case ContactColumnId.PHONE_NUMBER:
      return { phone: sort };
    case ContactColumnId.UPDATED_AT:
      return { updated_at: sort };
    case ContactColumnId.UPDATED_BY:
      return { updated_by: { first_name: sort } };
  }
}

export const CONTACT_LABEL_BY_ID = {
  [ContactColumnId.COMPANY]: "Current company",
  [ContactColumnId.CREATED_AT]: "Created at",
  [ContactColumnId.CREATED_BY]: "Created by",
  [ContactColumnId.EMAIL]: "Email",
  [ContactColumnId.NAME]: "Name",
  [ContactColumnId.PHONE_NUMBER]: "Phone number",
  [ContactColumnId.UPDATED_AT]: "Updated at",
  [ContactColumnId.UPDATED_BY]: "Updated by",
};
