import React from "react";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { formatCurrency } from "src/utils/formatCurrency";
import { isNonNullable } from "src/utils/isNonNullable";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
}

export const TotalSellAmountView = React.memo(({ bid }: Props) => {
  const [cellContent, pillCount] = React.useMemo(() => {
    const sortedEstimations = bid.attributes.estimations
      .map((estimation) => estimation.attributes.total)
      .filter(isNonNullable)
      .sort((a, b) => {
        return b - a;
      });
    return [formatCurrency(sortedEstimations[0]), sortedEstimations.length - 1];
  }, [bid]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={cellContent}
      pillCount={pillCount}
      resourceType={ResourceType.Estimation}
    />
  );
});
