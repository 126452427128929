import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { api } from "src/data/api/api";

interface Props {
  readonly userId: string | undefined;
}

export const UserCell = React.memo(function _UserCell({ userId }: Props) {
  const user = api.endpoints.getUser.useQuery(userId ?? skipToken);

  if (userId == null || user.data == null) {
    return undefined;
  }

  return `${user.data?.user.data.attributes.first_name} ${user.data?.user.data.attributes.last_name}`;
});
