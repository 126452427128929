import "./instrument";
import {
  ComboboxDropdown,
  createTheme,
  MantineProvider,
  Modal,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "src/data/store.ts";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { router } from "./utils/router";

// Allows time input in DateInput component
dayjs.extend(customParseFormat);

const theme = createTheme({
  colors: {
    teal: [
      "#ebfffe",
      "#d7fdfb",
      "#aafdf7",
      "#7cfdf4",
      "#60fcf1",
      "#53fdf0",
      "#4bfdef",
      "#3ee1d4",
      "#2dc8bc",
      "#00ada3",
    ],
  },
  components: {
    ComboboxDropdown: ComboboxDropdown.extend({
      defaultProps: {
        style: {
          maxHeight: 300,
          overflow: "scroll",
        },
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        closeOnClickOutside: false,
      },
    }),
  },
  defaultRadius: 8,
  primaryColor: "teal",
  primaryShade: 9,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <Notifications limit={2} position="top-center" />
        <RouterProvider router={router} />
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
);
