import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { NewCustomerDialog } from "./NewCustomerDialog";

export const NewCustomerButton = React.memo(function _NewCustomerButton() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button leftSection={<IconPlus size={14} />} onClick={open}>
        New customer
      </Button>

      {opened && <NewCustomerDialog onClose={close} />}
    </>
  );
});
