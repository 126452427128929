import { Button, Group } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React from "react";
import { Paths } from "src/constants/paths";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { router } from "../../utils/router";
import styles from "./NotificationsMessage.module.scss";

interface Props {
  readonly entityId: number;
  readonly entityType: Exclude<ResourceType, typeof ResourceType.Estimation>;
  readonly notificationId: string;
}

export const NotificationsMessage = React.memo<Props>(
  function _NotificationsMessage({ entityId, entityType, notificationId }) {
    const handleButtonClick = React.useCallback(() => {
      const entityPath = getEntityData(entityType).path;
      if (entityPath != null) {
        router.navigate(Paths.SLASH + entityPath + Paths.SLASH + entityId);
      }

      notifications.hide(notificationId);
    }, [entityId, entityType, notificationId]);

    return (
      <Group className={styles.root}>
        <span className={styles.mainText}>
          Success! New {getEntityData(entityType).display} has been created.
        </span>
        <Button
          className={styles.navText}
          onClick={handleButtonClick}
          variant="default"
        >
          Open {getEntityData(entityType).display}
        </Button>
      </Group>
    );
  },
);

interface EntityData {
  readonly path: string;
  readonly display: string;
}

function getEntityData(
  entityType: Exclude<ResourceType, typeof ResourceType.Estimation>,
): EntityData {
  switch (entityType) {
    case ResourceType.Bid:
      return {
        path: Paths.BIDS,
        display: "bid",
      };

    case ResourceType.Contact:
      return {
        path: Paths.CONTACTS,
        display: "contact",
      };

    case ResourceType.Customer:
      return {
        path: Paths.CUSTOMERS,
        display: "customer",
      };

    case ResourceType.JobSite:
      return {
        path: Paths.JOB_SITES,
        display: "job site",
      };
  }
}
