import { Button, Group, Modal, TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { api } from "src/data/api/api";
import { EMPTY_OBJECT, EMPTY_STRING } from "src/utils/empty";
import { FormFooter } from "../Frames/FormFooter";
import { BID_VIEW_TYPE, DEFAULT_BID_COLUMNS } from "./constants";

interface Props {
  readonly onClose: () => void;
}

export const NewViewDialog = React.memo<Props>(function _NewViewDialog({
  onClose,
}) {
  const [viewName, setViewName] = useInputState(EMPTY_STRING);

  const [createListView, { isLoading }] =
    api.endpoints.createListView.useMutation();

  const handleDialogClose = React.useCallback(() => {
    setViewName(EMPTY_STRING);
    onClose();
  }, [onClose, setViewName]);

  const handleViewSave = React.useCallback(async () => {
    await createListView({
      list_view: {
        name: viewName,
        filters: EMPTY_OBJECT,
        elements: DEFAULT_BID_COLUMNS,
        view: BID_VIEW_TYPE,
      },
    });

    onClose();
  }, [onClose, createListView, viewName]);

  return (
    <Modal onClose={handleDialogClose} opened={true} title="Create new view">
      <TextInput label="Tab name" onChange={setViewName} value={viewName} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={handleDialogClose} variant="outline">
              Cancel
            </Button>
            <Button
              disabled={viewName.trim().length === 0}
              loading={isLoading}
              onClick={handleViewSave}
            >
              Save
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
