import { ById } from "src/types/byId";
import { getLabelValueFromObject, LabelValue } from "src/types/util/labelValue";

interface OriginalReturns {
  readonly collection: ById<ById<string>>;
}

export interface GetBidOptionsReturns {
  readonly bidStage: ReadonlyArray<LabelValue>;
  readonly constructionType: ReadonlyArray<LabelValue>;
  readonly difficulty: ReadonlyArray<LabelValue>;
  readonly jobTypes: ReadonlyArray<LabelValue>;
  readonly priority: ReadonlyArray<LabelValue>;
  readonly relationship: ReadonlyArray<LabelValue>;
  readonly status: ReadonlyArray<LabelValue>;
}

export function getTransformedBidOptions({
  collection,
}: OriginalReturns): GetBidOptionsReturns {
  return {
    bidStage: getLabelValueFromObject(collection.bid_stage),
    constructionType: getLabelValueFromObject(collection.construction_type),
    difficulty: getLabelValueFromObject(collection.difficulty),
    jobTypes: getLabelValueFromObject(collection.job_types),
    priority: getLabelValueFromObject(collection.priority),
    relationship: getLabelValueFromObject(collection.relationship),
    status: getLabelValueFromObject(collection.status),
  };
}
