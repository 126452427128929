import { Stack } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { ContactSelectField } from "../Fields/ContactSelectField";
import { InfluenceRoleSelectField } from "../Fields/InfluenceRoleSelectField";
import { RoleStatusField } from "../Fields/RoleStatusField";
import { REQUIRED_RULES } from "./constants";
import { SiteContact } from "./types/jobSite";

interface Props {
  readonly control: Control<SiteContact>;
  readonly shouldAllowContactEdit?: boolean;
}

export const SiteContactFormContent = React.memo<Props>(
  function _SiteContactFormContent({
    control,
    shouldAllowContactEdit = false,
  }) {
    const siteContact = useController({
      control,
      name: "contact",
      rules: REQUIRED_RULES,
    });

    const role = useController({
      control,
      name: "influence",
    });

    const departedAt = useController({
      control,
      name: "departedAt",
    });

    return (
      <Stack>
        <ContactSelectField
          disabled={!shouldAllowContactEdit}
          error={siteContact.fieldState.error?.message}
          label="Choose contact"
          onChange={siteContact.field.onChange}
          required={true}
          value={siteContact.field.value}
        />
        <InfluenceRoleSelectField
          error={role.fieldState.error?.message}
          label="Influence"
          onChange={role.field.onChange}
          value={role.field.value}
        />
        <RoleStatusField
          label="Is this a current contact at this job site?"
          onChange={departedAt.field.onChange}
          value={departedAt.field.value}
        />
      </Stack>
    );
  },
);
