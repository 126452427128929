import { Anchor, Group, Stack, Tabs } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "src/components/Frames/PageHeader";
import { ShareButton } from "src/components/Frames/ShareButton";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { BidTable } from "../BidView/Table/BidTable";
import { DeleteButton } from "../Frames/DeleteButton";
import { LoadingScreen } from "../Frames/LoadingScreen";
import styles from "./ContactProfileView.module.scss";
import { Overview } from "./Overview/Overview";

const TABS = ["Overview", "Bids"] as const;

export const ContactProfileView = React.memo(function _ContactProfileView() {
  const navigate = useNavigate();
  const { contactId } = useParams<MatchParam<"CONTACT_ID">>();
  const [deleteContact] = api.endpoints.deleteContact.useMutation();

  const contact = api.endpoints.getContact.useQuery(contactId ?? skipToken);

  const handleContactClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.CONTACTS);
  }, [navigate]);

  const handleContactDelete = React.useCallback(async () => {
    if (contactId == null) {
      return;
    }

    await deleteContact(contactId);
    handleContactClick();
  }, [contactId, deleteContact, handleContactClick]);

  if (contact.currentData == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Profile} />;
  }

  const fullName = `${contact.currentData.contact.data.attributes.first_name} ${contact.currentData.contact.data.attributes.last_name}`;

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <ShareButton />
            <DeleteButton onDelete={handleContactDelete} />
          </Group>
        }
        subtitle={
          <span className={styles.subtitle}>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleContactClick}
            >
              Contacts
            </Anchor>
            <span className={styles.slash}>/</span>
            <span className={styles.path}>{fullName}</span>
          </span>
        }
        title={fullName}
      />

      <Group className={styles.group}>
        <Tabs className={styles.tabs} defaultValue={TABS[0]}>
          <Tabs.List p="0 20px">
            {TABS.map((tab) => (
              <Tabs.Tab key={tab} value={tab}>
                {tab}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel className={styles.panel} value={TABS[0]}>
            <Overview contact={contact.currentData.contact.data} />
          </Tabs.Panel>

          <Tabs.Panel className={styles.panel} value={TABS[1]}>
            <BidTable
              contactIdFilter={contact.currentData.contact.data.attributes.uuid}
            />
          </Tabs.Panel>
        </Tabs>
      </Group>
    </Stack>
  );
});
