import { Group, Stack } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { PageHeader } from "src/components/Frames/PageHeader";
import { CONTACTS_CONFIG } from "src/constants/navBarConfigs";
import { EMPTY_STRING } from "src/utils/empty";
import { SearchInput } from "../Frames/SearchInput";
import styles from "./ContactView.module.scss";
import { NewContactButton } from "./Header/NewContactButton";
import { ContactTable } from "./Table/ContactTable";

export const ContactView = React.memo(function _ContactView() {
  const [query, setQuery] = useInputState(EMPTY_STRING);

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <SearchInput onQueryChange={setQuery} query={query} />
            <NewContactButton />
          </Group>
        }
        title={
          <Group gap={4}>
            {CONTACTS_CONFIG.icon}
            {CONTACTS_CONFIG.name}
          </Group>
        }
      />

      <ContactTable query={query} />
    </Stack>
  );
});
