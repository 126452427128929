import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { selectAuthToken } from "src/data/selectors/auth";
import { authSlice } from "src/data/slices/auth";
import { useAppDispatch, useAppSelector } from "src/data/store";

interface TokenPayload {
  readonly exp: number;
  readonly iat: number;
  readonly sub: string;
}

export const ProtectedRoute: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthToken);

  const handleLogout = React.useCallback(() => {
    dispatch(authSlice.actions.logout());
    navigate(Paths.SLASH);
  }, [dispatch, navigate]);

  React.useEffect(() => {
    if (authToken == null) {
      handleLogout();
      return;
    }

    try {
      const target = authToken.split(".")[1];

      if (target == null) {
        handleLogout();
        return;
      }

      const result = atob(target);
      const parsed: TokenPayload = JSON.parse(result);

      // Backend returns a lower precision timestamp
      if (parsed.exp * 1_000 < Date.now()) {
        handleLogout();
      }
    } catch (error) {
      console.error("Failed to parse auth token.");
      handleLogout();
    }
  }, [authToken, handleLogout]);

  return children;
};
