import { notifications } from "@mantine/notifications";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "src/data/store";
import { EMPTY_STRING } from "src/utils/empty";
import { showEntityNotification } from "src/utils/notifications/showEntityNotification";
import { Method } from "../../constants/method";
import { resourceCacheSlice } from "../slices/resourceCache";
import { AddNoteArgs } from "./types/addNote";
import {
  ActionType,
  BulkTransactionArgs,
  BulkTransactionReturns,
  EntityResource,
  ResourceType,
} from "./types/bulkTransaction";
import { CreateListViewArgs } from "./types/createListView";
import { CreatePackageArgs } from "./types/createPackage";
import { GetBidReturns } from "./types/getBid";
import {
  GetBidOptionsReturns,
  getTransformedBidOptions,
} from "./types/getBidOptions";
import { GetBidsArgs, GetBidsReturns } from "./types/getBids";
import { GetCompanyReturns } from "./types/getCompany";
import { GetContactReturns } from "./types/getContact";
import {
  GetContactRoleOptionsReturns,
  getTransformedContactRoleOptions,
} from "./types/getContactRoleOptions";
import { GetContactsArgs, GetContactsReturns } from "./types/getContacts";
import { GetCustomerReturns } from "./types/getCustomer";
import {
  GetCustomerOptionsReturns,
  getTransformedCustomerOptions,
} from "./types/getCustomerOptions";
import { GetCustomersArgs, GetCustomersReturns } from "./types/getCustomers";
import { GetDashboardArgs, GetDashboardReturns } from "./types/getDashboard";
import { GetJobSiteReturns } from "./types/getJobSite";
import {
  getTransformedJobSiteOptions,
  TransformedGetJobSiteOptionsReturns,
} from "./types/getJobSiteOptions";
import { GetJobSitesArgs, GetJobSitesReturns } from "./types/getJobSites";
import { GetListViewsReturns } from "./types/getListViews";
import { GetNormalizedAddressesReturns } from "./types/getNormalizedAddresses";
import { GetPackagesArgs, GetPackagesReturns } from "./types/getPackagesArgs";
import { GetUserReturns } from "./types/getUser";
import { GetUsersArgs, GetUsersReturns } from "./types/getUsers";
import { LoginArgs } from "./types/login";
import { SignUpArgs } from "./types/signUp";
import { UpdateListViewArgs } from "./types/updateListView";
import { UpdateNoteArgs } from "./types/updateNote";
import { UpdateUserArgs } from "./types/updateUser";
import { getAuthToken, removeAuthToken, setAuthToken } from "./utils";

const PROD_URL = import.meta.env.VITE_SERVER_URL;
const BASE_URL = import.meta.env.DEV ? EMPTY_STRING : PROD_URL; // Dev proxy is configured in vite.config.ts

const TAG = {
  BID: "Bid",
  CONTACT: "Contact",
  CUSTOMER: "Customer",
  JOB_SITE: "JobSite",
  USER: "User",
  LIST_VIEW: "ListView",
} as const;

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
    responseHandler: (response) => {
      if (response.status === 401) {
        notifications.show({
          title: "Error",
          message: "Your session has expired. Please sign in again.",
        });
      }

      return response.json();
    },
  }),
  tagTypes: Object.values(TAG),

  endpoints: (builder) => ({
    /*
      Addresses
    */
    getNormalizedAddresses: builder.query<
      GetNormalizedAddressesReturns,
      string
    >({
      query: (address) => ({
        url: `/api/addresses/normalize?full_address=${encodeURIComponent(address)}`,
        method: Method.GET,
      }),
    }),

    /*
      Bids
    */
    getBid: builder.query<GetBidReturns, string>({
      query: (bidId) => ({
        url: `/api/companies/1/bids/${bidId}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.BID }],
    }),

    getBids: builder.query<GetBidsReturns, GetBidsArgs | undefined>({
      query: (body) => ({
        url: `/api/companies/1/bids/filter`,
        method: Method.POST,
        body,
      }),
      providesTags: [{ type: TAG.BID }],
    }),

    getBidOptions: builder.query<GetBidOptionsReturns, void>({
      query: () => ({
        url: "/api/companies/1/bids/options",
        method: Method.GET,
      }),
      transformResponse: getTransformedBidOptions,
    }),

    deleteBid: builder.mutation<void, string>({
      query: (bidId) => ({
        url: `/api/companies/1/bids/${bidId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Bulk Transactions
    */
    bulkTransaction: builder.mutation<
      BulkTransactionReturns,
      BulkTransactionArgs
    >({
      query: (bulk_transaction) => ({
        url: "/api/companies/1/bulk_transaction",
        method: Method.POST,
        body: bulk_transaction,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          const bidResource =
            data.bulk_transaction?.data?.attributes?.updated_resources?.find(
              (resource): resource is EntityResource =>
                resource.type === ResourceType.Bid &&
                resource.action === ActionType.Create,
            );
          const entityType = bidResource?.type;
          const entityId = bidResource?.id;

          if (entityType == null || entityId == null) {
            return;
          }

          showEntityNotification({
            entityId: entityId,
            entityType: entityType,
          });
        } catch (error) {
          // error notification
        }
      },
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Companies
    */
    getCompany: builder.query<GetCompanyReturns, void>({
      query: () => ({
        url: "/api/companies/1",
        method: Method.GET,
      }),
    }),

    /*
      Contacts
    */
    getContacts: builder.query<GetContactsReturns, GetContactsArgs | undefined>(
      {
        query: (body) => ({
          url: `/api/companies/1/contacts/filter`,
          method: Method.POST,
          body,
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          const { data } = await queryFulfilled;
          dispatch(
            resourceCacheSlice.actions.addContacts(data.collection.data),
          );
        },
        providesTags: [{ type: TAG.CONTACT }],
      },
    ),
    /*
      Contact Roles
    */
    getContact: builder.query<GetContactReturns, string>({
      query: (customerId) => ({
        url: `/api/companies/1/contacts/${customerId}`,
        method: Method.GET,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addContacts([data.contact.data]));
      },
      providesTags: [{ type: TAG.CONTACT }],
    }),

    getContactRoleOptions: builder.query<GetContactRoleOptionsReturns, void>({
      query: () => ({
        url: "/api/contact_roles/options",
        method: Method.GET,
      }),
      transformResponse: getTransformedContactRoleOptions,
    }),

    deleteContact: builder.mutation<void, string>({
      query: (contactId) => ({
        url: `/api/companies/1/contacts/${contactId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Customers
    */
    getCustomer: builder.query<GetCustomerReturns, string>({
      query: (customerId) => ({
        url: `/api/companies/1/customers/${customerId}`,
        method: Method.GET,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addCustomers([data.customer.data]));
      },
      providesTags: [{ type: TAG.CUSTOMER }],
    }),

    getCustomers: builder.query<
      GetCustomersReturns,
      GetCustomersArgs | undefined
    >({
      query: (body) => ({
        url: `/api/companies/1/customers/filter`,
        method: Method.POST,
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addCustomers(data.collection.data));
      },
      providesTags: [{ type: TAG.CUSTOMER }],
    }),

    getCustomerOptions: builder.query<GetCustomerOptionsReturns, void>({
      query: () => ({
        url: "/api/companies/1/customers/options",
        method: Method.GET,
      }),
      transformResponse: getTransformedCustomerOptions,
    }),

    deleteCustomer: builder.mutation<void, string>({
      query: (customerId) => ({
        url: `/api/companies/1/customers/${customerId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Estimations
    */
    createPackage: builder.mutation<void, CreatePackageArgs>({
      query: (body) => ({
        url: `/api/packages`,
        method: Method.POST,
        body,
      }),
    }),

    getPackages: builder.query<GetPackagesReturns, GetPackagesArgs>({
      query: (body) => ({
        url: `/api/packages/filter`,
        method: Method.POST,
        body,
      }),
    }),

    /*
      Job Sites
    */
    getJobSite: builder.query<GetJobSiteReturns, string>({
      query: (jobSiteId) => ({
        url: `/api/companies/1/job_sites/${jobSiteId}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.JOB_SITE }],
    }),

    getJobSites: builder.query<GetJobSitesReturns, GetJobSitesArgs | undefined>(
      {
        query: (body) => ({
          url: `/api/companies/1/job_sites/filter`,
          method: Method.POST,
          body,
        }),
        providesTags: [{ type: TAG.JOB_SITE }],
      },
    ),

    getJobSiteOptions: builder.query<TransformedGetJobSiteOptionsReturns, void>(
      {
        query: () => ({
          url: "/api/companies/1/job_sites/options",
          method: Method.GET,
        }),
        transformResponse: getTransformedJobSiteOptions,
      },
    ),

    deleteJobSite: builder.mutation<void, string>({
      query: (jobSiteId) => ({
        url: `/api/companies/1/job_sites/${jobSiteId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /* 
      Notes
    */
    addNote: builder.mutation<string | undefined, AddNoteArgs>({
      query: ({ note }) => ({
        url: `/api/notes`,
        method: Method.POST,
        body: note,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    updateNote: builder.mutation<string | undefined, UpdateNoteArgs>({
      query: (note) => ({
        url: `/api/notes/${note.id}`,
        method: Method.PUT,
        body: note,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    deleteNote: builder.mutation<string | undefined, number>({
      query: (id) => ({
        url: `/api/notes/${id}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /* 
      Dashboard
    */
    getDashboardSales: builder.query<GetDashboardReturns, GetDashboardArgs>({
      query: (dates) => ({
        url: `/api/reports/sales?${new URLSearchParams({
          start_date: dates.start_date,
          end_date: dates.end_date,
        })}`,
        method: Method.GET,
      }),
    }),

    /*
      List Views
    */
    getListViews: builder.query<GetListViewsReturns, void>({
      query: () => ({
        url: "/api/list_views",
        method: Method.GET,
      }),
      providesTags: [TAG.LIST_VIEW],
    }),

    createListView: builder.mutation<void, CreateListViewArgs>({
      query: (args) => ({
        url: "/api/list_views",
        method: Method.POST,
        body: args,
      }),
      invalidatesTags: [TAG.LIST_VIEW],
    }),

    updateListView: builder.mutation<void, UpdateListViewArgs>({
      query: (args) => ({
        url: `/api/list_views/${args.listViewId}`,
        method: Method.PUT,
        body: args.body,
      }),
      invalidatesTags: [TAG.LIST_VIEW],
    }),

    deleteListView: builder.mutation<void, string>({
      query: (listViewId) => ({
        url: `/api/list_views/${listViewId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [TAG.LIST_VIEW],
    }),

    /*
      Sessions
    */
    login: builder.mutation<string | undefined, LoginArgs>({
      query: (args) => ({
        url: "/login",
        method: Method.POST,
        body: { user: args },
      }),
      transformResponse: getAuthToken,
      onQueryStarted: (_args, { queryFulfilled }) => {
        setAuthToken(queryFulfilled);
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/logout",
        method: Method.DELETE,
      }),
      onQueryStarted: () => {
        removeAuthToken();
      },
    }),

    signUp: builder.mutation<string | undefined, SignUpArgs>({
      query: (args) => ({
        url: "/signup",
        method: Method.POST,
        body: { user: { ...args, company_id: 1 } },
      }),
      transformResponse: getAuthToken,
      onQueryStarted: (_args, { queryFulfilled }) => {
        setAuthToken(queryFulfilled);
      },
    }),

    /*
      Users
    */
    getUsers: builder.query<GetUsersReturns, GetUsersArgs | undefined>({
      query: (body) => ({
        url: "/api/users/filter",
        method: Method.POST,
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addUsers(data.collection.data));
      },
      providesTags: [{ type: TAG.USER }],
    }),

    getUser: builder.query<GetUserReturns, string>({
      query: (userId: string) => ({
        url: `/api/users/${userId}`,
        method: Method.GET,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addUsers([data.user.data]));
      },
      providesTags: (_returns, _error, userId) => [
        { type: TAG.USER, id: userId },
      ],
    }),

    updateUser: builder.mutation<void, UpdateUserArgs>({
      query: (payload) => ({
        url: `/api/users/${payload.id}`,
        method: Method.PUT,
        body: {
          user: payload.user,
        },
      }),
      invalidatesTags: (_returns, _error, payload) => [
        { type: TAG.USER, id: payload.id },
      ],
    }),
  }),
});
