import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { BidReceiversFormContent } from "src/components/Forms/NewBid/BidReceiversFormContent";
import {
  DEFAULT_BID_RECEIVER_VALUE,
  NewBid,
} from "src/components/Forms/types/bid";
import { PersistenceType } from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { EMPTY_STRING } from "src/utils/empty";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormValue = NewBid["bidReceivers"];

interface Props {
  readonly bid: LoadedBid;
  readonly onClose: () => void;
}

export const AddCustomerDialog = React.memo<Props>(function _AddCustomerDialog({
  bid,
  onClose,
}) {
  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      bidReceivers: [DEFAULT_BID_RECEIVER_VALUE],
    },
  });
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleCustomerAdd = React.useCallback(
    async (submittedValue: FormValue) => {
      try {
        const [bidReceiver] = submittedValue.bidReceivers;

        if (bidReceiver == null) {
          throw new Error();
        }

        const { customer, contacts, dueDate, walkDate } = bidReceiver;
        const associations: Array<Association> = [];
        const bidId = bid.attributes.uuid;
        const jobSiteId = bid.attributes.job_site.uuid;
        const customerId =
          customer != null
            ? customer.type === PersistenceType.EXISTING
              ? customer.data.uuid
              : customer.uuid
            : EMPTY_STRING;

        for (const contact of contacts) {
          const contactId =
            contact.type === PersistenceType.EXISTING
              ? contact.data.uuid
              : contact.uuid;

          associations.push({
            type: AssociationType.ContactRole,
            contact_id: contactId,
            resource_type: ResourceType.Bid,
            resource_id: bidId,
            metadata: {
              customer_id: customerId,
            },
          });
        }

        associations.push({
          type: AssociationType.CustomerBid,
          customer_id: customerId,
          bid_id: bidId,
          metadata: {
            due_at: dueDate?.toISOString(),
            job_walk_at: walkDate?.toISOString(),
          },
        });
        associations.push({
          type: AssociationType.CustomerJobSite,
          customer_id: customerId,
          job_site_id: jobSiteId,
        });

        await trigger({
          bulk_transaction: {
            associations,
          },
        }).unwrap();
        onClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [bid.attributes.job_site.uuid, bid.attributes.uuid, onClose, trigger],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Add customer"
    >
      <BidReceiversFormContent
        control={control}
        shouldAllowCustomerEdit={true}
      />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleCustomerAdd)}
            >
              Add customer
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
