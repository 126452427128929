import React from "react";
import { NotesModule } from "src/components/Frames/NotesModule";
import { TwoColumnOverview } from "src/components/Frames/TwoColumnOverview";
import { api } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { ContactInfo } from "./ContactInfo/ContactInfo";
import { CurrentRole } from "./CurrentRole/CurrentRole";
import { DedicatedJobSites } from "./DedicatedJobSites";

interface Props {
  readonly contact: LoadedContact;
}

export const Overview = React.memo<Props>(function _Overview({ contact }) {
  const [addNote, { isLoading: isAddNoteLoading }] =
    api.endpoints.addNote.useMutation();
  const [updateNote, { isLoading: isUpdateNoteLoading }] =
    api.endpoints.updateNote.useMutation();
  const [deleteNote] = api.endpoints.deleteNote.useMutation();

  const handleNoteAdd = React.useCallback(
    async (content: string) => {
      if (contact.attributes.id != null) {
        await addNote({
          note: {
            content: content,
            resource_id: contact.attributes.id.toString(),
            resource_type: ResourceType.Contact,
          },
        });
      }
    },
    [addNote, contact.attributes.id],
  );

  const handleNoteDelete = React.useCallback(
    async (id: number) => {
      await deleteNote(id);
    },
    [deleteNote],
  );

  const handleNoteUpdate = React.useCallback(
    async (id: number, content: string) => {
      await updateNote({
        id: id,
        note: {
          content: content,
        },
      });
    },
    [updateNote],
  );
  return (
    <TwoColumnOverview
      leftSection={
        <React.Fragment>
          <ContactInfo contact={contact} />
          <CurrentRole contact={contact} />
          <DedicatedJobSites contact={contact} />
        </React.Fragment>
      }
      rightSection={
        <React.Fragment>
          <NotesModule
            isAddNoteLoading={isAddNoteLoading}
            isUpdateNoteLoading={isUpdateNoteLoading}
            notes={contact.attributes.notes}
            onNoteAdd={handleNoteAdd}
            onNoteDelete={handleNoteDelete}
            onNoteUpdate={handleNoteUpdate}
          />
        </React.Fragment>
      }
    />
  );
});
