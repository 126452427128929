import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { SiteContactFormContent } from "src/components/Forms/SiteContactFormContent";
import { SiteContact } from "src/components/Forms/types/jobSite";
import { createExisting } from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { Unwrap } from "src/types/unwrap";

type FormValue = SiteContact;

interface Props {
  readonly contact: Unwrap<LoadedJobSite["attributes"]["contacts"]>;
  readonly jobSite: LoadedJobSite;
  readonly onClose: () => void;
}

export const EditSiteContactDialog = React.memo<Props>(
  function _EditSiteContactDialog({ contact, jobSite, onClose }) {
    const roleToEdit = React.useMemo(
      () =>
        contact.roles.find(
          (role) =>
            role.resource_type === ResourceType.JobSite &&
            role.resource_id === jobSite.attributes.id,
        ),
      [contact.roles, jobSite.attributes.id],
    );

    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        contact: createExisting({ uuid: contact.uuid }),
        departedAt: roleToEdit?.departed_at,
        influence: roleToEdit?.name,
      },
    });

    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleSiteContactEdit = React.useCallback(
      async (submittedValue: FormValue) => {
        try {
          await trigger({
            bulk_transaction: {
              associations: [
                {
                  type: AssociationType.ContactRole,
                  id: roleToEdit?.id,
                  contact_id: contact.uuid,
                  resource_type: ResourceType.JobSite,
                  resource_id: jobSite.attributes.uuid,
                  metadata: {
                    departed_at: submittedValue.departedAt,
                    name: submittedValue.influence,
                  },
                },
              ],
            },
          });

          onClose();
        } catch (error) {
          // error notification
        }
      },
      [contact.uuid, jobSite.attributes.uuid, onClose, roleToEdit?.id, trigger],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Edit site contact"
      >
        <SiteContactFormContent control={control} />

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleSiteContactEdit)}
              >
                Edit site contact
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
