import { Group, Table } from "@mantine/core";
import React from "react";
import styles from "src/components/BidProfileView/Overview/Customers/CustomersTable.module.scss";
import { ContactChip } from "src/components/Frames/EntityChip/ContactChip";
import { CustomerChip } from "src/components/Frames/EntityChip/CustomerChip";
import { RowHoverOptions } from "src/components/Frames/RowHoverOptions";
import rowHoverOptionsStyles from "src/components/Frames/RowHoverOptions.module.scss";
import { api } from "src/data/api/api";
import { AssociationType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { formatDateTime } from "src/utils/formatDateTime";
import { EditCustomerDialog } from "./EditCustomerDialog";

interface Props {
  readonly bid: LoadedBid;
  readonly shouldHideActions?: boolean;
}

export const CustomersTable = React.memo<Props>(function _CustomersTable({
  bid,
  shouldHideActions = false,
}) {
  const [trigger] = api.endpoints.bulkTransaction.useMutation();
  const [editId, setEditId] = React.useState<string>();

  const handleDialogClose = React.useCallback(() => {
    setEditId(undefined);
  }, []);

  const handleCustomerDelete = React.useCallback(
    (id: string) => {
      const customer = bid.attributes.customers.find(
        (entry) => entry.uuid === id,
      );

      const contacts = bid.attributes.contacts.filter(
        (entry) => entry.customer_id === customer?.uuid,
      );

      if (customer?.association_id == null) {
        throw new Error();
      }

      trigger({
        bulk_transaction: {
          deletions: [
            {
              resource_type: AssociationType.CustomerBid,
              resource_id: customer.association_id,
            },
            ...contacts.map((contact) => ({
              resource_type: AssociationType.ContactRole,
              resource_id: contact.association_id,
            })),
          ],
        },
      });
    },
    [bid.attributes.contacts, bid.attributes.customers, trigger],
  );

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Customer</Table.Th>
          <Table.Th>Contacts</Table.Th>
          <Table.Th className={styles.noWrap}>Due date</Table.Th>
          <Table.Th className={styles.noWrap}>Job walk</Table.Th>
          {!shouldHideActions && <Table.Th />}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {bid.attributes.customers.map((customer) => {
          return (
            <Table.Tr key={customer.uuid} className={rowHoverOptionsStyles.row}>
              <Table.Td>
                <CustomerChip id={customer.id.toString()} />
              </Table.Td>
              <Table.Td>
                <Group gap={5} wrap="wrap">
                  {bid.attributes.contacts
                    .filter((contact) => contact.customer_id === customer.uuid)
                    .map((contact) => (
                      <ContactChip
                        key={contact.id}
                        id={contact.id.toString()}
                      />
                    ))}
                </Group>
              </Table.Td>
              <Table.Td className={styles.noWrap}>
                {formatDateTime(customer.due_at)}
              </Table.Td>
              <Table.Td className={styles.noWrap}>
                {formatDateTime(customer.job_walk_at)}
              </Table.Td>
              {!shouldHideActions && (
                <Table.Td>
                  <RowHoverOptions
                    id={customer.uuid}
                    onDelete={handleCustomerDelete}
                    onEdit={setEditId}
                  />
                </Table.Td>
              )}
            </Table.Tr>
          );
        })}
      </Table.Tbody>

      {editId != null && (
        <EditCustomerDialog
          bid={bid}
          customerId={editId}
          onClose={handleDialogClose}
        />
      )}
    </Table>
  );
});
