import { Group } from "@mantine/core";
import {
  ColumnMovedEvent,
  GridOptions,
  RowSelectedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { FilterHeader } from "src/components/Table/Filter/FilterHeader";
import { PaginationFooter } from "src/components/Table/PaginationFooter";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { GetBidsArgs } from "src/data/api/types/getBids";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { ListViewWithId } from "src/data/api/types/shared/listView";
import { ResourceCacheType } from "src/data/types/resourceCacheType";
import { useTableSort } from "src/hooks/table/useTableSort";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { Mutable } from "src/types/mutable";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_ARRAY, EMPTY_SET, EMPTY_STRING } from "src/utils/empty";
import { isNonEmptyString } from "src/utils/isNonEmptyString";
import {
  ALL_BIDS_VIEW,
  BidColumnId,
  DEFAULT_BID_COLUMNS,
  FilterByColumn,
  MY_BIDS_VIEW,
} from "../constants";
import styles from "./BidTable.module.scss";
import { EditColumnsButton } from "./EditColumns/EditColumnsButton";
import { useBidColumnDefById } from "./hooks/useBidColumnDefById";
import { SaveViewButton } from "./SaveViewButton";
import {
  BID_LABEL_BY_ID,
  getBidFilterKeyFromColumnId,
  getBidSortFromColumnId,
  getFilterEntriesFromFilterSetById,
  getFilterSetByIdFromFiltersById,
  transformBidBasedOnFilters,
} from "./utils";

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
};

interface Props {
  readonly contactIdFilter?: string;
  readonly currentView?: ListViewWithId;
  readonly customerIdFilter?: string;
  readonly jobSiteIdFilter?: string;
  readonly query?: string;
}

export const BidTable = React.memo<Props>(function _BidTable({
  contactIdFilter,
  currentView,
  customerIdFilter,
  jobSiteIdFilter,
  query = EMPTY_STRING,
}) {
  const navigate = useNavigate();

  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const { onSortChanged, sortState } = useTableSort<string>(
    currentView?.filters.sort,
  );
  const [filterSetById, setFilterSetById] = React.useState<FilterByColumn>(
    () => {
      const defaultFilters = {
        [BidColumnId.STATUS]: EMPTY_SET,
        [BidColumnId.PRIORITY]: EMPTY_SET,
        [BidColumnId.SALES_LEAD]: EMPTY_SET,
      };

      const storedFilterSetById = getFilterSetByIdFromFiltersById(
        currentView?.filters.filtersById,
      );

      if (
        storedFilterSetById == null ||
        Object.values(storedFilterSetById).length === 0
      ) {
        return defaultFilters;
      }

      return {
        ...defaultFilters,
        ...storedFilterSetById,
      };
    },
  );

  const bidColumnDefById = useBidColumnDefById();
  const [columnOrder, setColumnOrder] = React.useState(
    () => currentView?.elements ?? DEFAULT_BID_COLUMNS,
  );

  const columnDefs = React.useMemo(() => {
    return columnOrder
      .map((columnId) =>
        currentView?.filters.sort?.columnId === columnId
          ? {
              ...bidColumnDefById[columnId],
              initialSort: currentView.filters.sort.sort,
            }
          : bidColumnDefById[columnId],
      )
      .filter((entry) => entry != null);
  }, [
    bidColumnDefById,
    columnOrder,
    currentView?.filters.sort?.columnId,
    currentView?.filters.sort?.sort,
  ]);

  const filterQuery = React.useMemo((): GetBidsArgs | undefined => {
    const and: Mutable<NonNullable<GetBidsArgs["search"]>["and"]> = [];

    if (contactIdFilter != null) {
      const filterKey = getBidFilterKeyFromColumnId(BidColumnId.CONTACTS);

      if (filterKey != null) {
        and.push({ [filterKey]: contactIdFilter });
      }
    }

    if (customerIdFilter != null) {
      const filterKey = getBidFilterKeyFromColumnId(BidColumnId.CUSTOMERS);

      if (filterKey != null) {
        and.push({ [filterKey]: customerIdFilter });
      }
    }

    if (jobSiteIdFilter != null) {
      const filterKey = getBidFilterKeyFromColumnId(
        BidColumnId.JOB_SITE_ADDRESS,
      );

      if (filterKey != null) {
        and.push({ [filterKey]: jobSiteIdFilter });
      }
    }

    and.push(...getFilterEntriesFromFilterSetById(filterSetById));

    if (isNonEmptyString(query)) {
      and.push({ query });
    }

    const search = and.length > 0 ? { and } : undefined;

    return {
      search,
      sort: getBidSortFromColumnId(
        sortState?.columnId as BidColumnId,
        sortState?.sort,
      ),
      page: currentPageIndex,
    };
  }, [
    contactIdFilter,
    currentPageIndex,
    customerIdFilter,
    filterSetById,
    jobSiteIdFilter,
    query,
    sortState?.columnId,
    sortState?.sort,
  ]);

  const bids = api.endpoints.getBids.useQuery(filterQuery);
  const bidOptions = api.endpoints.getBidOptions.useQuery();
  const company = api.endpoints.getCompany.useQuery();

  const agGridRef = React.useRef<AgGridReact<LoadedBid>>(null);

  const optionsOrResourceType = React.useMemo(() => {
    return {
      [BidColumnId.STATUS]: bidOptions.currentData?.status,
      [BidColumnId.PRIORITY]: bidOptions.currentData?.priority,
      [BidColumnId.BID_STAGE]: bidOptions.currentData?.bidStage,
      [BidColumnId.CONSTRUCTION_TYPE]: bidOptions.currentData?.constructionType,
      [BidColumnId.BRANCH_ID]:
        company.currentData?.company.data.attributes.branches.map((entry) => {
          return {
            label: entry.name,
            value: entry.id.toString(),
          };
        }) ?? EMPTY_ARRAY,
      [BidColumnId.JOB_TYPES]: bidOptions.currentData?.jobTypes ?? EMPTY_ARRAY,
      [BidColumnId.CONTACTS]: ResourceCacheType.Contact,
      [BidColumnId.CUSTOMERS]: ResourceCacheType.Customer,
      [BidColumnId.SALES_LEAD]: ResourceCacheType.User,
      [BidColumnId.CREATED_BY]: ResourceCacheType.User,
      [BidColumnId.UPDATED_BY]: ResourceCacheType.User,
    };
  }, [
    bidOptions.currentData,
    company.currentData?.company.data.attributes.branches,
  ]);

  const filteredBids = React.useMemo(() => {
    if (bids.data == null) {
      return EMPTY_ARRAY;
    }

    return bids.data.collection.data.map((bid) =>
      transformBidBasedOnFilters(bid, filterSetById),
    );
  }, [bids.data, filterSetById]);

  const handleRowSelect = React.useCallback(
    (event: RowSelectedEvent<LoadedBid>) => {
      if (event.data?.id == null) {
        return;
      }

      navigate(Paths.SLASH + Paths.BIDS + Paths.SLASH + event.data.id);
    },
    [navigate],
  );

  const handleColumnMove = React.useCallback((event: ColumnMovedEvent) => {
    if (!event.finished) {
      return;
    }

    setColumnOrder(event.api.getColumnState().map(({ colId }) => colId));
  }, []);

  if (bids.data?.collection.data == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Table} />;
  }

  return (
    <div className={classNames("ag-theme-quartz", styles.tableWrapper)}>
      <Group justify="space-between">
        <FilterHeader
          filterSetById={filterSetById}
          labelById={BID_LABEL_BY_ID}
          onFilterSetByIdChange={setFilterSetById}
          optionsOrResourceTypeById={optionsOrResourceType}
        />

        {currentView != null && (
          <Group pr={20}>
            <SaveViewButton
              columnOrder={columnOrder}
              currentViewId={currentView.id}
              filterSetById={filterSetById}
              sortState={sortState}
            />
            <EditColumnsButton
              columnOrder={columnOrder}
              isDisabled={
                currentView.id === MY_BIDS_VIEW ||
                currentView.id === ALL_BIDS_VIEW
              }
              onColumnOrderChange={setColumnOrder}
            />
          </Group>
        )}
      </Group>

      <AgGridReact<LoadedBid>
        ref={agGridRef}
        columnDefs={asMutableArray(columnDefs)}
        gridOptions={GRID_OPTIONS}
        onColumnMoved={handleColumnMove}
        onRowSelected={handleRowSelect}
        onSortChanged={onSortChanged}
        rowData={asMutableArray(filteredBids)}
      />

      {bids.data != null && (
        <PaginationFooter
          currentPageIndex={currentPageIndex}
          onPageChange={setCurrentPageIndex}
          totalPageCount={bids.data.total_pages}
        />
      )}
    </div>
  );
});
