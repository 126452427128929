import { Button, PasswordInput, Stack } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React from "react";
import { api } from "src/data/api/api";
import { selectCurrentUserId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { EMPTY_STRING } from "src/utils/empty";
import styles from "./ProfileView.module.scss";

export const ProfileView = React.memo(function _ProfileView() {
  const [updateUser] = api.endpoints.updateUser.useMutation();
  const currentUserId = useAppSelector(selectCurrentUserId);

  const [password, setPassword] = useInputState(EMPTY_STRING);
  const [passwordConfirmation, setPasswordConfirmation] =
    useInputState(EMPTY_STRING);

  const handleSubmit = React.useCallback(async () => {
    if (currentUserId == null) {
      throw new Error();
    }

    if (password !== passwordConfirmation) {
      notifications.show({
        title: "Error",
        message: "Password does not match",
      });
      return;
    }

    if (password.length < 8) {
      notifications.show({
        title: "Error",
        message: "Password needs to be longer than 8 characters",
      });
      return;
    }

    try {
      await updateUser({
        id: currentUserId,
        user: {
          password,
          password_confirmation: passwordConfirmation,
        },
      });

      notifications.show({
        title: "Success",
        message: "Successfully updated password",
      });
    } catch (error) {
      notifications.show({
        title: "Error",
        message: "Failed to update password",
      });
    }

    setPassword(EMPTY_STRING);
    setPasswordConfirmation(EMPTY_STRING);
  }, [
    passwordConfirmation,
    password,
    setPassword,
    setPasswordConfirmation,
    updateUser,
    currentUserId,
  ]);

  return (
    <div className={styles.root}>
      <Stack w={600}>
        <PasswordInput
          label="Password"
          onChange={setPassword}
          required={true}
          value={password}
        />
        <PasswordInput
          label="Password Confirmation"
          onChange={setPasswordConfirmation}
          required={true}
          value={passwordConfirmation}
        />

        <Button onClick={handleSubmit}>Update password</Button>
      </Stack>
    </div>
  );
});
