import { IconPencil, IconTrash } from "@tabler/icons-react";
import React from "react";
import styles from "./NoteHoverOptions.module.scss";

interface Props {
  readonly onDelete: () => void;
  readonly onEdit: () => void;
}

export const NoteHoverOptions = React.memo(function _NoteHoverOptions({
  onDelete,
  onEdit,
}: Props) {
  return (
    <div className={styles.hover}>
      <IconPencil className={styles.icon} onClick={onEdit} size={18} />
      <IconTrash className={styles.icon} onClick={onDelete} size={18} />
    </div>
  );
});
