import { Anchor, Group, Stack } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "src/components/Frames/PageHeader";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { LoadingScreen } from "../Frames/LoadingScreen";
import styles from "./EstimationView.module.scss";
import { EstimationProposal } from "./Proposal/EstimationProposal";
import { EstimationTable } from "./Table/EstimationTable";

export const EstimationView = React.memo(function _EstimationView() {
  const navigate = useNavigate();

  const { bidId } = useParams<MatchParam<"BID_ID">>();
  const bid = api.endpoints.getBid.useQuery(bidId ?? skipToken);
  const bidName = bid.currentData?.bid.data.attributes.name;

  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();

  const handleBidsClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.BIDS);
  }, [navigate]);

  const handleBidClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.BIDS + Paths.SLASH + bidId);
  }, [bidId, navigate]);

  if (bidId == null || estimationId == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Table} />;
  }

  return (
    <Stack className={styles.stack}>
      <PageHeader
        subtitle={
          <span className={styles.subtitle}>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleBidsClick}
            >
              Bids
            </Anchor>
            <span className={styles.slash}>/</span>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleBidClick}
            >
              {bidName}
            </Anchor>
            <span className={styles.slash}>/</span>
            <span className={styles.path}>Estimation</span>
          </span>
        }
        title={`Estimation for ${bidName}`}
      />

      <Group className={styles.group} gap={0} w="100%">
        <EstimationProposal className={styles.proposal} />
        <EstimationTable
          className={styles.table}
          estimationId={estimationId?.toString()}
        />
      </Group>
    </Stack>
  );
});
