import { Stack, TextInput } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { useOnValueChange } from "src/hooks/useOnValueChange";
import { IndustrySelectField } from "../Fields/IndustrySelectField";
import { UserField } from "../Fields/UserField";
import { REQUIRED_RULES } from "./constants";
import { NewCustomer } from "./types/customer";

interface Props {
  readonly control: Control<NewCustomer["data"]>;
}

export const NewCustomerFormContent = React.memo<Props>(
  function _NewCustomerFormContent({ control }) {
    const customerName = useController({
      control,
      name: "name",
      rules: REQUIRED_RULES,
    });
    const handleCustomerNameChange = useOnValueChange(
      customerName.field.onChange,
    );

    const industry = useController({
      control,
      name: "industry",
      rules: REQUIRED_RULES,
    });

    const accountManager = useController({
      control,
      name: "accountManager",
    });

    const vistaCustomerId = useController({
      control,
      name: "vistaCustomerId",
    });
    const handleVistaCustomerIdChange = useOnValueChange(
      vistaCustomerId.field.onChange,
    );

    return (
      <Stack>
        <TextInput
          error={customerName.fieldState.error?.message}
          label="Customer name"
          onChange={handleCustomerNameChange}
          required={true}
          value={customerName.field.value}
        />
        <IndustrySelectField
          error={industry.fieldState.error?.message}
          label="Industry"
          onChange={industry.field.onChange}
          required={true}
          value={industry.field.value}
        />
        <UserField
          error={accountManager.fieldState.error?.message}
          label="Account manager"
          onChange={accountManager.field.onChange}
          value={accountManager.field.value}
        />
        <TextInput
          error={vistaCustomerId.fieldState.error?.message}
          label="Vista customer ID"
          onChange={handleVistaCustomerIdChange}
          value={vistaCustomerId.field.value}
        />
      </Stack>
    );
  },
);
