import {
  Badge,
  Button,
  Checkbox,
  Popover,
  Stack,
  TextInput,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import React from "react";
import { ResourceCacheType } from "src/data/types/resourceCacheType";
import { useContactOptions } from "src/hooks/resourceCache/useContactOptions";
import { useCustomerOptions } from "src/hooks/resourceCache/useCustomerOptions";
import { useUserOptions } from "src/hooks/resourceCache/useUserOptions";
import { ById } from "src/types/byId";
import { LabelValue } from "src/types/util/labelValue";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import styles from "./FilterMenu.module.scss";

interface Props {
  readonly filterSet?: ReadonlySet<string>;
  readonly keyName: string;
  readonly label?: string;
  readonly onFilterSetByIdChange: React.Dispatch<
    React.SetStateAction<ById<ReadonlySet<string>>>
  >;
  readonly optionsOrResourceType?:
    | ReadonlyArray<LabelValue>
    | ResourceCacheType;
}

export const FilterMenu = React.memo<Props>(function _FilterMenu({
  filterSet,
  keyName,
  label,
  onFilterSetByIdChange,
  optionsOrResourceType,
}) {
  const [search, setSearch] = useInputState(EMPTY_STRING);

  const contactOptions = useContactOptions(
    optionsOrResourceType === ResourceCacheType.Contact ? search : undefined,
  );
  const customerOptions = useCustomerOptions(
    optionsOrResourceType === ResourceCacheType.Customer ? search : undefined,
  );
  const userOptions = useUserOptions(
    optionsOrResourceType === ResourceCacheType.User ? search : undefined,
  );

  const options = React.useMemo((): ReadonlyArray<LabelValue> => {
    switch (optionsOrResourceType) {
      case ResourceCacheType.Contact:
        return contactOptions;
      case ResourceCacheType.Customer:
        return customerOptions;
      case ResourceCacheType.User:
        return userOptions;
      default:
        return (
          optionsOrResourceType?.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase()),
          ) ?? EMPTY_ARRAY
        );
    }
  }, [
    contactOptions,
    customerOptions,
    optionsOrResourceType,
    search,
    userOptions,
  ]);

  const handleOptionToggle = React.useCallback(
    (event: React.ChangeEvent) => {
      const value = event.target.id;

      onFilterSetByIdChange((prev) => {
        const set = new Set(prev[keyName] ?? EMPTY_ARRAY);

        if (set == null) {
          return prev;
        }

        if (set.has(value)) {
          set.delete(value);
        } else {
          set.add(value);
        }

        return {
          ...prev,
          [keyName]: set,
        };
      });
    },
    [keyName, onFilterSetByIdChange],
  );

  const isFilterSetEmpty = filterSet == null || filterSet.size === 0;

  return (
    <Popover shadow="md">
      <Popover.Target>
        <Button
          classNames={{ label: styles.button }}
          size="xs"
          variant={!isFilterSetEmpty ? "light" : "default"}
        >
          {label}
          {!isFilterSetEmpty && <Badge size="xs">{filterSet.size}</Badge>}
        </Button>
      </Popover.Target>

      <Popover.Dropdown p="sm">
        <Stack>
          <TextInput
            leftSection={<IconSearch size={16} />}
            onChange={setSearch}
            placeholder="Search"
            value={search}
          />
          {options.map((option) => {
            return (
              <Checkbox
                key={option.value}
                checked={filterSet?.has(option.value)}
                id={option.value}
                label={option.label}
                onChange={handleOptionToggle}
              />
            );
          })}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
});
