import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { UserChip } from "src/components/Frames/EntityChip/UserChip";
import { api } from "src/data/api/api";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { WithClassname } from "src/types/withClassName";
import { formatDateTime } from "src/utils/formatDateTime";
import styles from "./CustomerInfo.module.scss";
import { EditCustomerButton } from "./EditCustomerButton";

interface Props extends WithClassname {
  readonly customer: LoadedCustomer;
}

export const CustomerInfo = React.memo<Props>(function _CustomerInfo({
  className,
  customer,
}) {
  const customerOptions = api.endpoints.getCustomerOptions.useQuery();

  return (
    <ContentSection
      className={className}
      rightSection={<EditCustomerButton customer={customer} />}
      title="Information"
    >
      <Table highlightOnHover={true} withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Industry</Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                customerOptions.currentData?.industries.find(
                  ({ value }) =>
                    value === customer.attributes.industry_id.toString(),
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Account manager</Table.Td>
            <Table.Td className={styles.columnValue}>
              {customer.attributes.users[0] != null && (
                <UserChip id={customer.attributes.users[0].id.toString()} />
              )}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Vista ID</Table.Td>
            <Table.Td className={styles.columnValue}>
              {customer.attributes.vista_customer_id}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Created</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(customer.meta.created_at)}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Last updated</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(customer.meta.updated_at)}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </ContentSection>
  );
});
