import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { NewContactFormContent } from "src/components/Forms/NewContactFormContent";
import { NewContact } from "src/components/Forms/types/contact";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { Unwrap } from "src/types/unwrap";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormValue = NewContact["data"];

interface Props {
  readonly contact: Unwrap<LoadedCustomer["attributes"]["contacts"]>;
  readonly customer: LoadedCustomer;
  readonly onClose: () => void;
}

export const EditContactDialog = React.memo<Props>(function _EditContactDialog({
  contact,
  customer,
  onClose,
}) {
  const role = React.useMemo(() => {
    return contact.roles.find(
      (entry) =>
        entry.resource_type === ResourceType.Customer &&
        entry.resource_id === customer.attributes.id,
    );
  }, [contact.roles, customer.attributes.id]);

  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      email: contact.email,
      firstName: contact.first_name,
      lastName: contact.last_name,
      phoneNumber: contact.phone,
      role: role?.name,
    },
  });
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleContactUpdate = React.useCallback(
    async (submittedValue: FormValue) => {
      try {
        const customerUuid = customer.attributes.uuid;
        const contactUuid = contact.uuid;
        const contactId = contact.id;

        await trigger({
          bulk_transaction: {
            associations: [
              {
                type: AssociationType.ContactRole,
                contact_id: contactUuid,
                resource_id: customerUuid,
                resource_type: ResourceType.Customer,
                metadata: {
                  name: submittedValue.role ?? null,
                },
              },
            ],
            contacts_attributes: [
              {
                id: contactId,
                uuid: contactUuid,
                company_id: 1 as const,
                email: submittedValue.email,
                first_name: submittedValue.firstName,
                last_name: submittedValue.lastName,
                phone: submittedValue.phoneNumber,
              },
            ],
          },
        }).unwrap();
        onClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [contact.id, contact.uuid, customer.attributes.uuid, onClose, trigger],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Edit contact"
    >
      <NewContactFormContent control={control} shouldRenderRole={true} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleContactUpdate)}
            >
              Edit contact
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
