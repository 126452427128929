import { Group, Stack, Tabs } from "@mantine/core";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { BidTable } from "src/components/BidView/Table/BidTable";
import { PageHeader } from "src/components/Frames/PageHeader";
import { BIDS_CONFIG } from "src/constants/navBarConfigs";
import { api } from "src/data/api/api";
import { ListViewWithId } from "src/data/api/types/shared/listView";
import { selectCurrentUserId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from "src/utils/empty";
import { SearchInput } from "../Frames/SearchInput";
import styles from "./BidView.module.scss";
import {
  ALL_BIDS_VIEW,
  BID_VIEW_TYPE,
  BidColumnId,
  DEFAULT_BID_COLUMNS,
  MY_BIDS_VIEW,
} from "./constants";
import { NewBidButton } from "./Header/NewBidDialog/NewBidButton";
import { NewViewDialog } from "./NewViewDialog";
import { ViewTab } from "./ViewTab";

const MAX_VIEW_COUNT = 10;
const NEW_VIEW_VALUE = "new-view";
const VIEW_PARAM = "view";

export const BidView = React.memo(function _BidView() {
  const [isNewViewDialogOpen, { open, close }] = useDisclosure(false);
  const [query, setQuery] = useInputState(EMPTY_STRING);
  const [params, setSearchParams] = useSearchParams();

  const selectedViewId = params.get(VIEW_PARAM) ?? MY_BIDS_VIEW;

  const views = api.endpoints.getListViews.useQuery();

  const currentUserId = useAppSelector(selectCurrentUserId);
  const currentUserUuid = api.endpoints.getUser.useQuery(
    currentUserId ?? skipToken,
  ).currentData?.user.data.attributes.uuid;

  const allViews = React.useMemo((): ReadonlyArray<ListViewWithId> => {
    return [
      ...(currentUserUuid != null
        ? [
            {
              id: MY_BIDS_VIEW,
              name: "My bids",
              view: BID_VIEW_TYPE,
              elements: DEFAULT_BID_COLUMNS,
              filters: {
                filtersById: {
                  [BidColumnId.CREATED_BY]: [currentUserUuid],
                },
              },
            },
          ]
        : EMPTY_ARRAY),
      {
        id: ALL_BIDS_VIEW,
        name: "All bids",
        view: BID_VIEW_TYPE,
        elements: DEFAULT_BID_COLUMNS,
        filters: EMPTY_OBJECT,
      },
      ...(views.currentData?.collection.data.map((listView) => {
        return {
          id: listView.id,
          ...listView.attributes,
        };
      }) ?? EMPTY_ARRAY),
    ];
  }, [currentUserUuid, views.currentData?.collection.data]);

  const selectedView = React.useMemo(() => {
    return allViews.find((view) => view.id === selectedViewId);
  }, [selectedViewId, allViews]);

  // If the selected view gets deleted, reset to my bids
  React.useEffect(() => {
    if (selectedView == null) {
      params.set(VIEW_PARAM, MY_BIDS_VIEW);
      setSearchParams(params);
    }
  }, [params, selectedView, setSearchParams]);

  const handleTabChange = React.useCallback(
    (value: string | null) => {
      if (value == null) {
        return;
      }

      // Somewhat hacky solution to use the same component throughout the tabs but imitate the
      // behavior of a regular button
      if (value === NEW_VIEW_VALUE) {
        open();
        return;
      }
      params.set(VIEW_PARAM, value);
      setSearchParams(params);
    },
    [open, params, setSearchParams],
  );

  return (
    <Stack className={styles.stack}>
      <PageHeader
        className={styles.header}
        rightSection={
          <Group>
            <SearchInput onQueryChange={setQuery} query={query} />
            <NewBidButton />
          </Group>
        }
        title={
          <Group gap={4}>
            {BIDS_CONFIG.icon}
            {BIDS_CONFIG.name}
          </Group>
        }
      />

      <Tabs
        className={styles.tab}
        onChange={handleTabChange}
        value={selectedViewId}
      >
        <Tabs.List p="0 20px">
          {allViews.map((view) => (
            <ViewTab key={view.id} name={view.name} viewId={view.id} />
          ))}
          <Tabs.Tab
            color="#5C5F66"
            disabled={allViews.length > MAX_VIEW_COUNT}
            value={NEW_VIEW_VALUE}
          >
            <Group gap={10}>
              <IconPlus size={14} /> New view
            </Group>
          </Tabs.Tab>
        </Tabs.List>

        {selectedView != null && (
          <BidTable
            key={selectedViewId}
            currentView={selectedView}
            query={query}
          />
        )}
      </Tabs>

      {isNewViewDialogOpen && <NewViewDialog onClose={close} />}
    </Stack>
  );
});
