import { Sort } from "src/data/api/types/shared/sort";
import { ValuesUnion } from "src/types/valuesUnion";

export const JobSiteColumnId = {
  ADDRESS: "ADDRESS",
  BUILDING_CLASS: "BUILDING_CLASS",
  CITY: "CITY",
  STATE: "STATE",
  ZIP_CODE: "ZIP_CODE",
  ACCOUNT_MANAGER: "ACCOUNT_MANAGER",
  CREATED_AT: "CREATED_AT",
  CREATED_BY: "CREATED_BY",
  UPDATED_AT: "UPDATED_AT",
  UPDATED_BY: "UPDATED_BY",
} as const;
export type JobSiteColumnId = ValuesUnion<typeof JobSiteColumnId>;

export function getJobSiteFilterKeyFromColumnId(key: JobSiteColumnId) {
  switch (key) {
    case JobSiteColumnId.ACCOUNT_MANAGER:
      return "users";
    case JobSiteColumnId.BUILDING_CLASS:
      return "build_class";
    case JobSiteColumnId.CREATED_BY:
      return "created_by";
    case JobSiteColumnId.UPDATED_BY:
      return "updated_by";
  }
}

export function getJobSiteSortFromColumnId(
  key: JobSiteColumnId | undefined,
  sort: Sort | undefined,
) {
  if (key == null || sort == null) {
    return;
  }

  switch (key) {
    case JobSiteColumnId.ACCOUNT_MANAGER:
      return { users: { first_name: sort } };
    case JobSiteColumnId.ADDRESS:
      return { address: { full_address: sort } };
    case JobSiteColumnId.BUILDING_CLASS:
      return { build_class: sort };
    case JobSiteColumnId.CREATED_AT:
      return { created_at: sort };
    case JobSiteColumnId.CREATED_BY:
      return { created_by: { first_name: sort } };
    case JobSiteColumnId.CITY:
      return { address: { city: sort } };
    case JobSiteColumnId.ZIP_CODE:
      return { address: { zip_code: sort } };
    case JobSiteColumnId.STATE:
      return { address: { region: sort } };
    case JobSiteColumnId.UPDATED_AT:
      return { updated_at: sort };
    case JobSiteColumnId.UPDATED_BY:
      return { updated_by: { first_name: sort } };
  }
}

export const JOB_SITE_LABEL_BY_ID = {
  [JobSiteColumnId.ACCOUNT_MANAGER]: "Account manager",
  [JobSiteColumnId.ADDRESS]: "Address",
  [JobSiteColumnId.BUILDING_CLASS]: "Building class",
  [JobSiteColumnId.CITY]: "City",
  [JobSiteColumnId.CREATED_AT]: "Created at",
  [JobSiteColumnId.CREATED_BY]: "Created by",
  [JobSiteColumnId.STATE]: "State",
  [JobSiteColumnId.UPDATED_AT]: "Updated at",
  [JobSiteColumnId.UPDATED_BY]: "Updated by",
  [JobSiteColumnId.ZIP_CODE]: "Zipcode",
};
