import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { CustomerRoleFormContent } from "src/components/Forms/CustomerRoleFormContent";
import { PersistenceType } from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";
import { NewContact } from "../../../Forms/types/contact";

type FormValue = NewContact["data"];

interface Props {
  readonly contact: LoadedContact;
  readonly onClose: () => void;
}

export const AddCurrentRoleDialog = React.memo<Props>(
  function _AddCurrentRoleDialog({ contact, onClose }) {
    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        workplace: null,
        role: null,
      },
    });
    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleRoleAdd = React.useCallback(
      async (submittedValue: FormValue) => {
        if (submittedValue.workplace == null) {
          return;
        }

        const customerId =
          submittedValue.workplace?.type === PersistenceType.EXISTING
            ? submittedValue.workplace.data.uuid
            : submittedValue.workplace.uuid;

        const associations: Array<Association> = [];
        try {
          await trigger({
            bulk_transaction: {
              associations: [
                {
                  type: AssociationType.ContactRole,
                  contact_id: contact.attributes.uuid,
                  resource_type: ResourceType.Customer,
                  resource_id: customerId,
                  metadata: {
                    departed_at: submittedValue.departedAt,
                    name: submittedValue.role ?? undefined,
                  },
                },
                ...associations,
              ],
            },
          }).unwrap();
          onClose();
        } catch (error) {
          showBulkTransactionNotifications(error);
        }
      },
      [contact.attributes.uuid, onClose, trigger],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Add new role"
      >
        <CustomerRoleFormContent
          control={control}
          shouldAllowCustomerEdit={true}
        />

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleRoleAdd)}
              >
                Add new role
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
