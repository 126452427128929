import { EMPTY_STRING } from "src/utils/empty";
import { ExistingCustomer, NewCustomer } from "./customer";
import { PersistenceType } from "./persistenceType";

export interface NewContact {
  readonly type: PersistenceType.NEW;
  readonly data: {
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly phoneNumber?: string;
    readonly workplace: NewCustomer | ExistingCustomer | null;
    readonly role: string | null;
    readonly departedAt: string | null;
  };
  readonly uuid: string;
}

export const DEFAULT_NEW_CONTACT_VALUE: NewContact["data"] = {
  email: EMPTY_STRING,
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  workplace: null,
  role: null,
  departedAt: null,
};

export interface ExistingContact {
  readonly type: PersistenceType.EXISTING;
  readonly data: {
    readonly uuid: string;
  };
}
