import React from "react";
import { api } from "src/data/api/api";

/*
	Technically users are not displayed as an interactable chip, but for the sake
	of discoverabilty and consistentcy, this component is named as UserChip
*/

interface Props {
  readonly id: string;
}

export const UserChip = React.memo(function _UserChip({ id }: Props) {
  const { data } = api.endpoints.getUser.useQuery(id);

  return (
    <React.Fragment>
      {data?.user.data.attributes.first_name}{" "}
      {data?.user.data.attributes.last_name}
    </React.Fragment>
  );
});
