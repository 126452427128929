import React from "react";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { ContactChip } from "../EntityChip/ContactChip";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
}

export const ContactsView = React.memo(({ bid }: Props) => {
  const [cellContent, pillCount] = React.useMemo(() => {
    const contacts = bid.attributes.contacts.filter(
      (contact) =>
        contact.customer_id != null &&
        bid.attributes.customers.some(
          (customer) => customer.uuid === contact.customer_id,
        ),
    );

    return [
      contacts[0]?.id != null ? (
        <ContactChip id={contacts[0]?.id.toString()} />
      ) : null,
      contacts.length - (contacts[0] == null ? 0 : 1),
    ];
  }, [bid.attributes.contacts, bid.attributes.customers]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={cellContent}
      pillCount={pillCount}
      resourceType={ResourceType.Customer}
    />
  );
});
