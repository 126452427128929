import { SortChangedEvent } from "ag-grid-community";
import React from "react";
import { SortState } from "src/types/sortState";

interface Returns<T extends string> {
  readonly onSortChanged: (event: SortChangedEvent) => void;
  readonly sortState: SortState<T> | undefined;
}

export function useTableSort<T extends string>(
  initialState?: SortState<T> | undefined,
): Returns<T> {
  const [sortState, setSortState] = React.useState(initialState);

  const handleTableSortUpdate = React.useCallback((event: SortChangedEvent) => {
    const column = event.columns?.find((entry) => entry.getSort() != null);
    const sort = column?.getSort();

    if (column == null || sort == null) {
      setSortState(undefined);
      return;
    }

    setSortState({
      columnId: column.getColId() as T,
      sort,
    });
  }, []);

  return {
    onSortChanged: handleTableSortUpdate,
    sortState,
  };
}
