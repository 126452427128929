import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { CustomerChip } from "src/components/Frames/EntityChip/CustomerChip";
import { api } from "src/data/api/api";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { WithClassname } from "src/types/withClassName";

interface Props extends WithClassname {
  readonly jobSite: LoadedJobSite;
}

export const BidReceivers = React.memo<Props>(function BidReceivers({
  className,
  jobSite,
}) {
  const customerOptions = api.endpoints.getCustomerOptions.useQuery();

  return (
    <ContentSection
      className={className}
      title="Customers that received bids at this site"
    >
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Customer</Table.Th>
            <Table.Th>Industry</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {jobSite.attributes.customers?.map((customer) => {
            return (
              <Table.Tr key={customer.id}>
                <Table.Td>
                  <CustomerChip id={customer.id.toString()} />
                </Table.Td>
                <Table.Td>
                  {
                    customerOptions.currentData?.industries.find(
                      ({ value }) => value === customer.industry_id.toString(),
                    )?.label
                  }
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </ContentSection>
  );
});
