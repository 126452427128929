import {
  ColDef,
  GridOptions,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { UserCell } from "src/components/Frames/Table/UserCell";
import { FilterHeader } from "src/components/Table/Filter/FilterHeader";
import { PaginationFooter } from "src/components/Table/PaginationFooter";
import { NO_OP_COMPARATOR } from "src/constants/noOpComparator";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { FilterArgs, FilterEntry } from "src/data/api/types/shared/filterSort";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { ResourceCacheType } from "src/data/types/resourceCacheType";
import { useTableSort } from "src/hooks/table/useTableSort";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { Mutable } from "src/types/mutable";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_STRING } from "src/utils/empty";
import { formatDateTime } from "src/utils/formatDateTime";
import { isNonEmptyString } from "src/utils/isNonEmptyString";
import styles from "./JobSiteTable.module.scss";
import {
  getJobSiteFilterKeyFromColumnId,
  getJobSiteSortFromColumnId,
  JOB_SITE_LABEL_BY_ID,
  JobSiteColumnId,
} from "./utils";

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
  autoSizeStrategy: {
    type: "fitGridWidth",
  },
};

interface Props {
  readonly query: string;
}

export const JobSiteTable = React.memo(function _JobSiteTable({
  query,
}: Props) {
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const { onSortChanged, sortState } = useTableSort<JobSiteColumnId>();
  const [filterSetById, setFilterSetById] = React.useState<
    Partial<Record<JobSiteColumnId, ReadonlySet<string>>>
  >({
    [JobSiteColumnId.ACCOUNT_MANAGER]: new Set(),
    [JobSiteColumnId.BUILDING_CLASS]: new Set(),
  });

  const filterQuery = React.useMemo((): FilterArgs | undefined => {
    const and: Mutable<NonNullable<FilterArgs["search"]>["and"]> = [];

    for (const [key, filterSet] of Object.entries(filterSetById)) {
      if (filterSet != null && filterSet.size > 0) {
        const or: Array<FilterEntry> = [];

        for (const filterValue of filterSet) {
          const filterKey = getJobSiteFilterKeyFromColumnId(
            key as JobSiteColumnId,
          );

          if (filterKey != null) {
            or.push({
              [filterKey]: filterValue,
            });
          }
        }

        and.push({ or });
      }
    }

    if (isNonEmptyString(query)) {
      and.push({ query });
    }

    const search = and.length > 0 ? { and } : undefined;

    return {
      search,
      sort: getJobSiteSortFromColumnId(sortState?.columnId, sortState?.sort),
      page: currentPageIndex,
    };
  }, [
    currentPageIndex,
    filterSetById,
    query,
    sortState?.columnId,
    sortState?.sort,
  ]);

  const agGridRef = React.useRef<AgGridReact<LoadedJobSite>>(null);

  const jobSites = api.endpoints.getJobSites.useQuery(filterQuery);
  const jobSiteOptions = api.endpoints.getJobSiteOptions.useQuery();

  const columnDefs: ReadonlyArray<ColDef<LoadedJobSite>> = React.useMemo(
    () => [
      {
        colId: JobSiteColumnId.ADDRESS,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.ADDRESS],
        valueGetter: (v) => v.data?.attributes.address?.street1,
      },
      {
        colId: JobSiteColumnId.CITY,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.CITY],
        valueGetter: (v) => v.data?.attributes.address?.city,
      },
      {
        colId: JobSiteColumnId.STATE,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.STATE],
        valueGetter: (v) => v.data?.attributes.address?.region,
      },
      {
        colId: JobSiteColumnId.ZIP_CODE,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.ZIP_CODE],
        valueGetter: (v) => v.data?.attributes.address?.zip_code,
      },
      {
        colId: JobSiteColumnId.ACCOUNT_MANAGER,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.ACCOUNT_MANAGER],
        valueGetter: (v) => {
          const user = v.data?.attributes.users?.[0];
          return user != null
            ? `${user.first_name} ${user.last_name}`
            : EMPTY_STRING;
        },
      },
      {
        colId: JobSiteColumnId.BUILDING_CLASS,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.BUILDING_CLASS],
        valueGetter: (v) =>
          jobSiteOptions.currentData?.buildingClass.find(
            (entry) => entry.value === v.data?.attributes.build_class,
          )?.label,
      },
      {
        colId: JobSiteColumnId.CREATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.CREATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.created_at),
      },
      {
        colId: JobSiteColumnId.CREATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.CREATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedJobSite>) => {
          return <UserCell userId={v.data?.meta.created_by?.id.toString()} />;
        },
      },
      {
        colId: JobSiteColumnId.UPDATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.UPDATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.updated_at),
      },
      {
        colId: JobSiteColumnId.UPDATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: JOB_SITE_LABEL_BY_ID[JobSiteColumnId.UPDATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedJobSite>) => {
          return <UserCell userId={v.data?.meta.updated_by?.id.toString()} />;
        },
      },
    ],
    [jobSiteOptions.currentData?.buildingClass],
  );

  const optionsOrResourceTypeById = React.useMemo(() => {
    return {
      [JobSiteColumnId.ACCOUNT_MANAGER]: ResourceCacheType.User,
      [JobSiteColumnId.BUILDING_CLASS]:
        jobSiteOptions.currentData?.buildingClass,
      [JobSiteColumnId.CREATED_BY]: ResourceCacheType.User,
      [JobSiteColumnId.UPDATED_BY]: ResourceCacheType.User,
    };
  }, [jobSiteOptions.currentData?.buildingClass]);

  const handleRowClick = React.useCallback(
    (row: RowSelectedEvent<LoadedJobSite>) => {
      if (row.data?.id == null) {
        return;
      }

      navigate(Paths.CURRENT + Paths.SLASH + row.data.id);
    },
    [navigate],
  );

  if (jobSites.data?.collection.data == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Table} />;
  }

  return (
    <div className={classNames("ag-theme-quartz", styles.tableWrapper)}>
      <FilterHeader
        filterSetById={filterSetById}
        labelById={JOB_SITE_LABEL_BY_ID}
        onFilterSetByIdChange={setFilterSetById}
        optionsOrResourceTypeById={optionsOrResourceTypeById}
      />

      <AgGridReact<LoadedJobSite>
        ref={agGridRef}
        columnDefs={asMutableArray(columnDefs)}
        gridOptions={GRID_OPTIONS}
        onRowClicked={handleRowClick}
        onSortChanged={onSortChanged}
        rowData={asMutableArray(jobSites.data.collection.data)}
      />

      {jobSites.data != null && (
        <PaginationFooter
          currentPageIndex={currentPageIndex}
          onPageChange={setCurrentPageIndex}
          totalPageCount={jobSites.data.total_pages}
        />
      )}
    </div>
  );
});
