import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { CustomerRoleFormContent } from "src/components/Forms/CustomerRoleFormContent";
import {
  createExisting,
  PersistenceType,
} from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { NewContact } from "../../../Forms/types/contact";

type FormValue = NewContact["data"];

interface Props {
  readonly contact: LoadedContact;
  readonly roleId: number;
  readonly onClose: () => void;
}

export const EditCurrentRoleDialog = React.memo<Props>(
  function _EditCurrentRoleDialog({ contact, onClose, roleId }) {
    const roleToEdit = React.useMemo(() => {
      return contact.attributes.contact_roles.find(
        (role) =>
          role.id === roleId && role.resource_type === ResourceType.Customer,
      );
    }, [contact.attributes.contact_roles, roleId]);

    const workplaceUuid = React.useMemo(() => {
      return contact.attributes.customer_roles.find((entry) =>
        entry.roles.some((role) => role.id === roleId),
      )?.uuid;
    }, [contact.attributes.customer_roles, roleId]);

    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        workplace:
          workplaceUuid != null
            ? createExisting({ uuid: workplaceUuid })
            : undefined,
        role: roleToEdit?.name ?? null,
        departedAt: roleToEdit?.departed_at ?? null,
      },
    });
    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleRoleEdit = React.useCallback(
      async (submittedValue: FormValue) => {
        try {
          const customerId =
            submittedValue.workplace?.type === PersistenceType.EXISTING
              ? submittedValue.workplace.data.uuid
              : undefined;

          if (customerId == null) {
            throw new Error();
          }

          await trigger({
            bulk_transaction: {
              associations: [
                {
                  type: AssociationType.ContactRole,
                  contact_id: contact.attributes.uuid,
                  resource_type: ResourceType.Customer,
                  resource_id: customerId,
                  metadata: {
                    departed_at: submittedValue.departedAt ?? null,
                    name: submittedValue.role ?? null,
                  },
                },
              ],
            },
          });

          onClose();
        } catch (error) {
          // error notification
        }
      },
      [contact.attributes.uuid, onClose, trigger],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Edit role"
      >
        <CustomerRoleFormContent control={control} />

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleRoleEdit)}
              >
                Edit role
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
