export const Paths = {
  BASE: "",
  BIDS: "bids",
  CONTACTS: "contacts",
  CURRENT: ".",
  CUSTOMERS: "customers",
  DASHBOARD: "dashboard",
  ESTIMATIONS: "estimations",
  JOB_SITES: "job-sites",
  PROFILE: "profile",
  SLASH: "/",
};
