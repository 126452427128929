import { NavLink, Stack } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import fullLogo from "src/assets/full-logo.svg";
import { ProfileLogo } from "src/components/NavBar/ProfileLogo";
import { NAV_BAR_CONFIGS } from "src/constants/navBarConfigs";
import { Paths } from "src/constants/paths";
import styles from "./NavBar.module.scss";

export const NavBar = React.memo(function _NavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === Paths.SLASH) {
      navigate(NAV_BAR_CONFIGS[0].path);
    }
  }, [location, location.pathname, navigate]);

  return (
    <Stack align="center" h="100%" justify="space-between">
      <div style={{ width: "100%" }}>
        <img alt="Grace logo" src={fullLogo} />
      </div>

      <Stack gap={0} style={{ width: "100%", height: "100%" }}>
        {NAV_BAR_CONFIGS.map((item) => (
          // TODO: make subcomponents out of these
          <NavLink
            key={item.name}
            active={location.pathname.includes(item.path)}
            className={styles.navLink}
            component="button"
            h={44}
            label={item.name}
            leftSection={item.icon}
            onClick={() => {
              navigate(item.path);
            }}
          />
        ))}
      </Stack>

      <ProfileLogo />
    </Stack>
  );
});
