import { notifications } from "@mantine/notifications";

export function showValidationNotification({
  message,
}: {
  readonly message: string;
}): void {
  notifications.show({
    message: getValidationMessage(message),
  });
}

// TODO: get validation error enums from BE
function getValidationMessage(rawMessage: string) {
  switch (rawMessage) {
    case "Email has already been taken":
      return "Contact with the same email address already exists";
    case "Name has already been taken":
      return "Customer with the same name already exists";
    default:
      return rawMessage;
  }
}
