import React from "react";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { LabelValue } from "src/types/util/labelValue";
import { EMPTY_ARRAY } from "src/utils/empty";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
  jobTypes: ReadonlyArray<LabelValue>;
}

export const JobSiteView = React.memo(({ bid, jobTypes }: Props) => {
  const [cellContent, pillCount] = React.useMemo(() => {
    const sortedEstimations = bid.attributes.estimations
      .slice()
      .sort((d1, d2) => {
        return (d2.attributes.total ?? 0) - (d1.attributes.total ?? 0);
      });

    const jobTypesForEstimation =
      sortedEstimations[0]?.attributes.job_types ?? EMPTY_ARRAY;

    return [
      jobTypes.find((entry) => {
        return jobTypesForEstimation.includes(entry.value);
      })?.label,
      sortedEstimations.length - 1,
    ];
  }, [bid.attributes.estimations, jobTypes]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={cellContent}
      pillCount={pillCount}
      resourceType={ResourceType.Estimation}
    />
  );
});
