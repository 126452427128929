import { Group, Stack, Title } from "@mantine/core";
import React from "react";
import { DASHBOARD_CONFIG } from "src/constants/navBarConfigs";
import { api } from "src/data/api/api";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { LoadingScreen } from "../Frames/LoadingScreen";
import { PageHeader } from "../Frames/PageHeader";
import { Chart } from "./Chart";
import { Controls } from "./Controls";
import styles from "./DashboardView.module.scss";
import { Footer } from "./Footer";
import { MetricType, ScopeType } from "./util/utils";

const INITIAL_YEAR = 2024;
const DATES = Array(new Date().getFullYear() - INITIAL_YEAR + 1)
  .fill(null)
  .map((_, index) => (index + INITIAL_YEAR).toString());

export const DashboardView = React.memo(function _DashboardView() {
  const [scope, setScope] = React.useState<ScopeType>(ScopeType.Company);
  const [metric, setMetric] = React.useState<MetricType>(MetricType.Sales);
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().getFullYear(), 0, 1),
  );
  const [endDate, setEndDate] = React.useState(new Date());
  const metrics = api.endpoints.getDashboardSales.useQuery({
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
  });

  const handleDateSelect = React.useCallback((selectedYear: string | null) => {
    if (selectedYear == null) {
      return;
    }

    if (selectedYear === new Date().getFullYear().toString()) {
      setStartDate(new Date(new Date().getFullYear(), 0, 1));
      setEndDate(new Date());
    } else {
      setStartDate(new Date(parseInt(selectedYear, 10), 0, 1));
      setEndDate(new Date(parseInt(selectedYear, 10), 11, 31));
    }
  }, []);

  if (metrics.currentData == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Dashboard} />;
  }

  return (
    <Stack className={styles.root}>
      <PageHeader
        title={
          <Group gap={4}>
            {DASHBOARD_CONFIG.icon}
            {DASHBOARD_CONFIG.name}
          </Group>
        }
      />
      {/* add loading screen conditional here */}
      <Controls
        dates={DATES}
        defaultDate={endDate.getFullYear().toString()}
        metric={metric}
        onDateSelect={handleDateSelect}
        onMetricSelect={setMetric}
        onScopeSelect={setScope}
        scope={scope}
      />
      <Stack className={styles.container}>
        <Group className={styles.chartHeader}>
          <Title className={styles.chartTitle} order={4} size={22}>
            {getDashboardTitle(scope)}
          </Title>
          {`Time range : ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
        </Group>
        <Chart
          className={styles.chart}
          data={metrics.currentData.dashboard}
          metric={metric}
          scope={scope}
        />
        <Footer
          className={styles.footer}
          data={metrics.currentData.dashboard}
          metric={metric}
          scope={scope}
        />
      </Stack>
    </Stack>
  );
});

function getDashboardTitle(scope: ScopeType) {
  switch (scope) {
    case ScopeType.Company:
      return "Company bidding metrics";

    case ScopeType.Individual:
      return "My bidding metrics";
  }
}
