import { LabelValue } from "src/types/util/labelValue";
import { ValuesUnion } from "src/types/valuesUnion";

export const ScopeType = {
  Company: "Company",
  Individual: "Individual",
} as const;
export type ScopeType = ValuesUnion<typeof ScopeType>;

export const MetricType = {
  Sales: "Sales",
  Volume: "Volume",
} as const;
export type MetricType = ValuesUnion<typeof MetricType>;

export const SCOPE_LABELS: ReadonlyArray<LabelValue> = [
  {
    label: "All bids",
    value: ScopeType.Company,
  },
  {
    label: "My bids",
    value: ScopeType.Individual,
  },
];

export const METRIC_LABELS: ReadonlyArray<LabelValue> = [
  {
    label: "Sell amount",
    value: MetricType.Sales,
  },
  {
    label: "Bid count",
    value: MetricType.Volume,
  },
];
