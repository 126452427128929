import { AppShell, AppShellNavbarConfiguration } from "@mantine/core";
import React from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "src/components/NavBar/NavBar";

const APP_SHELL_NAVBAR_PROPS: AppShellNavbarConfiguration = {
  breakpoint: "sm",
  width: 194,
};

export const Layout = React.memo(function _Layout() {
  return (
    <AppShell layout="alt" navbar={APP_SHELL_NAVBAR_PROPS}>
      <AppShell.Navbar bg="#F9FAFB" p={16}>
        <NavBar />
      </AppShell.Navbar>

      <AppShell.Main
        display="flex"
        h="100%"
        pl={APP_SHELL_NAVBAR_PROPS.width}
        pr={0}
        w="100%"
      >
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
});
