import { EMPTY_STRING } from "src/utils/empty";
import { PersistenceType } from "./persistenceType";

export interface NewCustomer {
  readonly type: PersistenceType.NEW;
  readonly data: {
    readonly name: string;
    readonly industry: string;
    readonly accountManager?: string;
    readonly vistaCustomerId?: string;
  };
  readonly uuid: string;
}

export const DEFAULT_NEW_CUSTOMER_VALUE: Partial<NewCustomer["data"]> = {
  name: EMPTY_STRING,
  industry: undefined,
  accountManager: undefined,
  vistaCustomerId: EMPTY_STRING,
};

export interface ExistingCustomer {
  readonly type: PersistenceType.EXISTING;
  readonly data: {
    readonly uuid: string;
  };
}
