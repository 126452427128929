import { Group, Paper, Stack, Title } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./ContentSection.module.scss";

interface Props extends React.PropsWithChildren, WithClassname {
  readonly rightSection?: React.ReactNode;
  readonly title?: string;
}

export const ContentSection = React.memo<Props>(function _ContentSection({
  children,
  className,
  rightSection = <div />,
  title,
}) {
  return (
    <Paper
      className={classNames(className, styles.paper)}
      component={Stack}
      p="lg"
      shadow="xs"
    >
      <Group justify="space-between">
        {title != null && <Title order={4}>{title}</Title>}
        {rightSection}
      </Group>

      <Stack className={styles.content}>{children}</Stack>
    </Paper>
  );
});
