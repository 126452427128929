import { Avatar, Button, Group } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { authSlice } from "src/data/slices/auth";
import { useAppDispatch } from "src/data/store";
import styles from "./ProfileLogo.module.scss";

export const ProfileLogo = React.memo(function _ProfileLogo() {
  const dispatch = useAppDispatch();
  const [logout] = api.useLogoutMutation();
  const navigate = useNavigate();

  const handleLogout = React.useCallback(() => {
    logout();
    dispatch(authSlice.actions.logout());
  }, [dispatch, logout]);

  const handleAvatarClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.PROFILE);
  }, [navigate]);

  return (
    <Group gap="sm">
      <Avatar className={styles.avatar} onClick={handleAvatarClick} />
      <Button onClick={handleLogout}>Sign out</Button>
    </Group>
  );
});
