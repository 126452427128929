import React from "react";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
}

export const SalesLeadView = React.memo(({ bid }: Props) => {
  const [user, pillCount] = React.useMemo(() => {
    const sortedEstimations = bid.attributes.estimations
      .slice()
      .sort((d1, d2) => {
        return (d2.attributes.total ?? 0) - (d1.attributes.total ?? 0);
      });

    const currentUser = sortedEstimations[0]?.users[0];

    return [
      currentUser == null
        ? undefined
        : `${currentUser.first_name} ${currentUser.last_name}`,
      sortedEstimations.length - 1,
    ];
  }, [bid.attributes.estimations]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={user}
      pillCount={pillCount}
      resourceType={ResourceType.Estimation}
    />
  );
});
