import { Tabs } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { ColDef, GridOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { api } from "src/data/api/api";
import { WithClassname } from "src/types/withClassName";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_ARRAY } from "src/utils/empty";
import styles from "./EstimationTable.module.scss";

const DEFAULT_COL_DEF: ColDef<unknown> = {
  sortable: false,
};

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
  suppressMovableColumns: true,
};

const NEW_PACKAGE_VALUE = "new-view";

interface Props extends WithClassname {
  readonly estimationId: string;
}

export const EstimationTable = React.memo(function _EstimationTable({
  className,
  estimationId,
}: Props) {
  const [createPackage] = api.endpoints.createPackage.useMutation();
  const packages = api.endpoints.getPackages.useQuery({
    search: {
      and: [
        {
          query: estimationId,
        },
      ],
    },
  });
  const [selectedPackageId, setSelectedPackageId] = React.useState<string>();

  const selectedPackage = React.useMemo(() => {
    return packages.currentData?.collection.data.find(
      (entry) => entry.attributes.id.toString() === selectedPackageId,
    );
  }, [packages.currentData?.collection.data, selectedPackageId]);

  const handleTabChange = React.useCallback(
    (value: string | null) => {
      if (value == null) {
        return;
      }

      if (value === NEW_PACKAGE_VALUE) {
        createPackage({ package: { estimation_id: parseInt(estimationId) } });
        return;
      }

      setSelectedPackageId(value);
    },
    [createPackage, estimationId],
  );

  const columnDefs = React.useMemo((): ReadonlyArray<ColDef<unknown>> => {
    return [
      {
        headerName: "COST TYPE",
      },
      {
        headerName: "TRADE",
      },
      {
        headerName: "DESCRIPTION",
      },
      {
        headerName: "QTY",
      },
      {
        headerName: "UNIT COST",
      },
      {
        headerName: "SUBTOTAL($)",
      },
      {
        headerName: "TRADE",
      },
      {
        headerName: "DESCRIPTION",
      },
      {
        headerName: "HOURS",
      },
      {
        headerName: "TOTAL HOURS",
      },
    ];
  }, []);

  return (
    <div className={classNames(styles.root, "ag-theme-quartz", className)}>
      <Tabs
        className={styles.tab}
        onChange={handleTabChange}
        value={selectedPackageId}
        variant="outline"
      >
        <Tabs.List>
          {packages.currentData?.collection.data.map(({ attributes }) => (
            <Tabs.Tab key={attributes.id} value={attributes.id.toString()}>
              {attributes.id}
            </Tabs.Tab>
          ))}
          <Tabs.Tab color="#5C5F66" value={NEW_PACKAGE_VALUE}>
            <IconPlus size={14} />
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>

      {selectedPackage != null && (
        <AgGridReact
          columnDefs={asMutableArray(columnDefs)}
          defaultColDef={DEFAULT_COL_DEF}
          gridOptions={GRID_OPTIONS}
          rowData={asMutableArray(
            selectedPackage?.attributes.bundles ?? EMPTY_ARRAY,
          )}
        />
      )}
    </div>
  );
});
