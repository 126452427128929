import { Divider, Paper, Stack } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { LoadedDashboard } from "src/data/api/types/getDashboard";
import { WithClassname } from "src/types/withClassName";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatPercentage } from "src/utils/formatPercentage";
import styles from "./Footer.module.scss";
import { formatBidCount } from "./util/formatBidCount";
import { MetricType, ScopeType } from "./util/utils";

interface Props extends WithClassname {
  readonly data: LoadedDashboard;
  readonly metric: MetricType;
  readonly scope: ScopeType;
}

export const Footer = React.memo<Props>(function _Footer({
  className,
  data,
  metric,
  scope,
}) {
  const salesData = React.useMemo(() => {
    const salesDataOptions = {
      [ScopeType.Company]: {
        [MetricType.Sales]: [
          {
            label: "Hit rate (by sell amount)",
            value: formatPercentage(
              data.company_data.dollar_units.percentage_won,
            ),
          },
          {
            label: "Average sell amount per win",
            value: formatCurrency(
              data.company_data.dollar_units.average_total_per_win,
            ),
          },
        ],
        [MetricType.Volume]: [
          {
            label: "Hit rate (by bid count)",
            value: formatPercentage(data.company_data.bid_units.percentage_won),
          },
          {
            label: "Average number of wins per salesperson",
            value: formatBidCount(data.company_data.bid_units.wins_per_rep, 1),
          },
        ],
      },
      [ScopeType.Individual]: {
        [MetricType.Sales]: [
          {
            label: "Hit rate (by sell amount)",
            value: formatPercentage(data.user_data.dollar_units.percentage_won),
          },
          {
            label: "Average sell amount per win",
            value: formatCurrency(
              data.user_data.dollar_units.average_total_per_win,
            ),
          },
        ],
        [MetricType.Volume]: [
          {
            label: "Hit rate (by bid count)",
            value: formatPercentage(data.user_data.bid_units.percentage_won),
          },
        ],
      },
    };

    return salesDataOptions[scope][metric];
  }, [data, metric, scope]);

  return (
    <Paper className={classNames(className, styles.container)}>
      {salesData.map((statistic, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && (
              <Divider
                className={styles.divider}
                my="xs"
                orientation="vertical"
                size="xs"
              />
            )}
            <Stack className={styles.statisticGroup}>
              {statistic.label}
              <span className={styles.statistic}>{statistic.value}</span>
            </Stack>
          </React.Fragment>
        );
      })}
    </Paper>
  );
});
