import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { EstimationField } from "src/components/Fields/EstimationField";
import {
  DEFAULT_ESTIMATION_VALUE,
  NewBid,
} from "src/components/Forms/types/bid";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  EstimationAttributes,
  ResourceType,
  SALES_LEAD_ROLE,
} from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";

type FormValue = NewBid["estimation"];

interface Props {
  readonly bid: LoadedBid;
  readonly onClose: () => void;
}

export const AddEstimationDialog = React.memo<Props>(
  function _AddEstimationDialog({ bid, onClose }) {
    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        estimations: [DEFAULT_ESTIMATION_VALUE],
      },
    });

    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleNewEstimationCreate = React.useCallback(
      async (submittedValue: FormValue) => {
        try {
          const [newEstimation] = submittedValue.estimations;

          if (newEstimation == null) {
            throw new Error();
          }

          const estimations_attributes: Array<EstimationAttributes> = [];
          const associations: Array<Association> = [];
          const estimationId = crypto.randomUUID();
          estimations_attributes.push({
            uuid: estimationId,
            status: newEstimation.status,
            job_types: newEstimation.jobTypes,
            total: newEstimation.total,
          });
          associations.push({
            type: AssociationType.Role,
            user_id: newEstimation.salesLead,
            resource_type: ResourceType.Estimation,
            resource_id: estimationId,
            metadata: {
              name: SALES_LEAD_ROLE,
            },
          });

          await trigger({
            bulk_transaction: {
              associations,
              job_sites_attributes: [
                {
                  id: bid.attributes.job_site.id,
                  bids_attributes: [
                    {
                      id: bid.attributes.id,
                      estimations_attributes,
                    },
                  ],
                },
              ],
            },
          });

          onClose();
        } catch (error) {
          // error notification
        }
      },
      [bid.attributes.id, bid.attributes.job_site.id, onClose, trigger],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Add estimation"
      >
        <EstimationField
          control={control}
          index={0}
          shouldRenderSellAmountField={true}
        />

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleNewEstimationCreate)}
              >
                Add estimation
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
