import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/data/store";

export const selectAuthToken = (state: RootState) => state.auth.token;

export const selectCurrentUserId = createSelector(selectAuthToken, (token) => {
  const payload = token?.split(".")[1];

  if (payload == null) {
    return undefined;
  }

  try {
    return `${JSON.parse(atob(payload)).sub}`;
  } catch (e) {
    return undefined;
  }
});
