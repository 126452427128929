import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPencil } from "@tabler/icons-react";
import React from "react";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { EditBidDialog } from "./EditBidDialog";

interface Props {
  readonly bid: LoadedBid;
}

export const EditBidButton = React.memo(function _EditBidButton({
  bid,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        leftSection={<IconPencil size={14} />}
        onClick={open}
        variant="subtle"
      >
        Edit
      </Button>

      {opened && <EditBidDialog bid={bid} onClose={close} />}
    </>
  );
});
