import React from "react";
import { api } from "src/data/api/api";
import { selectUserOptions } from "src/data/selectors/resourceCache";
import { useAppSelector } from "src/data/store";

export function useUserOptions(query?: string) {
  api.endpoints.getUsers.useQuery(
    query != null
      ? {
          search: {
            and: [{ query }],
          },
          page: 1,
        }
      : undefined,
  );

  const userOptions = useAppSelector(selectUserOptions);

  return React.useMemo(
    () =>
      userOptions
        .filter(
          (option) =>
            query == null ||
            option.label.toLowerCase().includes(query.toLowerCase()),
        )
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [userOptions, query],
  );
}
