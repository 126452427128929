import React from "react";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { LabelValue } from "src/types/util/labelValue";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
  statuses: ReadonlyArray<LabelValue>;
}

export const StatusView = React.memo(({ bid, statuses }: Props) => {
  const [cellContent, pillCount] = React.useMemo(() => {
    const sortedEstimations = bid.attributes.estimations
      .slice()
      .sort((d1, d2) => {
        return (d2.attributes.total ?? 0) - (d1.attributes.total ?? 0);
      });

    return [
      statuses.find(
        (option) => option.value === sortedEstimations[0]?.attributes.status,
      )?.label,
      sortedEstimations.length - 1,
    ];
  }, [bid.attributes.estimations, statuses]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={cellContent}
      pillCount={pillCount}
      resourceType={ResourceType.Estimation}
    />
  );
});
