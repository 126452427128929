import { notifications } from "@mantine/notifications";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { NotificationsMessage } from "../../components/Frames/NotificationsMessage";

const AUTO_CLOSE_TIMEOUT_MS = 10_000;

interface Args {
  readonly entityId: number;
  readonly entityType: Exclude<ResourceType, typeof ResourceType.Estimation>;
}

export function showEntityNotification({ entityId, entityType }: Args): void {
  const NOTIFICATION_ID = crypto.randomUUID();
  notifications.show({
    autoClose: AUTO_CLOSE_TIMEOUT_MS,
    id: NOTIFICATION_ID,
    message: (
      <NotificationsMessage
        entityId={entityId}
        entityType={entityType}
        notificationId={NOTIFICATION_ID}
      />
    ),
  });
}
