import {
  ColDef,
  GridOptions,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { UserCell } from "src/components/Frames/Table/UserCell";
import { FilterHeader } from "src/components/Table/Filter/FilterHeader";
import { PaginationFooter } from "src/components/Table/PaginationFooter";
import { NO_OP_COMPARATOR } from "src/constants/noOpComparator";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadedCustomer } from "src/data/api/types/shared/customer";
import { FilterArgs, FilterEntry } from "src/data/api/types/shared/filterSort";
import { ResourceCacheType } from "src/data/types/resourceCacheType";
import { useTableSort } from "src/hooks/table/useTableSort";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { Mutable } from "src/types/mutable";
import { asMutableArray } from "src/utils/asMutableArray";
import { formatDateTime } from "src/utils/formatDateTime";
import { isNonEmptyString } from "src/utils/isNonEmptyString";
import styles from "./CustomerTable.module.scss";
import {
  CUSTOMER_LABEL_BY_ID,
  CustomerColumnId,
  getCustomerFilterKeyFromColumnId,
  getCustomerSortFromColumnId,
} from "./utils";

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
  autoSizeStrategy: {
    type: "fitGridWidth",
  },
};

interface Props {
  readonly query: string;
}

export const CustomerTable = React.memo(function _CustomerTable({
  query,
}: Props) {
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const { onSortChanged, sortState } = useTableSort<CustomerColumnId>();

  const [filterSetById, setFilterSetById] = React.useState<
    Partial<Record<CustomerColumnId, ReadonlySet<string>>>
  >({
    [CustomerColumnId.ACCOUNT_MANAGER]: new Set(),
    [CustomerColumnId.INDUSTRY]: new Set(),
  });

  const filterQuery = React.useMemo((): FilterArgs | undefined => {
    const and: Mutable<NonNullable<FilterArgs["search"]>["and"]> = [];

    for (const [key, filterSet] of Object.entries(filterSetById)) {
      if (filterSet != null && filterSet.size > 0) {
        const or: Array<FilterEntry> = [];

        for (const filterValue of filterSet) {
          const filterKey = getCustomerFilterKeyFromColumnId(
            key as CustomerColumnId,
          );

          if (filterKey != null) {
            or.push({
              [filterKey]: filterValue,
            });
          }
        }

        and.push({ or });
      }
    }

    if (isNonEmptyString(query)) {
      and.push({ query });
    }

    const search = and.length > 0 ? { and } : undefined;

    return {
      search,
      sort: getCustomerSortFromColumnId(sortState?.columnId, sortState?.sort),
      page: currentPageIndex,
    };
  }, [
    currentPageIndex,
    filterSetById,
    query,
    sortState?.columnId,
    sortState?.sort,
  ]);

  const agGridRef = React.useRef<AgGridReact<LoadedCustomer>>(null);

  const customers = api.endpoints.getCustomers.useQuery(filterQuery);
  const customerOptions = api.endpoints.getCustomerOptions.useQuery(undefined);

  const columnDefs = React.useMemo(
    (): ReadonlyArray<ColDef<LoadedCustomer>> => [
      {
        colId: CustomerColumnId.NAME,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.NAME],
        valueGetter: (v) => v.data?.attributes.name,
      },
      {
        colId: CustomerColumnId.INDUSTRY,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.INDUSTRY],
        valueGetter: (v) =>
          customerOptions.data?.industries.find(
            ({ value }) => v.data?.attributes.industry_id.toString() === value,
          )?.label,
      },
      {
        colId: CustomerColumnId.ACCOUNT_MANAGER,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.ACCOUNT_MANAGER],
        cellRenderer: (v: ValueGetterParams<LoadedCustomer>) => {
          return (
            <UserCell userId={v.data?.attributes.users[0]?.id.toString()} />
          );
        },
      },
      {
        colId: CustomerColumnId.VISTA_CUSTOMER_ID,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.VISTA_CUSTOMER_ID],
        valueGetter: (v) => v.data?.attributes.vista_customer_id,
      },
      {
        colId: CustomerColumnId.CREATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.CREATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.created_at),
      },
      {
        colId: CustomerColumnId.CREATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.CREATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedCustomer>) => {
          return <UserCell userId={v.data?.meta.created_by?.id.toString()} />;
        },
      },
      {
        colId: CustomerColumnId.UPDATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.UPDATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.updated_at),
      },
      {
        colId: CustomerColumnId.UPDATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: CUSTOMER_LABEL_BY_ID[CustomerColumnId.UPDATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedCustomer>) => {
          return <UserCell userId={v.data?.meta.updated_by?.id.toString()} />;
        },
      },
    ],
    [customerOptions.data?.industries],
  );

  const optionsOrResourceType = React.useMemo(() => {
    return {
      [CustomerColumnId.ACCOUNT_MANAGER]: ResourceCacheType.User,
      [CustomerColumnId.INDUSTRY]: customerOptions.currentData?.industries,
      [CustomerColumnId.CREATED_BY]: ResourceCacheType.User,
      [CustomerColumnId.UPDATED_BY]: ResourceCacheType.User,
    };
  }, [customerOptions.currentData?.industries]);

  const handleRowClick = React.useCallback(
    (row: RowSelectedEvent<LoadedCustomer>) => {
      if (row.data?.id == null) {
        return;
      }

      navigate(Paths.CURRENT + Paths.SLASH + row.data.id);
    },
    [navigate],
  );

  if (customers.data?.collection.data == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Table} />;
  }

  return (
    <div className={classNames("ag-theme-quartz", styles.tableWrapper)}>
      <FilterHeader
        filterSetById={filterSetById}
        labelById={CUSTOMER_LABEL_BY_ID}
        onFilterSetByIdChange={setFilterSetById}
        optionsOrResourceTypeById={optionsOrResourceType}
      />

      <AgGridReact<LoadedCustomer>
        ref={agGridRef}
        columnDefs={asMutableArray(columnDefs)}
        gridOptions={GRID_OPTIONS}
        onRowClicked={handleRowClick}
        onSortChanged={onSortChanged}
        rowData={asMutableArray(customers.data.collection.data)}
      />

      {customers.data != null && (
        <PaginationFooter
          currentPageIndex={currentPageIndex}
          onPageChange={setCurrentPageIndex}
          totalPageCount={customers.data.total_pages}
        />
      )}
    </div>
  );
});
