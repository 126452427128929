import { EMPTY_STRING } from "src/utils/empty";

export enum PersistenceType {
  EXISTING = "EXISTING",
  NEW = "NEW",
}

export function createNew<T>(data: T, uuid: string = EMPTY_STRING) {
  return {
    type: PersistenceType.NEW as const,
    data,
    uuid,
  };
}

export function createExisting<T>(data: T) {
  return {
    type: PersistenceType.EXISTING as const,
    data,
  };
}
