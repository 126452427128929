import { Button, Group, Modal, Textarea } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React from "react";
import { FormFooter } from "src/components/Frames/FormFooter";
import { Note } from "src/data/api/types/shared/note";
import { EMPTY_STRING } from "src/utils/empty";

interface Props {
  readonly isLoading: boolean;
  readonly note?: Note;
  readonly onClose: () => void;
  readonly onNoteAdd?: (content: string) => Promise<void>;
  readonly onNoteUpdate?: (id: number, content: string) => Promise<void>;
}

export const NoteDialog = React.memo<Props>(function _NoteDialog({
  isLoading,
  note,
  onClose,
  onNoteAdd,
  onNoteUpdate,
}) {
  const [noteContent, setNoteContent] = useInputState(
    note?.content ?? EMPTY_STRING,
  );

  const isNewNote = note == null;

  const handleSaveClick = React.useCallback(async () => {
    if (isNewNote) {
      if (noteContent !== EMPTY_STRING) {
        try {
          await onNoteAdd?.(noteContent);
          onClose();
        } catch (error) {
          notifications.show({
            title: "Error",
            message: "We are unable to add your note. Please try again",
          });
        }
      }
    } else {
      const noteId = note.id;
      if (note.content !== noteContent && noteId != null) {
        try {
          await onNoteUpdate?.(noteId, noteContent);
          onClose();
        } catch (error) {
          notifications.show({
            title: "Error",
            message: "We are unable to edit your note. Please try again",
          });
        }
      } else if (note.content === noteContent) {
        onClose();
      }
    }
  }, [
    isNewNote,
    note?.id,
    note?.content,
    noteContent,
    onClose,
    onNoteAdd,
    onNoteUpdate,
  ]);

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title={isNewNote ? "Add note" : "Edit note"}
    >
      <Textarea
        autosize={true}
        data-autofocus={true}
        onChange={setNoteContent}
        required={true}
        value={noteContent}
      />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button loading={isLoading} onClick={handleSaveClick}>
              Save
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
