import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { CustomerChip } from "src/components/Frames/EntityChip/CustomerChip";
import { RowHoverOptions } from "src/components/Frames/RowHoverOptions";
import styles from "src/components/Frames/RowHoverOptions.module.scss";
import { api } from "src/data/api/api";
import {
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { WithClassname } from "src/types/withClassName";
import { AddCurrentRoleButton } from "./AddCurrentRoleButton";
import { EditCurrentRoleDialog } from "./EditCurrentRoleDialog";

interface Props extends WithClassname {
  readonly contact: LoadedContact;
}

export const CurrentRole = React.memo<Props>(function _CurrentRole({
  className,
  contact,
}) {
  const contactRoleOptions = api.endpoints.getContactRoleOptions.useQuery();
  const [trigger] = api.endpoints.bulkTransaction.useMutation();
  const [editId, setEditId] = React.useState<number>();

  const handleCurrentRoleDelete = React.useCallback(
    (id: number) => {
      const customerRole = contact.attributes.contact_roles.find(
        (entry) => entry.id === id,
      );

      if (customerRole == null) {
        throw new Error();
      }

      trigger({
        bulk_transaction: {
          deletions: [
            {
              resource_type: AssociationType.ContactRole,
              resource_id: customerRole.id,
            },
          ],
        },
      });
    },
    [contact.attributes.contact_roles, trigger],
  );

  const handleDialogClose = React.useCallback(() => {
    setEditId(undefined);
  }, []);

  return (
    <ContentSection
      className={className}
      rightSection={<AddCurrentRoleButton contact={contact} />}
      title="Current role"
    >
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Customer</Table.Th>
            <Table.Th>Role</Table.Th>
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {contact.attributes.contact_roles
            .filter((entry) => entry.resource_type === ResourceType.Customer)
            .map(({ name, departed_at, id, resource_id }) => {
              return (
                <Table.Tr key={id} className={styles.row}>
                  <Table.Td>
                    <CustomerChip id={resource_id.toString()} />
                  </Table.Td>
                  <Table.Td>
                    {
                      contactRoleOptions.currentData?.title.find(
                        ({ value }) => value === name,
                      )?.label
                    }
                  </Table.Td>
                  <Table.Td>
                    {departed_at != null ? "Past" : "Current"}
                  </Table.Td>
                  <Table.Td>
                    <RowHoverOptions
                      id={id}
                      onDelete={handleCurrentRoleDelete}
                      onEdit={setEditId}
                    />
                  </Table.Td>
                </Table.Tr>
              );
            })}
        </Table.Tbody>
      </Table>

      {editId != null && (
        <EditCurrentRoleDialog
          contact={contact}
          onClose={handleDialogClose}
          roleId={editId}
        />
      )}
    </ContentSection>
  );
});
