import { IconPencil, IconTrash } from "@tabler/icons-react";
import React from "react";
import { typedMemo } from "../../../util";
import styles from "./RowHoverOptions.module.scss";

interface Props<T> {
  readonly id: T;
  readonly onDelete?: (id: T) => void;
  readonly onEdit?: (id: T) => void;
}

export const RowHoverOptions = typedMemo(function _RowHoverOption<T>({
  id,
  onDelete,
  onEdit,
}: Props<T>) {
  const handleDelete = React.useCallback(() => {
    onDelete?.(id);
  }, [id, onDelete]);

  const handleEdit = React.useCallback(() => {
    onEdit?.(id);
  }, [id, onEdit]);

  return (
    <div className={styles.hover}>
      {onEdit != null && (
        <IconPencil className={styles.icon} onClick={handleEdit} size={14} />
      )}
      {onDelete != null && (
        <IconTrash className={styles.icon} onClick={handleDelete} size={14} />
      )}
    </div>
  );
});
