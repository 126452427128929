import { Table } from "@mantine/core";
import React from "react";
import { ContentSection } from "src/components/Frames/ContentSection";
import { EmbeddedMap } from "src/components/Frames/EmbeddedMap";
import { api } from "src/data/api/api";
import { LoadedJobSite } from "src/data/api/types/shared/jobSite";
import { WithClassname } from "src/types/withClassName";
import { EMPTY_STRING } from "src/utils/empty";
import { formatDateTime } from "src/utils/formatDateTime";
import { EditJobSiteButton } from "./EditJobSiteButton";
import styles from "./JobSiteInfo.module.scss";

interface Props extends WithClassname {
  readonly jobSite: LoadedJobSite;
}

export const JobSiteInfo = React.memo<Props>(function _JobSiteInfo({
  className,
  jobSite,
}) {
  const jobsiteOptions = api.endpoints.getJobSiteOptions.useQuery();

  const accountManager = React.useMemo(() => {
    const attributes = jobSite.attributes.users?.[0];

    if (attributes == null) {
      return EMPTY_STRING;
    }

    return `${attributes.first_name} ${attributes.last_name}`;
  }, [jobSite.attributes.users]);

  return (
    <ContentSection
      className={className}
      rightSection={<EditJobSiteButton jobSite={jobSite} />}
      title="Information"
    >
      <EmbeddedMap
        className={styles.map}
        googleId={jobSite.attributes.address?.google_id}
      />

      <Table highlightOnHover={true} withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Address</Table.Td>
            <Table.Td className={styles.columnValue}>
              {jobSite.attributes.address?.street1}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>City</Table.Td>
            <Table.Td className={styles.columnValue}>
              {jobSite.attributes.address?.city}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Zip code</Table.Td>
            <Table.Td className={styles.columnValue}>
              {jobSite.attributes.address?.zip_code}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Account manager</Table.Td>
            <Table.Td className={styles.columnValue}>{accountManager}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Building class</Table.Td>
            <Table.Td className={styles.columnValue}>
              {
                jobsiteOptions.currentData?.buildingClass.find(
                  ({ value }) => value === jobSite.attributes.build_class,
                )?.label
              }
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Created</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(jobSite.meta.created_at)}
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td className={styles.columnName}>Last updated</Table.Td>
            <Table.Td className={styles.columnValue}>
              {formatDateTime(jobSite.meta.updated_at)}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </ContentSection>
  );
});
