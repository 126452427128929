import { EMPTY_STRING } from "./empty";

const DATE_TIME_FORMAT = new Intl.DateTimeFormat(`en-US`, {
  dateStyle: "short",
  timeStyle: "short",
});

export function formatDateTime(date: Date | string | undefined) {
  if (date == null) {
    return EMPTY_STRING;
  }

  if (typeof date == "string") {
    return DATE_TIME_FORMAT.format(new Date(date));
  }

  return DATE_TIME_FORMAT.format(date);
}
