import { Button, Group, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { selectAuthToken } from "src/data/selectors/auth";
import { authSlice } from "src/data/slices/auth";
import { useAppDispatch, useAppSelector } from "src/data/store";
import { EMPTY_STRING } from "src/utils/empty";
import styles from "./Login.module.scss";

export const Login = React.memo(function _Login() {
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthToken);
  const [login, { isLoading: isLoginLoading }] =
    api.endpoints.login.useMutation();
  const [signUp, { isLoading: isSignUpLoading }] =
    api.endpoints.signUp.useMutation();

  const navigate = useNavigate();

  const [isLogin, setIsLogin] = React.useState(true);
  const [shouldShowErrors, setShouldShowErrors] = React.useState(false);

  const [email, setEmail] = useInputState(EMPTY_STRING);
  const [password, setPassword] = useInputState(EMPTY_STRING);
  const [firstName, setFirstName] = useInputState(EMPTY_STRING);
  const [lastName, setLastName] = useInputState(EMPTY_STRING);

  const isEmailValid = React.useMemo(() => {
    if (email.trim().length === 0) {
      return false;
    }

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, [email]);

  const isPasswordValid = React.useMemo(() => {
    return password.length > 8;
  }, [password.length]);

  const isFirstNameValid = React.useMemo(() => {
    return firstName.length > 0;
  }, [firstName.length]);

  const isLastNameValid = React.useMemo(() => {
    return lastName.length > 0;
  }, [lastName.length]);

  const handleModeToggle = React.useCallback(() => {
    setIsLogin((value) => !value);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    setShouldShowErrors(true);

    if (!isEmailValid || !isPasswordValid) {
      return;
    }

    const sanitizedEmail = email.trim();

    let token: string | undefined;
    if (isLogin) {
      token = await login({
        email: sanitizedEmail,
        password: password,
      }).unwrap();
    } else {
      if (!isFirstNameValid || !isLastNameValid) {
        return;
      }

      const sanitizedFirstName = firstName.trim();
      const sanitizedLastName = lastName.trim();

      token = await signUp({
        email: sanitizedEmail,
        password: password,
        first_name: sanitizedFirstName,
        last_name: sanitizedLastName,
        company_id: 1,
      }).unwrap();
    }

    if (token == null) {
      return;
    }

    dispatch(authSlice.actions.setCredentials({ token }));
  }, [
    dispatch,
    email,
    firstName,
    isEmailValid,
    isFirstNameValid,
    isLastNameValid,
    isLogin,
    isPasswordValid,
    lastName,
    login,
    password,
    signUp,
  ]);

  const mode = isLogin ? "Sign in" : "Register";
  const errorMessage = "Invalid";

  React.useEffect(() => {
    if (authToken) {
      navigate(Paths.SLASH + Paths.BIDS);
    }
  }, [authToken, navigate]);

  if (authToken != null) {
    return <Outlet />;
  }

  return (
    <div className={styles.root}>
      <Stack w={600}>
        {mode}
        <TextInput
          error={shouldShowErrors && !isEmailValid && errorMessage}
          label="Email"
          onChange={setEmail}
          value={email}
        />
        <PasswordInput
          error={
            shouldShowErrors && !isPasswordValid && !isLogin && errorMessage
          }
          label="Password"
          onChange={setPassword}
          value={password}
        />

        {!isLogin && (
          <React.Fragment>
            <TextInput
              error={shouldShowErrors && !isFirstNameValid && errorMessage}
              label="First name"
              onChange={setFirstName}
              value={firstName}
            />
            <TextInput
              error={shouldShowErrors && !isLastNameValid && errorMessage}
              label="Last name"
              onChange={setLastName}
              value={lastName}
            />
          </React.Fragment>
        )}

        <Group justify="space-between">
          <Button disabled={true} onClick={handleModeToggle} variant="default">
            {isLogin ? "First time?" : "Cancel"}
          </Button>
          <Button
            loading={isLoginLoading || isSignUpLoading}
            onClick={handleSubmit}
          >
            {mode}
          </Button>
        </Group>
      </Stack>
    </div>
  );
});
