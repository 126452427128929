import { EMPTY_ARRAY } from "src/utils/empty";
import { ById } from "../byId";

// Suppose to be compatible with mantine/core ComboboxItem
export interface LabelValue {
  readonly label: string;
  readonly value: string;
}

export function getLabelValueFromObject(
  object: ById<string> | undefined,
): ReadonlyArray<LabelValue> {
  if (object == null) {
    return EMPTY_ARRAY;
  }

  const result: Array<LabelValue> = [];

  for (const [key, value] of Object.entries(object)) {
    if (value == null) {
      continue;
    }

    result.push({
      label: value,
      value: key,
    });
  }

  return result;
}
