import { Button, Group, Modal, Stack } from "@mantine/core";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { BranchField } from "src/components/Fields/BranchField";
import { EstimationField } from "src/components/Fields/EstimationField";
import { REQUIRED_RULES } from "src/components/Forms/constants";
import { NewBid } from "src/components/Forms/types/bid";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  EstimationAttributes,
  ResourceType,
  SALES_LEAD_ROLE,
} from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";

type FormValue = NewBid["estimation"];

interface Props {
  readonly bid: LoadedBid;
  readonly estimationId: string;
  readonly onClose: () => void;
}

export const EditEstimationDialog = React.memo<Props>(
  function _EditEstimationDialog({ bid, estimationId, onClose }) {
    const estimation = React.useMemo(
      () =>
        bid.attributes.estimations.find(
          (entry) => entry.attributes.uuid === estimationId,
        ),
      [bid.attributes.estimations, estimationId],
    );

    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        branch: bid.attributes.branch_id?.toString(),
        estimations: [
          {
            jobTypes: estimation?.attributes.job_types,
            salesLead: estimation?.users[0]?.uuid,
            status: estimation?.attributes.status,
            total: estimation?.attributes.total,
          },
        ],
      },
    });

    const branch = useController({
      control,
      name: "branch",
      rules: REQUIRED_RULES,
    });

    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleEstimationUpdate = React.useCallback(
      async (submittedValue: FormValue) => {
        try {
          const [newEstimation] = submittedValue.estimations;

          if (newEstimation == null || estimation?.attributes.uuid == null) {
            throw new Error();
          }

          const estimations_attributes: Array<EstimationAttributes> = [];
          const associations: Array<Association> = [];
          estimations_attributes.push({
            id: estimation.attributes.id,
            job_types: newEstimation.jobTypes,
            status: newEstimation.status,
            total: newEstimation.total,
          });
          associations.push({
            type: AssociationType.Role,
            id: estimation.users[0]?.roles.find(
              (entry) =>
                entry.resource_type === ResourceType.Estimation &&
                entry.resource_id === estimation.attributes.id,
            )?.id,
            user_id: newEstimation.salesLead,
            resource_type: ResourceType.Estimation,
            resource_id: estimation.attributes.uuid,
            metadata: {
              name: SALES_LEAD_ROLE,
            },
          });

          await trigger({
            bulk_transaction: {
              associations,
              job_sites_attributes: [
                {
                  id: bid.attributes.job_site.id,
                  bids_attributes: [
                    {
                      id: bid.attributes.id,
                      branch_id: submittedValue.branch,
                      estimations_attributes,
                    },
                  ],
                },
              ],
            },
          });

          onClose();
        } catch (error) {
          // error notification
        }
      },
      [
        bid.attributes.id,
        bid.attributes.job_site.id,
        estimation,
        onClose,
        trigger,
      ],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title="Update estimation"
      >
        <Stack>
          <BranchField
            error={branch.fieldState.error?.message}
            onChange={branch.field.onChange}
            value={branch.field.value}
          />

          <EstimationField
            control={control}
            index={0}
            shouldRenderSellAmountField={true}
          />
        </Stack>

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleEstimationUpdate)}
              >
                Update estimation
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
