import { Text } from "@mantine/core";
import React from "react";
import { api } from "src/data/api/api";
import styles from "./Branch.module.scss";

interface Props {
  readonly branchId?: number;
}

export const Branch = React.memo<Props>(function _Branch({ branchId }) {
  const company = api.endpoints.getCompany.useQuery();

  const branchName = React.useMemo(() => {
    return company.currentData?.company.data.attributes.branches.find(
      (branch) => branch.id === branchId,
    )?.name;
  }, [branchId, company]);

  return (
    <div className={styles.container}>
      <Text c="dimmed" size="sm">
        Branch:
      </Text>
      <Text size="sm">{branchName}</Text>
    </div>
  );
});
