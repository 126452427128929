import { Paper } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { LoadedDashboard } from "src/data/api/types/getDashboard";
import { WithClassname } from "src/types/withClassName";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_OBJECT } from "src/utils/empty";
import styles from "./Chart.module.scss";
import { useChartData } from "./useChartData";
import { MetricType, ScopeType } from "./util/utils";
import { ZERO_DATA } from "./zeroData";

const SECTOR_COLORS = ["#DEE2E6", "#00A69C", "#91a7ff"];

interface Props extends WithClassname {
  readonly data: LoadedDashboard;
  readonly metric: MetricType;
  readonly scope: ScopeType;
}

export const Chart = React.memo<Props>(function _Chart({
  className,
  data,
  metric,
  scope,
}) {
  const { totalsData, salesData } = useChartData({ data, metric, scope });

  const isDataZero = React.useMemo(() => {
    switch (scope) {
      case ScopeType.Company:
        return data.company_data.bid_units.total === 0;

      case ScopeType.Individual:
        return data.user_data.bid_units.total === 0;

      default:
        return false;
    }
  }, [data, scope]);

  return (
    <Paper className={classNames(className, styles.root)} p="lg" shadow="xs">
      <ResponsiveContainer>
        <PieChart>
          <Pie
            className={styles.pieElement}
            data={
              isDataZero ? asMutableArray(ZERO_DATA) : asMutableArray(salesData)
            }
            dataKey="value"
            endAngle={-180}
            innerRadius="50%"
            label={
              !isDataZero &&
              ((props) => {
                const { cx, cy, midAngle, innerRadius, outerRadius, payload } =
                  props;

                if (payload.value === 0) {
                  return;
                }

                const RADIAN = Math.PI / 180;
                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + ((outerRadius + innerRadius) / 2) * cos;
                const sy = cy + ((outerRadius + innerRadius) / 2) * sin;
                const mx = cx + (outerRadius + 40) * cos;
                const my = cy + (outerRadius + 40) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? "start" : "end";

                return (
                  <g>
                    <path
                      d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                      fill="none"
                      stroke="#575757"
                    />
                    <text
                      className={styles.labelText}
                      textAnchor={textAnchor}
                      x={ex + (cos >= 0 ? 1 : -1) * 12}
                      y={ey - 3}
                    >
                      {payload.labelText}
                    </text>
                    <text
                      className={styles.labelValue}
                      dominantBaseline="hanging"
                      dy={3}
                      textAnchor={textAnchor}
                      x={ex + (cos >= 0 ? 1 : -1) * 12}
                      y={ey}
                    >
                      {payload.labelValue}
                    </text>
                  </g>
                );
              })
            }
            labelLine={false}
            outerRadius="80%"
            startAngle={180}
          >
            <Label
              content={
                isDataZero ? (
                  <text visibility="hidden" />
                ) : (
                  (props) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const viewBox = props.viewBox as any;
                    const { cx, cy } = viewBox ?? EMPTY_OBJECT;

                    return (
                      <text textAnchor="middle" x={cx} y={cy - 32}>
                        <tspan className={styles.totalText}>
                          {totalsData.label}
                        </tspan>
                        <tspan className={styles.totalText} dy={24} x={cx}>
                          {totalsData.units}
                        </tspan>
                        <tspan className={styles.totalValue} dy={40} x={cx}>
                          {totalsData.value}
                        </tspan>
                      </text>
                    );
                  }
                )
              }
            />
            {salesData.map((_statistic, index) => (
              <Cell
                key={index}
                className={styles.sector}
                fill={SECTOR_COLORS[index]}
                stroke={SECTOR_COLORS[index]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
});
